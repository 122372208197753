/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import descriptions from '../../../utils/helpers/Searcher';
import { NameType } from '../../../typescript/datas/NameType';
import { SearchableDataTableType, SearchableDataTableTypeArr } from '../../../typescript/datas/DataTablesType';
import {
  isDish,
  isIngredient,
  isMenu,
  isUser,
} from '../../../utils/helpers/Datatype';
import { DishType } from '../../../typescript/datas/DishTypes';
import { MenuType } from '../../../typescript/datas/MenuType';
import { IngredientType } from '../../../typescript/datas/IngredientType';
import { UserType } from '../../../typescript/datas/UserType';

type SearcherProps = {
  name: NameType,
  searchTerm: string,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  allEntities: SearchableDataTableTypeArr,
  setPartEntities: React.Dispatch<React.SetStateAction<
    SearchableDataTableTypeArr | SearchableDataTableType | undefined
  >>,
  disabled?: boolean,
}

function Searcher({
  name, // Nom de l'entité associée au searcher
  searchTerm, // Terme actuellement recherché
  setSearchTerm, // Modifie le terme recherché
  allEntities, // Tableau d'objets représentant toutes les entités à rechercher
  setPartEntities, // Modifie l'objet représentant l'entité trouvée via l'input de recherche
  disabled = false, // Booléen permettant de savoir si on a les droits de modification
}: SearcherProps) {
  /*
    State contenant l'objet représentant l'entité trouvée via l'input de recherche
    Permet aussi l'autosuggestion du navigateur
  */
  const [searchResults, setSearchResults] = useState<SearchableDataTableTypeArr>([]);

  /*
    Méthode filtrant mon tableau d'entités en ne gardant que
    celles qui commencent comme le terme de recherche entré
  */
  const filterResults = (
    query: string,
  ) => {
    const filteredResults = allEntities.filter(
      (entity) => {
        if ((name === 'users') && isUser(entity)) {
          return entity.realName.toLowerCase().startsWith(query.toLowerCase());
        }
        if (isIngredient(entity) || isDish(entity) || isMenu(entity)) {
          return entity.title.toLowerCase().startsWith(query.toLowerCase());
        }
        return '';
      },
    );

    // Dans certains cas on utilisera le state pour l'autosuggestion
    if (name === 'dishesPart' || name === 'menusPart') {
      setSearchResults(filteredResults as DishType[] | MenuType[]);
    } else {
      setPartEntities(filteredResults as IngredientType[] | UserType[]);
    }
  };

  // Méthode permettant de vérifier si le terme entré est exactement correspondant à une entité
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (name === 'dishesPart' || name === 'menusPart') {
      // Vérifie si le terme correspond exactement à l'une des entités
      const tempSelectedEntity = allEntities.find(
        (entity) => {
          if (isIngredient(entity) || isDish(entity)) {
            return entity.title.toLowerCase() === query.toLowerCase();
          }
          return false;
        },
      );
      if (tempSelectedEntity) {
        setPartEntities(tempSelectedEntity);
      } else {
        setPartEntities(undefined);
      }
    }
    filterResults(query);
  };

  return (
    <Form.Group
      className={`mb-3 ${(name !== 'dishesPart' && name !== 'menusPart')
        ? 'd-flex flex-column align-items-center'
        : ''}`}
      controlId="entitySearch"
    >
      <Form.Label>
        {name && descriptions[name as 'users' | 'dishes' | 'menus' | 'ingredients' | 'dishesPart' | 'menusPart']}
        {(name === 'dishesPart' || name === 'menusPart')
        && <span className="text-primary ml-2">*</span>}
      </Form.Label>
      <Form.Control
        type="text"
        value={searchTerm}
        onChange={handleChange}
        list={(name === 'dishesPart' || name === 'menusPart') ? 'suggestions' : ''}
        autoComplete="on"
        className={`${(name !== 'dishesPart' && name !== 'menusPart') ? 'w-30' : ''}`}
        disabled={disabled}
      />
      {(name === 'dishesPart' || name === 'menusPart')
      && searchTerm !== ''
      && searchResults.length > 0
      && (
        <datalist id="suggestions">
          {searchResults
            .filter(
              (entity): entity is DishType | IngredientType => (
                isDish(entity) || isIngredient(entity)
              )
              && entity.title.trim() !== ''
              && entity.title.toLowerCase() !== searchTerm.toLowerCase(),
            )
            .map((entity) => (
              <option key={entity.id} value={entity.title} />
            ))}
        </datalist>
      )}
    </Form.Group>
  );
}

export default Searcher;
