import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { RestaurantOrderType } from '../../../typescript/datas/OrderTypes';
import { ConfirmationType } from '../../../typescript/type/ConfirmationType';

const CartContainer = styled.div`
  position: fixed;
  top: 94px;
  right: 0;
  width: 25%;
  background-color: #202020;
  color: white !important;
  text-align: center;
  padding: 20px;
  z-index: 999;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

const CartTitle = styled.h2`
  text-decoration: underline;
  color: white !important;
`;

const CartSubtitle = styled.h3`
  color: white !important;
`;

const CartTotal = styled.h2`
  color: white !important;
  padding-top: 10px;
  margin-bottom: 10px;
`;

const CartButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CartDetails = styled(Button)`
  cursor: pointer;
  padding: 5px;
  display: inline;
  font-size: 18px;
  margin-bottom: 20px;
`;

const CartValidation = styled(Button)`
  cursor: pointer;
  padding: 5px;
  display: inline;
  font-size: 18px;
`;

type CartMinProps = {
  orders: RestaurantOrderType[],
  setOrderDetails: React.Dispatch<React.SetStateAction<boolean>>,
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationType>>,
};

function CartMin({
  orders,
  setOrderDetails,
  setConfirmation,
}: CartMinProps) {
  const totalProducts = orders.reduce((total, order) => total + order.quantity, 0);
  /*
    Utilisation de l'accumulateur de reduce pour obtenir le prix total
    basé sur celui de toutes les commandes
  */
  let totalPrice = orders.reduce((total, order) => total + (order.quantity * order.price), 0);

  // Permet d'éviter le bug des décimales
  totalPrice = (Math.round(totalPrice * 100) / 100);

  const handleOrderDetailsChange = () => {
    setOrderDetails((prevOrderDetails) => !prevOrderDetails);
  };

  return (
    <CartContainer>
      <CartTitle>Panier</CartTitle>
      <CartSubtitle>
        {totalProducts}
        {' '}
        Article(s)
      </CartSubtitle>
      <CartTotal>
        Total à payer :
        {' '}
        {totalPrice}
        {' '}
        €
      </CartTotal>
      <CartButtons>
        <CartDetails id="cartDetails" onClick={handleOrderDetailsChange}>Voir détails</CartDetails>
        {orders.length > 0 && (
          <CartValidation className="btn-success" onClick={() => setConfirmation({ display: true, totalPrice })}>Valider la commande</CartValidation>
        )}
      </CartButtons>
    </CartContainer>
  );
}

export default CartMin;
