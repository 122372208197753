import styled from 'styled-components';
import arraysAreEquals from '../../../utils/helpers/Client/dishDetails';
import { RestaurantOrderDishType } from '../../../typescript/datas/OrderDishTypes';

const OrderCell = styled.td`
  vertical-align: middle !important;
`;

const OrderProductContent = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const OrderProductImgContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

const OrderProductImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const OrderProductInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderRemove = styled.td`
  vertical-align: middle !important;
`;

const OrderCloseButton = styled.button`
  background-color: #202020;
  border: none;
`;

type DishOrderDetailsProps = {
  order: RestaurantOrderDishType,
  setOrders: React.Dispatch<React.SetStateAction<RestaurantOrderDishType[]>>,
};

function DishOrderDetails({
  order,
  setOrders,
}: DishOrderDetailsProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  // Permet la suppression d'un produit du panier
  const handleRemoveProduct = (
    productId: number,
    ingredientsRemoved: string[],
    meatSelected: string,
  ) => {
    // On retire le produit qui correspond aux conditions
    setOrders((prevOrders) => prevOrders.filter((prevOrder) => (
      !(prevOrder.id === productId
        && arraysAreEquals(prevOrder.ingredientsToRemove, ingredientsRemoved)
        && prevOrder.selectedMeat === meatSelected)
    )));
  };

  // Permet la modification de la quantité d'un produit du panier
  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    productId: number,
    ingredientsRemoved: string[],
    meatSelected: string,
  ) => {
    const { value } = e.target;
    const newValue = Number.isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10);

    // On ne change la quantité que pour le produit correspondant aux critères
    setOrders((prevOrders) => prevOrders.map((prevOrder) => {
      if (prevOrder.id === productId
          && arraysAreEquals(prevOrder.ingredientsToRemove, ingredientsRemoved)
          && prevOrder.selectedMeat === meatSelected) {
        return { ...prevOrder, quantity: newValue };
      }
      return prevOrder;
    }));
  };

  return (
    <tr>
      <OrderCell className="p-4">
        <OrderProductContent>
          <OrderProductImgContainer>
            <OrderProductImg src={`${apiUrl}/images/dishes/${order.imageName}`} alt="" />
          </OrderProductImgContainer>
          <OrderProductInfos>
            <p>{order.title}</p>
            {order.ingredientsToRemove.length > 0 && (
              <small>
                <span className="text-muted">
                  Retrait:
                </span>
                <br />
                {order.ingredientsToRemove.map(
                  (ingredientToRemove) => (
                    <span key={ingredientToRemove} className="text-muted">
                      {window.innerWidth <= 768 ? '' : '-->'}
                      {ingredientToRemove}
                      <br />
                    </span>
                  ),
                )}
              </small>
            )}
            {order.selectedMeat !== '' && (
              <small>
                <span className="text-muted">Cuisson:</span>
                <br />
                <span className="text-muted">
                  {window.innerWidth <= 768 ? '' : '-->'}
                  {order.selectedMeat}
                </span>
              </small>
            )}
          </OrderProductInfos>
        </OrderProductContent>
      </OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price}
        {' '}
        €
      </OrderCell>
      <OrderCell className="align-middle p-4"><input type="text" pattern="[0-9]*" className="form-control text-center" value={order.quantity} onChange={(e) => handleQuantityChange(e, order.id, order.ingredientsToRemove, order.selectedMeat)} /></OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price * order.quantity}
        {' '}
        €
      </OrderCell>
      <OrderRemove className="text-center align-middle px-0"><OrderCloseButton type="button" style={{ color: 'red' }} onClick={() => handleRemoveProduct(order.id, order.ingredientsToRemove, order.selectedMeat)}>x</OrderCloseButton></OrderRemove>
    </tr>
  );
}

export default DishOrderDetails;
