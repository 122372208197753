import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { HandleImageChangeType } from '../../../../typescript/functions/HandleImageChangeType';
import { PresentationOptionsType } from '../../../../typescript/datas/PresentationOptionsType';

type AboutUsOptionsFormPartProps = {
  handleSubmit: HandleSubmitType,
  handleImageChange: HandleImageChangeType,
  entries: PresentationOptionsType,
  setEntries: React.Dispatch<React.SetStateAction<PresentationOptionsType>>,
};

const PresentationSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const PresentationTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

function PresentationOptionsFormPart({
  handleSubmit,
  handleImageChange,
  entries,
  setEntries,
}: AboutUsOptionsFormPartProps) {
  return (
    <PresentationSection>
      <PresentationTitle>Section Présentation</PresentationTitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationLogo">Image du logo</FormLabel>
          <Form.Control
            type="file"
            id="presentationLogo"
            className="bg-dark"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, 'logo')}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationTitle">Titre</FormLabel>
          <FormControl
            type="text"
            id="presentationTitle"
            value={entries.title}
            onChange={(e) => setEntries({ ...entries, title: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationSubtitle">Sous-titre</FormLabel>
          <FormControl
            type="text"
            id="presentationSubtitle"
            value={entries.subtitle}
            onChange={(e) => setEntries({ ...entries, subtitle: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationTerms">Ensemble de mots du titre (séparés par des espaces)</FormLabel>
          <FormControl
            type="text"
            id="presentationTerms"
            value={entries.terms}
            onChange={(e) => setEntries({ ...entries, terms: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationButtonText">Texte du bouton</FormLabel>
          <FormControl
            type="text"
            id="presentationButtonText"
            value={entries.buttonText}
            onChange={(e) => setEntries({ ...entries, buttonText: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationAnimationSpeed">Vitesse d&apos;animation (milisecondes)</FormLabel>
          <FormControl
            type="text"
            id="presentationAnimationSpeed"
            value={entries.animationSpeed}
            onChange={(e) => setEntries(
              { ...entries, animationSpeed: parseInt(e.target.value, 10) },
            )}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationDescription">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={5}
            id="presentationDescription"
            value={entries.description}
            onChange={(e) => setEntries({ ...entries, description: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="presentationBackgroundImg">Image du background</FormLabel>
          <Form.Control
            type="file"
            id="presentationBackgroundImg"
            className="bg-dark"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, 'backgroundImg')}
          />
        </FormGroup>
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </PresentationSection>
  );
}

export default PresentationOptionsFormPart;
