/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { useApi } from '../../../utils/hooks/admin';
import { IngredientMissingType } from '../../../typescript/datas/IngredientType';
import { DishMissingType, DishType } from '../../../typescript/datas/DishTypes';
import {
  isDish,
  isDishMissing,
  isHydraMember,
  isMenu,
  isMenuMissing,
} from '../../../utils/helpers/Datatype';
import {
  MenuMissingType,
  MenuType,
} from '../../../typescript/datas/MenuType';
import { RestaurantOptionsType } from '../../../typescript/datas/RestaurantOptionsType';

const IngredientEmpty = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: end;
`;

const DishEmpty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

function StocksAlert() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const mercureUrl = process.env.REACT_APP_MERCURE_URL;

  const { authToken, fetchData } = useApi();

  // Indique que des plats  ont été récupérés
  const [isDishRetrieved, setIsDishRetrieved] = useState(false);

  // Indique que des menus ont été récupérés
  const [isMenuRetrieved, setIsMenuRetrieved] = useState(false);

  // Permet de gérer l'affichage de la modale
  const [show, setShow] = useState(false);

  // Contient tous les ingrédients dont les stocks manquent
  const [ingredients, setIngredients] = useState<IngredientMissingType[]>([]);

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  // Contient tous les plats concernés par le manque d'ingrédient
  const [dishesEmpty, setDishesEmpty] = useState<DishMissingType[]>([]);

  // Contient tous les menus concernés par le manque d'ingrédient
  const [menusEmpty, setMenusEmpty] = useState<MenuMissingType[]>([]);

  // Contient la limite en % avant de déclencher une alerte de stock
  const [stockCritical, setStockCritical] = useState<number>(0);

  // Permet d'ajouter un ingrédient parmi ceux qui ont un stock critique
  const addIngredient = (
    ingredientData: IngredientMissingType,
    remainingInPercent: number,
  ) => {
    console.log('ingredientData : ', ingredientData);
    /*
      Mercure va déclencher addIngredient à chaque fois qu'un ingrédient va atteindre
      le stock critique. Pour pouvoir mettre à jour mon state ingrédients en une seule fois
      et effectuer une seule requête avec le tableau des ingrédients obtenus, je retarde la mise
      à jour du state à chaque fois que je récupère un nouvel ingrédient (clearTimeout). Quand
      plus rien n'arrive, le timer peut arriver à son terme et déclencher la maj avec tous les
      ingrédients qui lui sont parvenus d'ici là
    */
    // Si le timer est déjà en cours on le supprime
    if (timer) {
      clearTimeout(timer);
    }

    // Je définis un timer qui exécute le code après un délai de 200ms
    const newTimer = setTimeout(() => {
      setIngredients((prevState) => {
        // Si l'ingrédient n'était pas encore dans le tableau
        if (!prevState.some((ingredient) => ingredient.id === ingredientData.id)) {
          return [
            ...prevState,
            {
              id: ingredientData.id,
              title: ingredientData.title,
              quantity: ingredientData.quantity,
              unit: ingredientData.unit,
              percentQuantity: remainingInPercent,
            },
          ];
        }
        return prevState;
      });
    }, 200);

    // Je mets à jour le timer actuel avec le nouveau timer créé
    setTimer(newTimer);
  };

  // Permet de retirer un plat de la vente
  const handleSingleDishRemove = (
    id: number,
  ) => {
    const fetchDish = async () => {
      const dishOptions = {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${authToken}`,
          'content-type': 'application/merge-patch+json',
        },
        body: JSON.stringify({
          isSaled: false,
        }),
      };

      const { data } = await fetchData<DishType>(`${apiUrl}/api/dishes/${id}`, dishOptions);

      if (data && isDish(data)) {
        const updatedDishEmpty = dishesEmpty.filter((dish) => dish.id !== data.id);

        setDishesEmpty(updatedDishEmpty);
      }
    };

    fetchDish();
  };

  // Permet de retirer un menu de la vente
  const handleSingleMenuRemove = (
    id: number,
  ) => {
    const fetchMenu = async () => {
      const menuOptions = {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${authToken}`,
          'content-type': 'application/merge-patch+json',
        },
        body: JSON.stringify({
          isSaled: false,
        }),
      };

      const { data } = await fetchData<MenuType>(`${apiUrl}/api/menus/${id}`, menuOptions);

      if (data && isMenu(data)) {
        const updatedMenuEmpty = menusEmpty.filter((menu) => menu.id !== data.id);

        setMenusEmpty(updatedMenuEmpty);
      }
    };

    fetchMenu();
  };

  // Permet de retirer les alertes générées par un ingrédient
  const handleSingleIngredientIgnore = (
    ingredientId: number,
    ingredientTitle: string,
  ) => {
    const fetchIngredient = async () => {
      const ingredientOptions = {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${authToken}`,
          'content-type': 'application/merge-patch+json',
        },
        body: JSON.stringify({
          alert: false,
        }),
      };

      await fetchData(`${apiUrl}/api/ingredients/${ingredientId}`, ingredientOptions);

      // Je retire l'ingrédient dont je viens de supprimer l'alerte
      setIngredients((prev) => prev.filter((ingredient) => ingredient.id !== ingredientId));

      /*
        Du coup on peut supprimer cet ingrédient de la liste des ingrédients
        des plats qui étaient concernés par l'alerte
      */
      setDishesEmpty((prev) => prev.map((dish) => ({
        ...dish,
        ingredientTitles: dish.ingredientTitles.filter((title) => title !== ingredientTitle),
      })));

      /*
        Du coup on peut supprimer cet ingrédient de la liste des ingrédients
        des menus qui étaient concernés par l'alerte
      */
      setMenusEmpty((prev) => prev.map((menu) => ({
        ...menu,
        ingredientTitles: menu.ingredientTitles.filter((title) => title !== ingredientTitle),
      })));
    };

    fetchIngredient();
  };

  // Permet de retirer les alertes de tous les ingrédients qui les ont lancées
  const handleAllIngredientIgnore = () => {
    const fetchAllIngredient = async () => {
      const ids = ingredients.map((ingredient) => ingredient.id);
      const ingredientsOptions = {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${authToken}`,
          'content-type': 'application/merge-patch+json',
        },
        body: JSON.stringify(ids),
      };

      await fetchData(`${apiUrl}/api/ingredientsAlert`, ingredientsOptions);
      // Je retire tous mes ingrédients car ils ne générent plus d'alerte à présent
      setIngredients([]);

      // Du coup tous mes plats ne sont plus concernés non plus par les alertes
      setDishesEmpty([]);

      // Du coup tous mes plats ne sont plus concernés non plus par les alertes
      setMenusEmpty([]);
    };

    fetchAllIngredient();
  };

  // Si on ferme simplement la modale on reset tous les states
  const handleClose = () => {
    setIngredients([]);
    setDishesEmpty([]);
    setShow(false);
  };

  /*
    Se déclenche au montage initial et permet d'obtenir le seuil d'alerte,
    de construire le cache des plats et des menus
  */
  useEffect(() => {
    const fetchOrders = async () => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      await fetchData(`${apiUrl}/api/dishes?isDeleted=false`, options);
      await fetchData(`${apiUrl}/api/menus?isDeleted=false`, options);
      const { data: restaurantData } = await fetchData<RestaurantOptionsType>(`${apiUrl}/api/restaurant_options`, options);

      if (restaurantData && isHydraMember<RestaurantOptionsType>(restaurantData)) {
        setStockCritical(restaurantData['hydra:member'][0].stockCriticalThreshold);
      }
    };
    if (authToken) {
      fetchOrders();
    }
  }, [authToken]);

  // On s'abonne au topic des ingredients pour déterminer le stock restant
  useEffect(() => {
    const mercureFetch = () => {
      // Url du topic mercure des ingredients
      const url = `${mercureUrl}/.well-known/mercure?topic=/api/ingredients`;
      const eventSource = new EventSource(url);

      // Quand un ingrédient est mise à jour
      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        // si l'alerte pour cet ingrédient est demandé
        if (eventData.alert) {
          // Pourcentage restant de l'ingrédient
          const remainingInPercent = (eventData.quantity / eventData.maxQuantity) * 100;

          if (remainingInPercent <= stockCritical) {
            setShow(true);
            addIngredient(eventData, remainingInPercent);
          }
        }
      };

      eventSource.onerror = () => {
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    };

    if (stockCritical !== null) {
      mercureFetch();
    }
  }, [stockCritical]);

  // Permet de récupérer tous les plats vendus qui ont des ingrédents manquants
  useEffect(() => {
    const fechDataAsync = async (
      ids: number[],
    ) => {
      // Convertir les IDs en une chaîne séparée par des virgules
      const idsString = ids.join(',');

      // Dans une requête GET je ne peux avoir de body. Donc je passe mes ids dans l'url
      const dishUrl = `${apiUrl}/api/dishesEmpty?ingredientIds=${idsString}`;

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const { data: dishesData } = await fetchData<DishMissingType[]>(dishUrl, options);

      if (dishesData && isDishMissing(dishesData)) {
        setDishesEmpty(dishesData);
        setIsDishRetrieved(true);
      }
    };

    if (ingredients.length > 0) {
      const ids = ingredients.map((ingredient) => ingredient.id);
      fechDataAsync(ids);
    }
  }, [ingredients]);

  // Permet de récupérer tous les menus vendus qui ont des ingrédents manquants
  useEffect(() => {
    const fechDataAsync = async (
      ids: number[],
    ) => {
      // Convertir les IDs en une chaîne séparée par des virgules
      const idsString = ids.join(',');

      // Dans une requête GET je ne peux avoir de body. Donc je passe mes ids dans l'url
      const menuUrl = `${apiUrl}/api/menusEmpty?ingredientIds=${idsString}`;

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const { data: menusData } = await fetchData<MenuMissingType[]>(menuUrl, options);

      if (menusData && isMenuMissing(menusData)) {
        setMenusEmpty(menusData);
        setIsMenuRetrieved(true);
      }
    };

    if (ingredients.length > 0) {
      const ids = ingredients.map((ingredient) => ingredient.id);
      fechDataAsync(ids);
    }
  }, [ingredients]);

  // Se déclenche quand les plats problématiques changent
  useEffect(() => {
    // Il faut absolument avoir récupéré mes plats et mes menus pour poursuivre
    if (isDishRetrieved && isMenuRetrieved) {
      // Si plus aucun plat ni menu vendu ne manque d'ingrédients
      if (dishesEmpty.length === 0 && menusEmpty.length === 0) {
        setShow(false);
      /*
        Sinon si on trouve des plats ou des menus problèmatiques
        qui n'ont plus d'ingrédients problèmatiques
      */
      } else {
        // On les retire
        const updatedDishesEmpty = dishesEmpty.filter((dish) => dish.ingredientTitles.length > 0);
        const updatedMenusEmpty = menusEmpty.filter((menu) => menu.ingredientTitles.length > 0);

        // Si on en a retiré, on met à jour notre liste de plats problèmatiques
        if (updatedDishesEmpty.length !== dishesEmpty.length) {
          setDishesEmpty(updatedDishesEmpty);
        }

        // Si on en a retiré, on met à jour notre liste de menus problèmatiques
        if (updatedDishesEmpty.length !== dishesEmpty.length) {
          setMenusEmpty(updatedMenusEmpty);
        }
      }
    }
  }, [dishesEmpty, menusEmpty, isDishRetrieved, isMenuRetrieved]);

  console.log('ingredients : ', ingredients);
  console.log('dishEmpty : ', dishesEmpty);
  console.log('menusEmpty : ', menusEmpty);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          Alerte de stock !
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6 style={{ color: '#6C7293' }}>Ingrédients manquants :</h6>
        {ingredients.map((ingredient, index) => (
          <React.Fragment key={ingredient.id}>
            <IngredientEmpty>
              <span>
                {ingredient.title}
                <br />
                {ingredient.quantity}
                {' '}
                {ingredient.unit}
                {' --> '}
                {Math.round(ingredient.percentQuantity * 100) / 100}
                {' %'}
              </span>
              {(index !== ingredients.length - 1) && (
                <>
                  <br key={`br-${ingredient.id}-1`} />
                  <br key={`br-${ingredient.id}-2`} />
                </>
              )}
              <Button onClick={() => handleSingleIngredientIgnore(ingredient.id, ingredient.title)} variant="warning">Ignorer</Button>
            </IngredientEmpty>
          </React.Fragment>
        ))}
        {dishesEmpty.length > 0 && <h6 style={{ color: '#6C7293', marginTop: '50px' }}>Plats concernés :</h6>}
        {dishesEmpty.length > 0 && dishesEmpty.map((dish) => (
          <React.Fragment key={dish.id}>
            <DishEmpty>
              <span>
                {dish.title}
                <br />
                {' ('}
                {dish.ingredientTitles.map((ingredientTitle, ingredientIndex) => (
                  <React.Fragment key={ingredientTitle}>
                    {ingredientTitle}
                    {(ingredientIndex !== dish.ingredientTitles.length - 1) && (
                      <span>
                        {', '}
                      </span>
                    )}
                  </React.Fragment>
                ))}
                {') '}
              </span>
              <Button onClick={() => handleSingleDishRemove(dish.id)}>Retirer</Button>
            </DishEmpty>
          </React.Fragment>
        ))}
        {menusEmpty.length > 0 && <h6 style={{ color: '#6C7293', marginTop: '50px' }}>Menus concernés :</h6>}
        {menusEmpty.length > 0 && menusEmpty.map((menu) => (
          <React.Fragment key={menu.id}>
            <DishEmpty>
              <span>
                {menu.title}
                <br />
                {' ('}
                {menu.ingredientTitles.map((ingredientTitle, ingredientIndex) => (
                  <React.Fragment key={ingredientTitle}>
                    {ingredientTitle}
                    {(ingredientIndex !== menu.ingredientTitles.length - 1) && (
                      <span>
                        {', '}
                      </span>
                    )}
                  </React.Fragment>
                ))}
                {') '}
              </span>
              <Button onClick={() => handleSingleMenuRemove(menu.id)}>Retirer</Button>
            </DishEmpty>
            <br />
          </React.Fragment>
        ))}
        <Modal.Footer style={{ justifyContent: 'space-around' }}>
          <Button style={{ marginLeft: '10px' }} variant="warning" onClick={handleAllIngredientIgnore}>Tout Ignorer</Button>
          <Button variant="success" onClick={handleClose}>Valider</Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default StocksAlert;
