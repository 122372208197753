import { useEffect, useState } from 'react';
import { useApi } from '../../../../utils/hooks/admin';
import SpinnerWrapper from '../../SpinnerWrapper';
import AboutUsOptionsFormPart from '../../FormPart/AboutUsOptionsFormPart';
import { HandleImageChangeType } from '../../../../typescript/functions/HandleImageChangeType';
import { DataOptionType } from '../../../../typescript/datas/DataOptionsType';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { OptionsType } from '../../../../typescript/type/OptionsType';
import {
  getBodyData,
  getImageBodyForm,
  getOptionDefaultEntryColumns,
  getUrl,
} from '../../../../utils/helpers/Generic/GenericOption';
import { ImageMemberInfosState } from '../../../../typescript/datas/ImageTypes';
import { AboutUsOptionsType } from '../../../../typescript/datas/AboutUsOptionsType';
import { PresentationOptionsType } from '../../../../typescript/datas/PresentationOptionsType';
import { DishAndMenuType } from '../../../../typescript/datas/DishAndMenuType';
import { DishType } from '../../../../typescript/datas/DishTypes';
import { MenuType } from '../../../../typescript/datas/MenuType';
import { SpecialOptionsType } from '../../../../typescript/datas/SpecialOptionsType';
import { MenuOptionsType } from '../../../../typescript/datas/MenuOptionsType';
import { TeamOptionsType } from '../../../../typescript/datas/TeamOptionsType';
import { GalleryOptionsType } from '../../../../typescript/datas/GalleryOptionsType';
import PresentationOptionsFormPart from '../../FormPart/PresentationOptionsFormPart';
import SpecialOptionsFormPart from '../../FormPart/SpecialOptionsFormPart';
import MenuOptionsFormPart from '../../FormPart/MenuOptionsFormPart';
import TeamOptionsFormPart from '../../FormPart/TeamOptionsFormPart';
import GalleryOptionsFormPart from '../../FormPart/GalleryOptionsFormPart';
import { optionsBuilder } from '../../../../utils/helpers/FormPart/SpecialOptionsFormPart';
import FooterOptionsFormPart from '../../FormPart/FooterOptionsFormPart';
import { FooterOptionsType } from '../../../../typescript/datas/FooterOptionsType';
import RestaurantOptionsFormPart from '../../FormPart/RestaurantOptionsFormPart';
import { RestaurantOptionsType } from '../../../../typescript/datas/RestaurantOptionsType';
import { isHydraMember, isTeamOptions } from '../../../../utils/helpers/Datatype';

type GenericOptionProps = {
  name: string,
};

function GenericOption({ name }: GenericOptionProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { fetchData, authToken } = useApi();

  const [isLoading, setIsLoading] = useState(true);

  // State contenant le tableau de tous les objets entité à afficher
  // IL EST IMPORTANT DE DONNER DES VALEURS PAR DÉFAUT QUAND UN OBJET EST ASSOCIÉ À UN FORMULAIRE
  const [entries, setEntries] = useState<
    Partial<DataOptionType>
  >(getOptionDefaultEntryColumns(name));

  // Permet de savoir quand le formulaire est soumis
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Contient les informations à envoyer concernant l'image donnée par l'utilisateur
  const [imageInfos, setImageInfos] = useState({});

  // Contient les informations à envoyer concernant les images de l'entité associée
  const [imageMemberInfos, setImageMemberInfos] = useState<ImageMemberInfosState>({});

  // Contient les éléments actuellement associés au carousel de special_options
  const [productsDisplayed, setProductsDisplayed] = useState<Partial<DishAndMenuType>[]>([]);

  // Contient les éléménts initialement associés au carousel de special_options
  const [initialProductsDisplayed, setInitialProductsDisplayed] = useState<
    Partial<DishAndMenuType>[]
  >([]);

  // Contient les éléments ajoutés et supprimés du carousel de special_options
  const [finalDishesAdded, setFinalDishesAdded] = useState<Partial<DishType>[]>([]);
  const [isDishTreated, setIsDishTreated] = useState(false);
  const [finalDishesRemoved, setFinalDishesRemoved] = useState<Partial<DishType>[]>([]);
  const [finalMenusAdded, setFinalMenusAdded] = useState<Partial<MenuType>[]>([]);
  const [isMenuTreated, setIsMenuTreated] = useState(false);
  const [finalMenusRemoved, setFinalMenusRemoved] = useState<Partial<MenuType>[]>([]);

  // Permet de ne traiter la 2ème entité que lorsque la 1ère l'a été
  const [firstResponse, setFirstResponse] = useState<Response>();

  // Méthode pour gérer le changement d'image pour un champ spécifique
  const handleImageChange: HandleImageChangeType = (e, fieldName) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      // Pour chaque image du formulaire, un objet lui est dédié et il est identifié par son field
      setImageInfos((prevImageInfos) => ({
        ...prevImageInfos,
        [fieldName]: {
          imageFile: file,
          imageName: file.name,
        },
      }));
    }
  };

  // Requête l'API à la soumission du formulaire
  const handleSubmit: HandleSubmitType = async (e) => {
    // Mes requêtes vont s'effectuer, j'affiche mon loading
    setIsLoading(true);

    if (e) {
      // On retire le comportement par défaut du formulaire
      e.preventDefault();
    }

    // Dans le cas d'un union type il faut toujours pouvoir vérifier le type sélectionné
    if (name === 'team_options' && isTeamOptions(entries)) {
      // Je replace les membres dans le bon ordre
      const updatedEntries = { ...entries };

      updatedEntries.teamMembers = entries.teamMembers?.sort((a, b) => a.id - b.id);
      setEntries(updatedEntries);
    }

    setFormSubmitted(true);
  };

  useEffect(() => {
    /* ************ TRAITEMENT DE LA 1ERE ENTITÉ  ************ */
    const submitFirstEntity = async () => {
      // Informations nécessaires pour la requête
      const entityOptions = optionsBuilder(authToken, entries, 'PATCH');

      const entityUrl = `${apiUrl}/api/${name}/${entries.id}`;

      const { response } = await fetchData<DataOptionType>(entityUrl, entityOptions);

      // Ma première entité a été traitée. Si il y en a une autre, elle va pouvoir l'être aussi
      setFirstResponse(response);
    };

    if (formSubmitted && Object.keys(entries).length > 0) {
      submitFirstEntity();
    }
  }, [entries, formSubmitted]);

  useEffect(() => {
    const submitSecondaryEntity = async () => {
      // FormData à envoyer qui contient le ou les images de l'entité
      let formData = new FormData();

      /* ************ TRAITEMENT DE L'ENTITÉ ASSOCIÉE  ************ */
      if (name === 'team_options' || name === 'gallery_options') {
        const secondaryEntityPutOptions = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(getBodyData(name, entries)),
        };

        const secondaryEntityPutUrl = getUrl(name);

        await fetchData(secondaryEntityPutUrl, secondaryEntityPutOptions);
      }

      /* ************ FIN DU TRAITEMENT DES ENTITÉS ************ */

      /* --------------------------------------------------- */

      /* ************ TRAITEMENT DES IMAGES DE L'ENTITÉ ************ */

      if (Object.keys(imageInfos).length > 0) {
        getImageBodyForm(formData, imageInfos);

        const imagePostOptions: OptionsType = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `${apiUrl}/api/${name}/${entries.id}`;

        // On requête l'API pour inscrire la nouvelle image
        await fetchData(imagePostUrl, imagePostOptions);

        // FormData va être réutilisé dans le traitement des images suivantes. Je le vide donc.
        formData = new FormData();
        setImageInfos({});
      }

      /* ************ TRAITEMENT DES IMAGES DE L'ENTITÉ ASSOCIÉE ************ */

      if (Object.keys(imageMemberInfos).length > 0) {
        getImageBodyForm(formData, imageMemberInfos, name);
        // Envoi de la requête avec les données FormData
        const imageMemberPostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `${apiUrl}/api/${name === 'team_options' ? 'team_members' : 'gallery_images'}`;

        // On requête l'API pour inscrire la nouvelle image
        await fetchData(imagePostUrl, imageMemberPostOptions);

        formData = new FormData();
        setImageMemberInfos({});
      }

      /* ************ FIN DU TRAITEMENT DES IMAGES DES ENTITÉS ************ */

      /* ************ TRAITEMENT DES AJOUTS / SUPP DES ÉLÉMENTS DU CAROUSEL ************ */

      // Si je traite le formulaire du carousel
      if (name === 'special_options') {
        // Si au moins 1 élément apparaît dans la liste de ceux ajoutés
        if (finalDishesAdded.length > 0) {
          const options = optionsBuilder(authToken, { operation: 'add', entries: finalDishesAdded }, 'PATCH');
          const url = `${apiUrl}/api/dishes`;

          await fetchData(url, options);
          setIsDishTreated(true);
        }
        if (finalMenusAdded.length > 0) {
          const options = optionsBuilder(authToken, { operation: 'add', entries: finalMenusAdded }, 'PATCH');
          const url = `${apiUrl}/api/menus`;

          await fetchData(url, options);
          setIsMenuTreated(true);
        }
        // Le nouveau tableau initial vaut celui des produits affichés
        setInitialProductsDisplayed(productsDisplayed);

        /* ************ FIN TRAITEMENT DES AJOUTS / SUPP DES ÉLÉMENTS DU CAROUSEL ************ */
      }

      setIsLoading(false);
      setFormSubmitted(false);
    };

    if (firstResponse && firstResponse.status === 200) {
      submitSecondaryEntity();
    }
  }, [firstResponse]);

  useEffect(() => {
    const dishRemovedProcess = async () => {
      const options = optionsBuilder(authToken, { operation: 'remove', entries: finalDishesRemoved }, 'PATCH');
      const url = `${apiUrl}/api/dishes`;

      await fetchData(url, options);
      // Je vide le tableau des éléments ajoutés une fois le traitement achevé
      setFinalDishesRemoved([]);
      setIsDishTreated(false);
    };

    if ((isDishTreated) && (finalDishesRemoved.length > 0)) {
      dishRemovedProcess();
    }
  }, [isDishTreated]);

  useEffect(() => {
    const menuRemovedProcess = async () => {
      const options = optionsBuilder(authToken, { operation: 'remove', entries: finalMenusRemoved }, 'PATCH');
      const url = `${apiUrl}/api/menus`;

      await fetchData(url, options);
      // Je vide le tableau des éléments ajoutés une fois le traitement achevé
      setFinalMenusRemoved([]);
      setIsMenuTreated(false);
    };

    if ((isMenuTreated) && (finalMenusRemoved.length > 0)) {
      menuRemovedProcess();
    }
  }, [isMenuTreated]);

  useEffect(() => {
    // Méthode permettant la récupération de la liste des entités
    const fechDataAsync = async () => {
      const options = {
        method: 'GET',
        ...(name === 'restaurant_options' ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
      };

      // Requête l'API récupérant la liste des entités et construisant le cache de ces derniers
      const { data } = await fetchData<DataOptionType>(`${apiUrl}/api/${name}`, options);

      // Si aucune donnée n'est retournée
      if (!data) {
        // On retire le loading
        setIsLoading(false);

        // On ne fait rien de plus et on sort de la méthode
        return;
      }

      if (isHydraMember<DataOptionType>(data)) {
        // Je récupère toutes les informations pertinentes concernant mes entrées
        setEntries(data['hydra:member'][0]);
      }

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    };

    fechDataAsync();
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      {name === 'presentation_options' && entries && (
        <PresentationOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries as PresentationOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<PresentationOptionsType>>}
        />
      )}
      {name === 'about_us_options' && entries && (
        <AboutUsOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries as AboutUsOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<AboutUsOptionsType>>}
        />
      )}
      {name === 'special_options' && entries && (
        <SpecialOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries as SpecialOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<SpecialOptionsType>>}
          setIsLoading={setIsLoading}
          productsDisplayed={productsDisplayed}
          setProductsDisplayed={setProductsDisplayed}
          initialProductsDisplayed={initialProductsDisplayed}
          setInitialProductsDisplayed={setInitialProductsDisplayed}
          setFinalDishesAdded={setFinalDishesAdded}
          setFinalDishesRemoved={setFinalDishesRemoved}
          setFinalMenusAdded={setFinalMenusAdded}
          setFinalMenusRemoved={setFinalMenusRemoved}
        />
      )}

      {name === 'menu_options' && entries && (
        <MenuOptionsFormPart
          handleSubmit={handleSubmit}
          entries={entries as MenuOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<MenuOptionsType>>}
        />
      )}

      {name === 'team_options' && entries && (
        <TeamOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          setImageMemberInfos={setImageMemberInfos}
          entries={entries as TeamOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<TeamOptionsType>>}
        />
      )}

      {name === 'gallery_options' && entries && (
        <GalleryOptionsFormPart
          handleSubmit={handleSubmit}
          setImageMemberInfos={setImageMemberInfos}
          entries={entries as GalleryOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<GalleryOptionsType>>}
        />
      )}

      {name === 'footer_options' && entries && (
        <FooterOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries as FooterOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<FooterOptionsType>>}
        />
      )}

      {name === 'restaurant_options' && entries && (
        <RestaurantOptionsFormPart
          handleSubmit={handleSubmit}
          entries={entries as RestaurantOptionsType}
          setEntries={setEntries as React.Dispatch<React.SetStateAction<RestaurantOptionsType>>}
        />
      )}
    </>
  );
}

export default GenericOption;
