import { DataOptionType } from '../../typescript/datas/DataOptionsType';
import { DataTablesType } from '../../typescript/datas/DataTablesType';
import { DishAndMenuType } from '../../typescript/datas/DishAndMenuType';
import { DishMissingType, DishType } from '../../typescript/datas/DishTypes';
import { GalleryOptionsType } from '../../typescript/datas/GalleryOptionsType';
import { ImageType } from '../../typescript/datas/ImageTypes';
import { IngredientType } from '../../typescript/datas/IngredientType';
import { MenuDishType } from '../../typescript/datas/MenuDishType';
import { MenuMissingType, MenuType } from '../../typescript/datas/MenuType';
import { RestaurantMenuDishType } from '../../typescript/datas/OrderMenuDishType';
import { RestaurantOrderType } from '../../typescript/datas/OrderTypes';
import { RestaurantOptionsType } from '../../typescript/datas/RestaurantOptionsType';
import { TableType } from '../../typescript/datas/TableType';
import { TeamOptionsType } from '../../typescript/datas/TeamOptionsType';
import { TokenType } from '../../typescript/datas/TokenType';
import { UserType } from '../../typescript/datas/UserType';
import { ErrorDataType } from '../../typescript/type/ErrorDataType';
import { HydraMemberType } from '../../typescript/type/HydraMemberType';

export const isHydraMember = <T>(
  entry: unknown,
): entry is HydraMemberType<T> => typeof entry === 'object' && entry !== null && 'hydra:member' in entry;

export const isData = <T>(
  entry: unknown,
): entry is T => typeof entry === 'object' && entry !== null && 'imageName' in entry;

export const isRestaurantOption = (
  entry: unknown,
): entry is RestaurantOptionsType => typeof entry === 'object' && entry !== null && 'days' in entry;

export const isTeamOptions = (
  entry: Partial<DataOptionType>,
  // Si la fonction retourne true, entry sera considérée comme TeamOptionsType
): entry is TeamOptionsType => entry && 'teamMembers' in entry;

export const isUser = (entry: unknown): entry is UserType => (
  typeof entry === 'object'
  && entry !== null
  && ('hireDate' in entry || 'username' in entry || 'realName' in entry)
);

export const isIngredient = (
  entry: unknown,
): entry is IngredientType => typeof entry === 'object' && entry !== null && 'maxQuantity' in entry;

export const isTable = (entry: unknown): entry is TableType => (
  typeof entry === 'object'
  && entry !== null
  && ('isOccupied' in entry || 'number' in entry || 'urlIdentifier' in entry)
);

export const isIngredientDishOrMenu = (
  entry: DataTablesType,
): entry is IngredientType => entry && 'title' in entry;

export const isToken = (
  entry: unknown,
): entry is TokenType => typeof entry === 'object' && entry !== null && 'token' in entry;

export const isGalleryOptions = (
  entry: Partial<DataOptionType>,
): entry is GalleryOptionsType => entry && 'galleryImages' in entry;

export const isDishOrMenu = (
  entry: DataTablesType,
): entry is DishType => entry && 'price' in entry;

export const isDish = (
  entry: unknown,
): entry is DishType => typeof entry === 'object' && entry !== null && 'cookingChoice' in entry;

export const isDishMissing = (
  entry: DishMissingType[] | ErrorDataType | HydraMemberType<DishMissingType[]>,
): entry is DishMissingType[] => Array.isArray(entry) && entry.every((item) => item && 'title' in item);

export const isMenu = (
  entry: unknown,
): entry is MenuType => typeof entry === 'object' && entry !== null && ('price' in entry && !('category' in entry));

export const isMenuMissing = (
  entry: MenuMissingType[] | ErrorDataType | HydraMemberType<MenuMissingType[]>,
): entry is MenuMissingType[] => Array.isArray(entry) && entry.every((item) => item && 'title' in item);

export const isImage = (
  entry: unknown,
): entry is ImageType => typeof entry === 'object' && entry !== null && 'imageName' in entry;

export const isRestaurantMenuDish = (
  entry: RestaurantOrderType,
): entry is RestaurantMenuDishType => entry && 'associatedDishes' in entry;

export const isMenuDishType = (
  entry: DishAndMenuType | MenuDishType[],
): entry is MenuDishType[] => Array.isArray(entry);
