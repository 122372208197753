import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { MenuOptionsType } from '../../../../typescript/datas/MenuOptionsType';

type MenuOptionsFormPartProps = {
  handleSubmit: HandleSubmitType,
  entries: MenuOptionsType,
  setEntries: React.Dispatch<React.SetStateAction<MenuOptionsType>>,
};

const MenuSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const MenuTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

function MenuOptionsFormPart({
  handleSubmit,
  entries,
  setEntries,
}: MenuOptionsFormPartProps) {
  return (
    <MenuSection>
      <MenuTitle>Section Menu</MenuTitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="menuTitle">Titre</FormLabel>
          <FormControl
            type="text"
            id="menuTitle"
            value={entries.title}
            onChange={(e) => setEntries({ ...entries, title: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="menuDescription">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={5}
            id="menuDescription"
            value={entries.description}
            onChange={(e) => setEntries({ ...entries, description: e.target.value })}
          />
        </FormGroup>
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </MenuSection>
  );
}

export default MenuOptionsFormPart;
