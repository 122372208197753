/*
  Permet de retourner la date actuelle à transmettre au champ hireDate afin que ce dernier
  ne puisse pas accepter des valeurs ultérieures à cette dernière
*/
const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // Les mois commencent à 0, d'où le + 1
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month.toString();
  const formattedDay = day < 10 ? `0${day}` : day.toString();

  return `${year}-${formattedMonth}-${formattedDay}`;
};

export default getCurrentDate;
