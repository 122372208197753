import { useState, useEffect } from 'react';
import {
  Form, Container, Row, Col, Button,
} from 'react-bootstrap';
import { useApi } from '../../../utils/hooks/admin';
import SpinnerWrapper from '../SpinnerWrapper';
import { PermissionType } from '../../../typescript/datas/PermissionType';
import { RoleType } from '../../../typescript/datas/RoleType';
import { isHydraMember } from '../../../utils/helpers/Datatype';

function RoleManagement() {
  const apiUrl = process.env.REACT_APP_API_URL;

  /*
  Contient le token d'authentification et le username de l'utilisateur connecté.
  Partagés par un provider.
  */
  const {
    fetchData, authToken, authPermissions, isArraySuperset, updateAssociativeEntity,
  } = useApi();

  // State récupérant l'ensemble des rôles enregistrés
  const [roles, setRoles] = useState<RoleType[]>([]);

  // State récupérant l'ensemble des permissions enregistrés
  const [allPermissions, setAllPermissions] = useState<PermissionType[]>([]);

  // State contenant le nom du rôle actuellement sélectionné
  const [selectedRole, setSelectedRole] = useState('Administrateur');

  // State contenant l'id du rôle actuellement sélectionné
  const [selectedRoleId, setselectedRoleId] = useState(0);

  // State contenant les informations des permissions du rôle actuellement sélectionné
  const [rolePermissions, setRolePermissions] = useState<Partial<PermissionType>[]>([]);

  // State contenant l'id des permissions initialement accordées à un rôle
  const [initialRolePermissionsId, setInitialRolePermissionsId] = useState<number[]>([]);

  // State permettant de savoir si des permissions pour un rôle ont changé
  const [changesSaved, setChangesSaved] = useState(true);

  // State permettant de "recharger la page" après validation des changements de permissions
  const [reload, setReload] = useState(true);

  // State permettant de gérer le spinner de chargement
  const [isLoading, setIsLoading] = useState(true);

  // Contiendra l'ensemble des permissions du rôle (sans doublon)
  const displayedGroups: Set<string> = new Set();

  // Informations nécessaires pour les requêtes
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  };

  // Se déclenche initialement et quand les permissions sont changées et validées
  // Récupère tous les rôles et toutes les permissions
  useEffect(() => {
    // Méthode permettant l'appel API
    const fetchRolesAndPermissions = async () => {
      // Interroge l'API en demandant la récupération de tous les Rôles
      const { data: roleData } = await fetchData<RoleType>(`${apiUrl}/api/roles`, options);

      if (!roleData) {
        setIsLoading(false);
        return;
      }

      if (isHydraMember<RoleType>(roleData)) {
        // Je recopie toutes les infos des Rôles dans mon state
        setRoles(roleData['hydra:member']);
      }

      // Interroge l'API en demandant la récupération de toutes les Permissions
      const { data: permissionData } = await fetchData <PermissionType>(
        `${apiUrl}/api/permissions`,
        options,
      );

      if (isHydraMember<PermissionType>(permissionData)) {
        // Je recopie toutes les infos des Permissions dans mon state
        setAllPermissions(permissionData['hydra:member'].map((permission) => ({
          id: permission.id,
          name: permission.name,
          scope: permission.scope,
        })));
      }

      setIsLoading(false);
    };

    if (reload) {
      setIsLoading(true);
      fetchRolesAndPermissions();
      setReload(false);
    }
  }, [reload]);

  // Se déclenche initialement et quand le rôle sélectionné change
  // Récupère toutes les permissions associées au rôle sélectionné
  useEffect(() => {
    // Contient les informations du Rôle actuellement sélectionné
    const selectedRoleObject = roles.find((role) => role.name === selectedRole);

    // Si on trouve ce Rôle dans la liste des Rôles récupérée du serveur
    if (selectedRoleObject) {
      setselectedRoleId(selectedRoleObject.id);

      // On récupère les permissions actuellement réellement attribuées au rôle sélectionné
      setInitialRolePermissionsId(selectedRoleObject.rolePermissions.map(
        (rolePermission) => rolePermission.permission.id,
      ));

      // On recopie toutes les permissions de ce Rôle dans le state approprié
      setRolePermissions(selectedRoleObject.rolePermissions.map((rolePermission) => ({
        id: rolePermission.permission.id,
        name: rolePermission.permission.name,
      })));
    } else {
      // On vide le tableau des permissions
      setRolePermissions([]);
    }
  }, [selectedRole, roles]);

  // Se déclenche initialement et chaque fois que les permissions du rôle sélectionné changent
  // Vérifie si les permissions sont différentes qu'initialement
  useEffect(() => {
    // Comme id peut être undefined (car Partial<PermissionType>[]), on s'assure de sa présence
    const rolePermissionIds = rolePermissions
      .map((rolePermission) => rolePermission.id)
      .filter((id) => id !== undefined);

    // Contient l'information sur si les permissions ont changé ou non
    const arePermissionsChanged = (isArraySuperset(
      initialRolePermissionsId,
      rolePermissionIds,
    )
    && isArraySuperset(
      rolePermissionIds,
      initialRolePermissionsId,
    ));
    // On met à jour notre state l'indiquant (true ou false)
    setChangesSaved(arePermissionsChanged);
  }, [initialRolePermissionsId, rolePermissions]);

  // Méthode déclenchée lorsque l'utilisateur choisit un Rôle dans le select
  const handleRoleChange = (
    roleName: string,
  ) => {
    // On inscrit dans le state le Rôle qui a été sélectionné
    setSelectedRole(roleName);
  };

  // Méthode déclenchée quand les permissions sont modifiées lors d'un clic sur les checkbox
  const handlePermissionChange = (
    permissionId: number,
    permissionName: string,
  ) => {
    // Met à jour les permissions liées au rôle lorsqu'une case est cochée / décochée
    setRolePermissions((prevRolePermissions) => {
      const permissionExists = prevRolePermissions.some(
        (permission) => permission.name === permissionName,
      );

      // Si la permission était déjà présente on la retire
      if (permissionExists) {
        return prevRolePermissions.filter((permission) => permission.name !== permissionName);
      }
      // Sinon on l'ajoute
      return [...prevRolePermissions, { id: permissionId, name: permissionName }];
    });
  };

  // Méthode déclenchée à la validation du formulaire et qui met à jour les associations
  const handleSubmit = async () => {
    // Récupère dans un tableau tous les ids des permissions attribuées
    const rolePermissionsId = rolePermissions
      .map((rolePermission) => rolePermission.id)
      .filter((id) => id !== undefined);

    setIsLoading(true);

    // Maj des associations User Role
    await updateAssociativeEntity(
      'role_permissions',
      selectedRoleId,
      rolePermissionsId,
      initialRolePermissionsId,
      'roles',
    );

    setInitialRolePermissionsId(rolePermissionsId);
    setReload(true);
  };

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Container fluid className="pt-4 px-4">
        <div className="bg-secondary rounded h-100 p-4">
          <Row className="justify-content-center">
            <Col sm={12} xl={12}>
              <Form.Group className="mb-3">
                <Form.Select
                  className="w-auto"
                  size="sm"
                  aria-label=".form-select-lg example"
                  onChange={(e) => handleRoleChange(e.target.value)}
                  value={selectedRole}
                >
                  <option value="" disabled>
                    Liste des rôles
                  </option>
                  {roles.map((role) => (
                    <option key={role['@id']} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={12} xl={12}>
              <Form.Group className="mb-3">
                {selectedRole && (
                  <>
                      {allPermissions.map((permission) => {
                      // Contiendra le div du scope de permission
                        let groupDiv = null;

                        if (permission.scope && !displayedGroups.has(permission.scope)) {
                          // Si le scope apparaît une fois en l'ajoute à notre ensemble de scope
                          displayedGroups.add(permission.scope);

                          // On peut construire notre div qu'on ajoutera à l'affichage
                          groupDiv = (
                            <h6
                              className=" mt-3 mb-3"
                              key={permission.scope}
                            >
                              {permission.scope}
                            </h6>
                          );
                        }
                        // Ce div contenant les checkbox sera toujours affiché
                        const checkboxDiv = (
                          <div key={permission.name}>
                            <Form.Check
                              type="checkbox"
                              label={permission.name}
                              name={permission.name}
                              id={permission.name}
                              checked={rolePermissions.some(
                                (rolePermission) => rolePermission.name === permission.name,
                              )}
                              onChange={
                                () => handlePermissionChange(permission.id, permission.name)
                              }
                              disabled={!authPermissions.includes(
                                'Mise à jour des permissions pour les rôles associés',
                              )}
                            />
                          </div>
                        );

                        // Permet l'affichage des 2 blocs
                        return [groupDiv, checkboxDiv];
                      })}
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={12} xl={12}>
              <p
                className="text-primary"
              >
                {!changesSaved && 'Des permissions ont été ajustées pour ce rôle.'}
              </p>
              <Button
                variant="primary"
                size="sm"
                disabled={changesSaved}
                onClick={handleSubmit}
              >
                Sauvegarder les changements
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default RoleManagement;
