import { createRoot } from 'react-dom/client';
import {
  Navigate,
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import Login from './pages/Login';
import Admin from './pages/Admin';
import {
  ApiProvider,
  ModalManagementProvider,
  RestaurantProvider,
  ThemeProvider,
} from './utils/context';
import Restaurant from './pages/Restaurant';

const rootElement = document.getElementById('root');

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="login"
          element={(
            <ApiProvider>
              <ModalManagementProvider>
                <ThemeProvider theme="login">
                  <Login />
                </ThemeProvider>
              </ModalManagementProvider>
            </ApiProvider>
          )}
        />
        <Route
          path="admin"
          element={(
            <ApiProvider>
              <ModalManagementProvider>
                <ThemeProvider theme="admin">
                  <Admin />
                </ThemeProvider>
              </ModalManagementProvider>
            </ApiProvider>
          )}
        />
        <Route
          path="admin/:mode"
          element={(
            <ApiProvider>
              <ModalManagementProvider>
                <ThemeProvider theme="admin">
                  <Admin />
                </ThemeProvider>
              </ModalManagementProvider>
            </ApiProvider>
          )}
        />
        <Route
          path="restaurant"
          element={(
            <RestaurantProvider>
              <ThemeProvider theme="restaurant">
                <Restaurant />
              </ThemeProvider>
            </RestaurantProvider>
          )}
        />
        <Route
          path="restaurant/:tableKey"
          element={(
            <RestaurantProvider>
              <ThemeProvider theme="restaurant">
                <Restaurant />
              </ThemeProvider>
            </RestaurantProvider>
          )}
        />
        <Route
          path="*"
          element={
            <Navigate to="/restaurant" />
          }
        />
      </Routes>
    </Router>
  );
}

if (rootElement) {
  createRoot(rootElement).render(<App />);
}
