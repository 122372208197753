import { DishType } from '../../../typescript/datas/DishTypes';
import { IngredientType } from '../../../typescript/datas/IngredientType';
import { VolumeUnitsType, WeightUnitsType } from '../../../typescript/type/UnitsType';
import { isDish, isIngredient } from '../Datatype';

// Tableau contenant toutes les catégories d'ingrédient qu'il est possible de trouver
export const ingredientCategoryChoice = [
  'Alcools',
  'Céréales',
  'Condiments',
  'Confiseries',
  'Crustacés',
  'Édulcorants',
  'Farines',
  'Fruits',
  'Herbes aromatiques',
  'Huiles',
  'Laits alternatifs',
  'Légumes',
  'Pâtes',
  'Poissons',
  'Produits animal',
  'Produits laitiers',
  'Sirops',
  'Viandes',
  'Volailles',
  'Autres',
];

// Tableau contenant toutes les catégories de plats qu'il est possible d'attribuer
export const dishCategoryChoice = [
  'Entrée',
  'Plat principal',
  'Accompagnement',
  'Dessert',
];

// Permet de déterminer le titre d'une modale
export const getModalTitle = (
  isAddModal: boolean,
  isInputDisabled: boolean,
) => {
  if (isAddModal) {
    if (isInputDisabled) {
      return 'Infos complémentaires ';
    }
    return 'Édition ';
  }
  return 'Ajout ';
};

// Méthode permettant d'obtenir la valeur de la propriété d'un ingrédient
export const getEntityProperty = (
  entityId: number,
  property: keyof IngredientType | keyof DishType,
  allAssociatedEntities: IngredientType[] | DishType[],
) => {
  const entity = allAssociatedEntities.find((item) => item.id === entityId);

  if (entity) {
    let value;
    if (isIngredient(entity)) {
      value = entity[property as keyof IngredientType];
    } else if (isDish(entity)) {
      value = entity[property as keyof DishType];
    }

    if (value instanceof Date) {
      return value.toLocaleDateString('fr-FR');
    }

    if (Array.isArray(value) || value instanceof File || value === undefined) {
      return ''; // Retourne une chaîne vide pour les valeurs non rendables
    }

    return value;
  }
  return '';
};

export const weightUnits = ['Kg', 'g', 'mg'];
export const volumeUnits = ['l', 'dl', 'cl', 'ml'];

export const getUnits = (
  baseUnit: string,
) => {
  if (weightUnits.includes(baseUnit)) {
    return weightUnits;
  }

  if (volumeUnits.includes(baseUnit)) {
    return volumeUnits;
  }

  throw new Error(`Unité inconnue : ${baseUnit}`);
};

const conversionFactors = {
  weight: {
    Kg: 1,
    g: 1000,
    mg: 1000000,
  },
  volume: {
    l: 1,
    dl: 10,
    cl: 100,
    ml: 1000,
  },
};

export const convertQuantity = (
  value: number,
  fromUnit: WeightUnitsType | VolumeUnitsType,
  toUnit: WeightUnitsType | VolumeUnitsType,
  type: 'weight' | 'volume',
) => {
  if (fromUnit === toUnit) {
    return value;
  }

  // On détermine quel objet va à être utilisé
  const factors = conversionFactors[type];

  // On indique que le type de fromUnit et toUnit doit match avec celui des clé de l'objet
  const factorFrom = conversionFactors[type][fromUnit as keyof typeof factors];
  const factorTo = conversionFactors[type][toUnit as keyof typeof factors];

  return value * (factorTo / factorFrom);
};
