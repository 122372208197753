import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import GenericOption from '../Generic/GenericOption';

const OptionsTitle = styled.h6`
  margin-bottom: 33.6px;
`;

function RestaurantManagement() {
  return (
    <Container fluid className="pt-4 px-4">
      <div className="bg-secondary rounded h-100 p-4">
        <div className="themeOptions">
          <OptionsTitle>Options Générales</OptionsTitle>
          <GenericOption name="restaurant_options" />
        </div>
      </div>
    </Container>
  );
}

export default RestaurantManagement;
