import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {
  parseISO,
  format,
  isSameDay,
} from 'date-fns';
import { fr } from 'date-fns/locale'; // Importer la locale française
import SpinnerWrapper from '../SpinnerWrapper';
import { useApi, useModalManagement } from '../../../utils/hooks/admin';
import { WorkingHourType } from '../../../typescript/datas/WorkingHourType';
import { EmployeesType, UserType } from '../../../typescript/datas/UserType';
import { isHydraMember } from '../../../utils/helpers/Datatype';

type PlanningDeleteModalProps = {
  handleClose: () => void,
  handleSuccess: () => void,
  hours: Partial<WorkingHourType>[],
};

function PlanningDeleteModal({
  handleClose,
  handleSuccess,
  hours,
}: PlanningDeleteModalProps) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authToken, fetchData } = useApi();
  const { handleSuccessInModal } = useModalManagement();

  // Contient la liste des employés
  const [employees, setEmployees] = useState<EmployeesType[]>([]);

  // Contient l'employé actuellement sélectionné
  const [selectedEmployee, setSelectedEmployee] = useState('');

  // Contient la date actuellement sélectionnée
  const [selectedDate, setSelectedDate] = useState('');

  // Contient l'id des tranches sélectionnées pour la suppression
  const [selectedHours, setSelectedHours] = useState<Set<number>>(new Set());

  const [isLoading, setIsLoading] = useState(false);

  // Filtrer les heures en fonction de l'employé sélectionné
  // Conserve toutes les entrées si Tous ou seulement celle dont l'employé apparaît dans le title
  // Filtrer selon l'activité de l'employé et s'assurer que toutes les prop requises sont définies
  const filteredHours = hours.filter((hour) => hour.activity
    && (selectedEmployee === 'Tous' || hour.activity.includes(selectedEmployee)))
    // Trier par l'heure de début;
    .sort((a, b) => (a.startTime as Date).getTime() - (b.startTime as Date).getTime());

  // Générer les dates disponibles à partir des événements filtrés
  const availableDates = [...new Set(filteredHours.map((hour) => format(hour.startTime as Date, 'yyyy-MM-dd')))];

  useEffect(() => {
    const fechDataAsync = async () => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const { data } = await fetchData<UserType>(`${apiUrl}/api/users/username`, options);

      if (!data) {
        setIsLoading(false);
        return;
      }

      if (isHydraMember<UserType>(data)) {
        setEmployees([{ id: -1, username: 'Tous' }, ...data['hydra:member'].map((entry) => ({
          id: entry.id,
          username: entry.username,
        }))]);
      }

      setIsLoading(false);
    };

    fechDataAsync();
  }, [authToken, fetchData]);

  // Permet de sélectionner le 1er employé par défaut
  useEffect(() => {
    if (employees.length > 0) {
      setSelectedEmployee(employees[1].username);
    }
  }, [employees]);

  // Réinitialiser les sélections lorsque les filtres changent
  useEffect(() => {
    setSelectedHours(new Set());
  }, [selectedEmployee, selectedDate]);

  // Gestion de la sélection des heures
  const handleSelectHour = (
    id: number,
  ) => {
    setSelectedHours((prev) => {
      // Je crée une collection en prenant l'état précédent
      const newSelection = new Set(prev);

      // Si l'id qu'on veut manipuler existe déjà
      if (newSelection.has(id)) {
        // On le retire
        newSelection.delete(id);
      } else {
        // Sinon on l'ajoute
        newSelection.add(id);
      }
      return newSelection;
    });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setIsLoading(true);

    // Informations nécessaires pour la requête
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hours: Array.from(selectedHours) }),
    };

    /*
      Interroge l'API en demandant la suppression de toutes les
      entités du tableau identifiées par leur nom
    */
    const { response } = await fetchData(`${apiUrl}/api/planning`, options);

    if (response) {
      if (!response.ok) {
        setIsLoading(false);
        return;
      }

      // Si la requête a réussi, ferme la modale et "recharge" la page
      handleSuccessInModal(response, handleClose, handleSuccess, setIsLoading);
    }
  };

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Suppression de tranches horaires
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* Sélection de l'employé */}
            <Form.Group className="mb-3" controlId="employees">
              <Form.Label>Employés</Form.Label>
              <Form.Select
                name="employees"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                {employees.map((employee) => (
                  <option
                    key={employee.id}
                    value={employee.id === -1 ? 'Tous' : employee.username}
                  >
                    {employee.username}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Sélection de la date */}
            <Form.Group className="mb-3" controlId="startTime">
              <Form.Label>Date de la tranche horaire</Form.Label>
              <Form.Select
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              >
                <option value="">Toutes</option>
                {availableDates.map((date) => (
                  <option key={date} value={date}>
                    {format(parseISO(date), 'EEE MMM d', { locale: fr })}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Affichage des heures filtrées */}
            <div>
              {filteredHours.filter((hour) => selectedDate === '' || isSameDay(hour.startTime as Date, parseISO(selectedDate))).map((hour) => (
                <div key={hour.id}>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${hour.id}`}
                    label={(
                      <>
                        {format(hour.startTime as Date, 'EEE MMM d HH:mm', { locale: fr })}
                        {' '}
                        –
                        {' '}
                        {format(hour.endTime as Date, 'HH:mm', { locale: fr })}
                        {' '}
                        {hour.activity}
                      </>
                    )}
                    // Le !! force la conversion en boolean afin de vérifier la présence de id
                    checked={selectedHours.has(hour.id as number)}
                    onChange={() => handleSelectHour(hour.id as number)}
                  />
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-center w-100">
              <Button
                variant="success"
                size="sm"
                className="me-4"
                type="submit"
                disabled={selectedHours.size === 0}
              >
                Supprimer
              </Button>
              <Button variant="primary" size="sm" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default PlanningDeleteModal;
