import { useState } from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useApi } from '../../../../utils/hooks/admin';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { OpeningHoursType } from '../../../../typescript/datas/OpeningHoursType';
import { RestaurantOptionsType } from '../../../../typescript/datas/RestaurantOptionsType';
import { isRestaurantOption } from '../../../../utils/helpers/Datatype';

type RestaurantOptionsFormPartProps = {
  handleSubmit: HandleSubmitType,
  entries: RestaurantOptionsType,
    setEntries: React.Dispatch<React.SetStateAction<RestaurantOptionsType>>,
  };

const RestaurantSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const RestaurantTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

function RestaurantOptionsFormPart({
  handleSubmit,
  entries,
  setEntries,
}: RestaurantOptionsFormPartProps) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { fetchData, authToken } = useApi();
  const [daysRange, setDaysRange] = useState('');
  const [hoursRange, setHoursRange] = useState('');
  const [error, setError] = useState('');

  const daysPattern = /^(Lundi|Mardi|Mercredi|Jeudi|Vendredi|Samedi|Dimanche)\s*-\s*(Lundi|Mardi|Mercredi|Jeudi|Vendredi|Samedi|Dimanche)$/i;
  const hoursPattern = /^([01]\d|2[0-3]):([0-5]\d)\s*-\s*([01]\d|2[0-3]):([0-5]\d)$/;

  const validateDaysRange = (
    range: string,
  ) => {
    const match = range.match(daysPattern);
    if (!match) return false;
    const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    return daysOfWeek.indexOf(match[1]) < daysOfWeek.indexOf(match[2]);
  };

  const validateHoursRange = (
    range: string,
  ) => {
    const match = range.match(hoursPattern);
    if (!match) return false;
    const startTime = parseInt(match[1] + match[2], 10);
    const endTime = parseInt(match[3] + match[4], 10);
    return startTime < endTime;
  };

  const handleAddOpeningHours = async () => {
    if (!validateDaysRange(daysRange)) {
      setError('La plage de jours est invalide ou les jours ne sont pas dans l\'ordre.');
      return;
    }
    if (!validateHoursRange(hoursRange)) {
      setError('La plage horaire est invalide ou les heures ne sont pas dans l\'ordre.');
      return;
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'application/ld+json',
      },
      body: JSON.stringify({
        days: daysRange,
        hours: hoursRange,
        restaurantOption: `${apiUrl}/api/restaurant_options/${entries.id}`,
      }),
    };

    const { data } = await fetchData<RestaurantOptionsType>(`${apiUrl}/api/opening_hours`, options);

    if (data && isRestaurantOption(data)) {
      setEntries((prevEntries) => ({
        ...prevEntries,
        openingHours: [
          // Si openingHours non défini alors []
          ...prevEntries.openingHours ?? [],
          {
            id: data.id,
            days: daysRange,
            hours: hoursRange,
          },
        ],
      }));
    }

    setDaysRange('');
    setHoursRange('');
    setError('');

    handleSubmit();
  };

  const handleDeleteOpeningHour = async (
    item: OpeningHoursType,
  ) => {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    await fetchData<OpeningHoursType>(`${apiUrl}/api/opening_hours/${item.id}`, options);

    setEntries((prevEntries) => ({
      ...prevEntries,
      openingHours: prevEntries.openingHours?.filter((openingHour) => openingHour.id !== item.id),
    }));

    handleSubmit();
  };

  return (
    <>
      <RestaurantSection>
        <RestaurantTitle>Horaires d&apos;ouverture</RestaurantTitle>
        <Form>
          <Form.Group className="mb-3" controlId="formDaysRange">
            <Form.Label>Plage de jours</Form.Label>
            <Form.Control
              type="text"
              value={daysRange}
              onChange={(e) => setDaysRange(e.target.value)}
              placeholder="ex: Lundi - Vendredi"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formHoursRange">
            <Form.Label>Plage horaire</Form.Label>
            <Form.Control
              type="text"
              value={hoursRange}
              onChange={(e) => setHoursRange(e.target.value)}
              placeholder="ex: 08:00 - 16:00"
            />
          </Form.Group>

          {error && <p className="text-primary">{error}</p>}

          <Button variant="success" onClick={handleAddOpeningHours}>
            Ajouter
          </Button>

          <hr />

          <h5>Horaires actuelles :</h5>
          <ul>
            {entries.openingHours?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} style={{ marginBottom: '20px' }}>
                {item.days}
                {' '}
                :
                {' '}
                {item.hours}
                <Button
                  variant="danger"
                  onClick={() => handleDeleteOpeningHour(item)}
                  style={{ marginLeft: '10px' }}
                >
                  Supprimer
                </Button>
              </li>
            ))}
          </ul>
        </Form>
        <p style={{ margin: '0px' }}>
          (Les clients ne peuvent commander en dehors de ces horaires)
        </p>
      </RestaurantSection>

      <RestaurantSection>
        <RestaurantTitle>Alertes de stock</RestaurantTitle>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="stockWarningThreshold">
            <Form.Label>
              % restant d&apos;un ingrédient avant avertissement
              <br />
              - Les informations de l&apos;ingrédient apparaissent
              {' '}
              en jaune dans le tableau de ces derniers
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.stockWarningThreshold}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                stockWarningThreshold: parseInt(e.target.value, 10),
              }))}
              min="1"
              max="50"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="stockCriticalThreshold">
            <Form.Label>
              % restant d&apos;un ingrédient avant seuil critique
              <br />
              - Les informations de l&apos;ingrédient apparaissent
              {' '}
              en rouge dans le tableau de ces derniers
              <br />
              - Un message apparaît et propose de retirer de la vente les plats
              {' '}
              qui en contiennent
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.stockCriticalThreshold}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                stockCriticalThreshold: parseInt(e.target.value, 10),
              }))}
              min="1"
              max="10"
            />
          </Form.Group>
          <Button variant="success" type="submit">
            Valider
          </Button>
        </Form>
      </RestaurantSection>

      <RestaurantSection>
        <RestaurantTitle>Temps d&apos;attente des commandes</RestaurantTitle>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="averageWaitTime">
            <Form.Label>
              Le temps d&apos;attente est moyen quand il est supérieur à : (en min)
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.averageWaitTime}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                averageWaitTime: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="longWaitTime">
            <Form.Label>
              Le temps d&apos;attente est long quand il est supérieur à : (en min)
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.longWaitTime}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                longWaitTime: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="refreshInterval">
            <Form.Label>
              Le temps d&apos;attente doit être calculé toutes les : (en s)
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.refreshInterval}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                refreshInterval: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Button variant="success" type="submit">
            Valider
          </Button>
        </Form>
      </RestaurantSection>

      <RestaurantSection>
        <RestaurantTitle>Graphiques</RestaurantTitle>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="totalOrderAim">
            <Form.Label>
              Nombre de commandes visées par jour
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.totalOrderAim}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                totalOrderAim: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="totalMonthOrderAim">
            <Form.Label>
              Nombre de commandes visées par mois
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.totalMonthOrderAim}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                totalMonthOrderAim: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="profitAim">
            <Form.Label>
              Recette visée par jour
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.profitAim}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                profitAim: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="monthProfitAim">
            <Form.Label>
              Recette visée par mois
            </Form.Label>
            <Form.Control
              className="w-20"
              type="number"
              value={entries.monthProfitAim}
              onChange={(e) => setEntries((prevEntries) => ({
                ...prevEntries,
                monthProfitAim: parseInt(e.target.value, 10),
              }))}
            />
          </Form.Group>
          <Button variant="success" type="submit">
            Valider
          </Button>
        </Form>
      </RestaurantSection>
    </>
  );
}

export default RestaurantOptionsFormPart;
