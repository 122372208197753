import { DataOptionType } from '../../../typescript/datas/DataOptionsType';
import { DishAndMenuType } from '../../../typescript/datas/DishAndMenuType';
import { DishType } from '../../../typescript/datas/DishTypes';
import { MenuType } from '../../../typescript/datas/MenuType';

/* eslint-disable max-len */
export const carouselIndexSort = (
  productsDisplayed: Partial<DishAndMenuType>[],
) => {
  // Je reclasse mon tableau en ordre croissant d'index de carousel
  productsDisplayed.sort((a, b) => {
    // Comparaison des valeurs de carouselIndex
    if (a.carouselIndex && b.carouselIndex && a.carouselIndex < b.carouselIndex) {
      return -1; // a doit venir avant b
    }
    if (a.carouselIndex && b.carouselIndex && a.carouselIndex > b.carouselIndex) {
      return 1; // a doit venir après b
    }
    return 0; // Les valeurs sont égales, pas de changement d'ordre
  });
};

export const finalProductDisplayedBuilder = (
  initialProductsDisplayed: Partial<DishAndMenuType>[],
  productsDisplayed: Partial<DishAndMenuType>[],
  dishes: Partial<DishType>[],
  menus: Partial<MenuType>[],
) => {
  const finalDishesAdded: Partial<DishType>[] = [];
  const finalDishesRemoved: Partial<DishType>[] = [];
  const finalMenusAdded: Partial<MenuType>[] = [];
  const finalMenusRemoved: Partial<MenuType>[] = [];

  // Pour chaque produit du tableau initial
  initialProductsDisplayed.forEach((initialProductDisplayed) => {
    // On vérifie qu'il est possible de le trouver dans le tableau final
    const found = productsDisplayed.find((productDisplayed) => productDisplayed.id === initialProductDisplayed.id);
    // Si il n'est pas trouvé c'est qu'il a été supprimé
    if (!found) {
      // Je vérifie si il s'agit d'un Dish
      const removedDishProduct = dishes.find((dish) => dish.id === initialProductDisplayed.id);
      // ou si il s'agit d'un Menu
      const removedMenuProduct = menus.find((menu) => menu.id === initialProductDisplayed.id);
      if (removedDishProduct) {
        // Je peux ajouter cet élément au tableau des Dish supprimés
        finalDishesRemoved.push(initialProductDisplayed);
      } else if (removedMenuProduct) {
        // Je peux ajouter cet élément au tableau des Menu supprimés
        finalMenusRemoved.push(initialProductDisplayed);
      }
      // Si je l'ai trouvé mais que son index de carousel a changé
    } else if (found && initialProductDisplayed.carouselIndex !== found.carouselIndex) {
      // Je vérifie si il s'agit d'un Dish
      const addedDishProduct = dishes.find((dish) => dish.id === initialProductDisplayed.id);
      // ou si il s'agit d'un Menu
      const addedMenuProduct = menus.find((menu) => menu.id === initialProductDisplayed.id);
      if (addedDishProduct) {
        // Je peux ajouter cet élément au tableau des ajouts de Dish
        finalDishesAdded.push(found);
      } else if (addedMenuProduct) {
        // Je peux ajouter cet élément au tableau des ajouts de Menu
        finalMenusAdded.push(found);
      }
    }
  });

  // Pour chaque produit du tableau final
  productsDisplayed.forEach((productDisplayed) => {
    // On vérifie qu'il est possible de le trouver dans le tableau initial
    const found = initialProductsDisplayed.find((initialProductDisplayed) => initialProductDisplayed.id === productDisplayed.id);
    // Si ce n'est pas le cas c'est qu'il a été ajouté
    if (!found) {
      // Je vérifie si il s'agit d'un Dish
      const addedDishProduct = dishes.find((dish) => dish.id === productDisplayed.id);
      // ou si il s'agit d'un Menu
      const addedMenuProduct = menus.find((menu) => menu.id === productDisplayed.id);
      if (addedDishProduct) {
        // Je peux ajouter cet élément au tableau des ajouts de Dish
        finalDishesAdded.push(productDisplayed);
      } else if (addedMenuProduct) {
        // Je peux ajouter cet élément au tableau des ajouts de Menu
        finalMenusAdded.push(productDisplayed);
      }
    }
  });

  return {
    finalDishesAdded,
    finalDishesRemoved,
    finalMenusAdded,
    finalMenusRemoved,
  };
};

export const optionsBuilder = (
  authToken: string,
  body: {
    operation: string;
    entries: Partial<DishType>[] | Partial<MenuType>[];
  } | Partial<DataOptionType>,
  meth: string,
) => {
  const bodyCopy = { ...body };

  if ('@id' in bodyCopy) {
    delete bodyCopy['@id'];
  }

  if ('@type' in bodyCopy) {
    delete bodyCopy['@type'];
  }

  if ('logo' in bodyCopy) {
    delete bodyCopy.logo;
  }

  if ('backgroundImg' in bodyCopy) {
    delete bodyCopy.backgroundImg;
  }

  if ('mainImg' in bodyCopy) {
    delete bodyCopy.mainImg;
  }

  if ('secondaryImg' in bodyCopy) {
    delete bodyCopy.secondaryImg;
  }

  return {
    method: meth,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'content-type': 'application/merge-patch+json',
    },
    body: JSON.stringify(bodyCopy),
  };
};
