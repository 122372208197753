import { Button, Modal } from 'react-bootstrap';

type QRCode = {
  qrCode: string | null,
  setQrCode: React.Dispatch<React.SetStateAction<string | null>>,
}

function QRCode({
  qrCode,
  setQrCode,
}: QRCode) {
  return (
    <Modal show onHide={() => setQrCode(null)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          QR Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          Imprimez puis présentez ce QR Code aux clients pour leur
          permettre de réserver pour la table associée.
        </p>
        <p>
          {qrCode ? <img src={qrCode} alt="QR Code" /> : <p>Aucun QR Code disponible</p>}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center w-100">
          <Button variant="primary" size="sm" onClick={() => setQrCode(null)}>
            Fermer
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default QRCode;
