import { createContext } from 'react';
import { CacheType, UpdateCacheType } from './CacheTypes';
import { HandleSuccessInModalType } from './ModalTypes';
import { WindowSizeType } from './WindowSizeTypes';
import { FetchDataType } from './FetchDataTypes';
import { SetArrayStringStateType, SetBooleanStateType, SetStringStateType } from './StateTypes';
import { UpdateUserAuthType } from '../type/AuthType';
import { isArraySupersetType, updateAssociativeEntityType } from './AssociativeEntityUpdaterTypes';
import themeImports from '../../utils/helpers/themeImports';

type ApiContextType = {
  isLoading: boolean,
  setIsLoading: SetBooleanStateType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: CacheType<any>,
  updateCache: UpdateCacheType,
  resetCache: () => void,
  errors: string[],
  setErrors: SetArrayStringStateType,
  fetchData: FetchDataType,
  authToken: string,
  authId: number,
  authRoles: string[],
  authUser: string,
  authImg: string,
  setAuthImg: SetStringStateType,
  authPermissions: string[],
  updateUserAuth: UpdateUserAuthType,
  resetUserAuth: () => void,
  isArraySuperset: isArraySupersetType,
  updateAssociativeEntity: updateAssociativeEntityType,
  windowSize: WindowSizeType;
};

export const ApiContext = createContext<ApiContextType | undefined>(undefined);

type ModalManagementContextType = {
  handleSuccessInModal: HandleSuccessInModalType,
};

export const ModalManagementContext = createContext<
  ModalManagementContextType | undefined
>(undefined);

type RestaurantContextType = {
  fetchRestaurantData: FetchDataType,
};

export const RestaurantContext = createContext<RestaurantContextType | undefined>(undefined);

type ThemeContextType = {
  theme: keyof typeof themeImports;
};

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);
