/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import arraysAreEquals from '../../../utils/helpers/Client/dishDetails';
import { RestaurantOrderType } from '../../../typescript/datas/OrderTypes';
import { ProductDetailsType } from '../../../typescript/type/ProductDetailsType';
import { RestaurantMenuDishType } from '../../../typescript/datas/OrderMenuDishType';
import { MenuDishType } from '../../../typescript/datas/MenuDishType';
import { DishType } from '../../../typescript/datas/DishTypes';
import { isMenuDishType } from '../../../utils/helpers/Datatype';
import { RestaurantOrderDishType } from '../../../typescript/datas/OrderDishTypes';

const DishContainer = styled.div<{
  $carouselIndex: number,
  $isFromMenu: boolean,
  }>`
  display: flex;
  // Règle le soucis de taille des éléments de carousel
  flex: 0 0 100%;
  flex-direction: row;
  background-color: #202020;
  z-index: 9999;
  padding: 30px;
  color: white !important;
  font-size: 18px;
  vertical-align: auto !important;
  transition: transform 2s ease;
  transform: translateX(${(props) => props.$carouselIndex * -100}%);
  width: 80%;
  overflow-y: scroll;
  max-height: 90vh;
  position: ${({ $isFromMenu }) => ($isFromMenu ? 'static' : 'fixed')};
  ${({ $isFromMenu }) => !$isFromMenu
  && `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

  @media screen and (min-width: 2000px) {
    width: 50%;
  }

  p {
    margin: 0px;
  }

  label {
    font-weight: initial;
  }
`;

const DishImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const DishImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;

const DishInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 20px 0px 20px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
  }
`;

const DishTitle = styled.h1`
  text-align: center;
  color: white;
  font-family: 'nautilus_pompiliusregular';
  font-size: 52px;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
`;

const DishChoice = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
`;

const DishSubInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
`;

const DishDescription = styled.div`
  padding: 10px 0px 10px 0px;
`;

const DishIngredients = styled.div`
  padding: 10px 0px 10px 0px;
`;

const DishIngredient = styled.span`
  &.allergen {
    font-weight: bold;
    color: orange;
    cursor: pointer;
    position: relative;
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.allergen:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

const DishNeeds = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const DishIngredientRemove = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
`;

const DishionCookingMeat = styled.div`
  display: flex;
  flex-direction: column;
`;

const DishFinalNeeds = styled.div`
  padding: 10px 0px 10px 0px;
`;

const DishValidation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
`;

const DishAdd = styled(Button)`
  width: 150px;
  white-space: normal;

  @media screen and (max-width: 480px) {
    width: 100px;
  }
`;

const DishAbord = styled(Button)`
  width: 150px;

  @media screen and (max-width: 480px) {
    width: 100px;
  }
`;

type DishDetailsProps = {
  dish: DishType | MenuDishType[],
  orders: RestaurantOrderType[],
  setOrders: React.Dispatch<React.SetStateAction<RestaurantOrderType[]>>,
  setDishDetails: React.Dispatch<React.SetStateAction<ProductDetailsType>>,
  tableNumber: number,
  carouselIndex?: number,
  setCarouselIndex?: React.Dispatch<React.SetStateAction<number>>,
  isFromMenu?: boolean,
  menuOrderInfos?: RestaurantMenuDishType,
  setMenuOrderInfos?: React.Dispatch<React.SetStateAction<RestaurantMenuDishType>>,
};

function DishDetails({
  dish,
  orders,
  setOrders,
  setDishDetails,
  tableNumber,
  carouselIndex,
  setCarouselIndex,
  isFromMenu = false,
  menuOrderInfos,
  setMenuOrderInfos,
}: DishDetailsProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  // Contient tous les types de cuisson possible
  const cookings = ['Bleue', 'Saignante', 'À point', 'Bien cuite'];

  // Contient le plat actuellement selectionné d'une catégorie (Menu)
  const [dishSelected, setDishSelected] = useState<string | DishType | MenuDishType[]>(isMenuDishType(dish) ? dish[0].dish : '');

  // Contient le tableau des dishIngredient d'où on peut retrouver les ingredients des plats
  const [dishIngredients, setDishIngredients] = useState(
    isMenuDishType(dish) ? dish[0].dish.dishIngredients : dish.dishIngredients,
  );

  // Tableau contenant tous les ingrédients supprimés
  const [ingredientsToRemove, setIngredientsToRemove] = useState<string[]>([]);

  // Contient le type de cuisson actuellement sélectionné
  const [selectedMeat, setSelectedMeat] = useState(
    ((dishSelected as DishType).cookingChoice || (dish as DishType).cookingChoice) ? cookings[0] : '',
  );

  // Contient les dishIngredients qui sont retirables
  const validOptions = isFromMenu
    ? (dishSelected as DishType).dishIngredients.filter(
      (dishIngredient) => dishIngredient.isRemovable,
    )
    : dishIngredients.filter(
      (dishIngredient) => dishIngredient.isRemovable,
    );

  // Contient l'ingrédient actuellement sélectionné pour la suppression
  const [selectedIngredientToRemove, setSelectedIngredientToRemove] = useState(
    validOptions.length > 0 ? validOptions[0].ingredient.title : '',
  );

  // Permet d'indiquer qu'on a atteint la fin de la prise de commande pour un menu
  const [endOfMenuOrder, setEndOfMenuOrder] = useState(false);

  // Permet de sélectionner un ingrédient en vue de sa suppression
  const handleIngredientChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedIngredientToRemove(e.target.value);
  };

  // Permet de changer le plat affiché en se basant sur la position de celui sélectionné
  const handleDishChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedIndex = Number(e.target.selectedOptions[0].getAttribute('data-index') ?? -1);

    // On doit mettre à jour le plat affiché ET ses ingrédients affichés
    setDishSelected((dish as MenuDishType[])[selectedIndex].dish);
    setDishIngredients((dish as MenuDishType[])[selectedIndex].dish.dishIngredients);

    // Quand on change de plat, on reset les choix effectués sur celui qu'on abandonne
    setIngredientsToRemove([]);
  };

  // Permet d'ajouter l'ingrédient sélectionné au tableau de ceux supprimés
  const handleIngredientSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setIngredientsToRemove((prevIngredients) => [...prevIngredients, selectedIngredientToRemove]);
  };

  // Permet de sélectionner un type de cuisson pour la viande en vue de sa validation
  const handleMeatChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedMeat(e.target.value);
  };

  // Permet de fermer la modale du produit et de réinitialiser les states
  const handleAbord = () => {
    // Ferme la modale de description du produit
    setDishDetails((prevDetails) => ({ ...prevDetails, display: false }));

    // Réinitialisation de tous les states (car la modale est cachée et pas démontée)
    setIngredientsToRemove([]);
    setSelectedMeat('');
    setSelectedIngredientToRemove('');
  };

  // Permet la validation d'un produit et son ajout dans le panier (Dish)
  const handleDishValidate = (
    dishSaled: DishType,
    ingredientsRemoved: string[],
    meatSelected: string,
  ) => {
    // Permet d'indiquer si le produit était déjà dans le panier
    let isExistingObjectFound = false;

    // Je recherche parmi les produits si il en existe déjà un similaire dans le panier
    const updatedOrders = orders.map((order) => {
      // Si c'est le cas
      if (
        order.id === dishSaled.id
        && arraysAreEquals(
          (order as RestaurantOrderDishType).ingredientsToRemove,
          ingredientsRemoved,
        )
        && (order as RestaurantOrderDishType).selectedMeat === meatSelected
      ) {
        // Je l'indique avec le boolean
        isExistingObjectFound = true;

        // Je le modifie en ajoutant 1 de quantité
        return { ...order, quantity: order.quantity + 1 };
      }
      return order;
    });

    // Si je n'ai pas trouvé de correspondance j'ajoute ce produit pour la première fois
    if (!isExistingObjectFound) {
      const orderWithInfos = {
        id: dishSaled.id,
        title: dishSaled.title,
        imageName: dishSaled.imageName,
        price: dishSaled.price,
        quantity: 1,
        ingredientsToRemove,
        selectedMeat,
      };
      updatedOrders.push(orderWithInfos);
    }

    setOrders(updatedOrders);

    // On ferme la modale quand le produit est ajouté
    handleAbord();
  };

  // Met à jour les informations du menu choisi avec le plat tout juste validé
  const handleMenuValidate = (
    buttonText: string,
  ) => {
    // Contient les informations pertinentes du plat choisi à ajouter au menu commandé
    const dishWithInfos = {
      id: (dishSelected as DishType).id,
      title: (dishSelected as DishType).title,
      imageName: (dishSelected as DishType).imageName,
      price: (dishSelected as DishType).price,
      quantity: 1,
      ingredientsToRemove,
      selectedMeat,
    };

    setCarouselIndex?.((prevCarouselIndex) => {
      // Tant qu'on est pas en bout de prise de commande
      if (buttonText !== 'Valider Dessert') {
        // On incrémente le carousel
        return prevCarouselIndex + 1;
      }
      // On ne bouge plus
      return prevCarouselIndex;
    });

    // Je mtes à jour mon objet contenu les informations du menu et des plats associés
    setMenuOrderInfos?.((prevMenuOrderInfos) => ({
      ...prevMenuOrderInfos,

      // En ajoutant dans le tableau des plats ajoutés, le plat validé
      associatedDishes: [...(prevMenuOrderInfos.associatedDishes || []), dishWithInfos],
    }));

    // Si on est au bout de la prise de commande
    if (buttonText === 'Valider Dessert') {
      setEndOfMenuOrder(true);
    }
  };

  // Se déclenche quand un ingrédient est retiré
  useEffect(() => {
    // Si il y a des ingrédients retirés
    if (ingredientsToRemove.length > 0) {
      // Je cherche la nom du prochain ingrédient retirable qui n'a pas encore été retiré
      const firstIngredientNotRemoved = dishIngredients.find(
        (dishIngredient) => !ingredientsToRemove.includes(dishIngredient.ingredient.title)
        && dishIngredient.isRemovable,
      );

      // Si j'en trouve un
      if (firstIngredientNotRemoved) {
        // Il devient la nouvelle valeur par défaut du select
        setSelectedIngredientToRemove(firstIngredientNotRemoved.ingredient.title);
      }
    }
  }, [ingredientsToRemove]);

  useEffect(() => {
    setSelectedMeat(((dishSelected as DishType).cookingChoice || (dish as DishType).cookingChoice) ? cookings[0] : '');
    setSelectedIngredientToRemove(validOptions.length > 0 ? validOptions[0].ingredient.title : '');
  }, [dishSelected]);

  useEffect(() => {
    // Quand on atteint la fin de la commande
    if (endOfMenuOrder) {
      // L'objet contenu le menu et les plats associés est construit. Je l'add dans order
      setOrders((prevOrder) => [...prevOrder, menuOrderInfos as RestaurantOrderType]);

      // Je retire la modale et je reset les states
      handleAbord();
    }
  }, [endOfMenuOrder]);

  return (
    <DishContainer $isFromMenu={isFromMenu} $carouselIndex={carouselIndex as number}>
      <DishImageContainer>
        <DishImage src={`${apiUrl}/images/dishes/${isFromMenu ? (dishSelected as DishType).imageName : (dish as DishType).imageName}`} />
      </DishImageContainer>
      <DishInfos>
        <DishTitle>
          {isFromMenu ? (dishSelected as DishType).title : (dish as DishType).title}
        </DishTitle>
        {isFromMenu && (
          <DishChoice>
            <Form>
              <Form.Group className="mb-3">
                <Form.Select
                  onChange={handleDishChange}
                  value={(dishSelected as DishType).title}
                  style={{ marginBottom: '10px', color: 'black' }}
                >
                  {(dish as MenuDishType[]).map((dishElem, index) => (
                    <option
                      value={dishElem.dish.title}
                      key={dishElem.id}
                      data-index={index}
                    >
                      {dishElem.dish.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </DishChoice>
        )}
        <DishSubInfos>
          <p>
            <span style={{ textDecoration: 'underline' }}>Catégorie</span>
            {' '}
            :
            {' '}
            {isFromMenu ? (dishSelected as DishType).category : (dish as DishType).category}
          </p>
          <p>
            <span style={{ textDecoration: 'underline' }}>Prix</span>
            {' '}
            :
            {' '}
            {isFromMenu ? '/' : (dish as DishType).price}
            {' '}
            {isFromMenu ? '' : '€'}
          </p>
        </DishSubInfos>
        <DishDescription>
          {isFromMenu ? (dishSelected as DishType).description : (dish as DishType).description}
        </DishDescription>
        <DishIngredients>
          <span style={{ textDecoration: 'underline' }}>Ingrédients</span>
          {' '}
          :
          {' '}
          {dishIngredients.map((dishIngredient, index) => (
            <DishIngredient
              key={dishIngredient.ingredient.id}
              className={dishIngredient.ingredient.isAllergen ? 'allergen' : ''}
            >
              {dishIngredient.ingredient.title}
              {dishIngredient.ingredient.isAllergen && (
                <div className="tooltip">Allergène possible</div>
              )}
              {index < dishIngredients.length - 1 && ', '}
            </DishIngredient>
          ))}
        </DishIngredients>
        {tableNumber !== -1 && (
          <DishNeeds>
            {validOptions.length !== ingredientsToRemove.length && (
              <DishIngredientRemove>
                <Form onSubmit={handleIngredientSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ display: 'block' }}>
                      <span style={{ textDecoration: 'underline' }}>Retrait d&apos;ingrédients</span>
                      {' '}
                      :
                    </Form.Label>
                    <Form.Select
                      onChange={handleIngredientChange}
                      value={selectedIngredientToRemove}
                      style={{ marginBottom: '10px', color: 'black' }}
                    >
                      {validOptions.map((dishIngredient) => (
                        !ingredientsToRemove.includes(dishIngredient.ingredient.title) && (
                        <option
                          value={dishIngredient.ingredient.title}
                          key={dishIngredient.ingredient.id}
                        >
                          {dishIngredient.ingredient.title}
                        </option>
                        )))}
                    </Form.Select>
                  </Form.Group>
                  <Button type="submit" variant="danger">Retirer</Button>
                </Form>
              </DishIngredientRemove>
            )}
            {(isFromMenu
              ? (dishSelected as DishType).cookingChoice
              : (dish as DishType).cookingChoice) && (
              <DishionCookingMeat>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ display: 'block' }}>
                      <span style={{ textDecoration: 'underline' }}>Cuisson de la viande</span>
                      {' '}
                      :
                    </Form.Label>
                    <Form.Select
                      onChange={handleMeatChange}
                      value={selectedMeat}
                      style={{ marginBottom: '10px', color: 'black' }}
                    >
                      {cookings.map((cooking) => (
                        <option
                          value={cooking}
                          key={cooking}
                        >
                          {cooking}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </DishionCookingMeat>
            )}
          </DishNeeds>
        )}
        {ingredientsToRemove.length > 0 && (
          <DishFinalNeeds>
            <span style={{ textDecoration: 'underline' }}>Retiré(s)</span>
            {' '}
            :
            {' '}
            {ingredientsToRemove.map((ingredientToRemove, index) => (
              <span key={ingredientToRemove}>
                {ingredientToRemove}
                {index < ingredientsToRemove.length - 1 && ', '}
              </span>
            ))}
          </DishFinalNeeds>
        )}
        <DishValidation>
          {/* Si je commande */}
          {tableNumber !== -1 && (
            <DishAdd
              variant="success"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                const dataText = e.currentTarget.getAttribute('data-text');
                if (dataText) {
                  return isFromMenu
                    ? handleMenuValidate(dataText)
                    : handleDishValidate(dish as DishType, ingredientsToRemove, selectedMeat);
                }
                return handleDishValidate(dish as DishType, ingredientsToRemove, selectedMeat);
              }}
              data-text={`Valider ${(dishSelected as DishType).category === 'Accompagnement' ? 'À-côté' : (dishSelected as DishType).category}`}
            >
              {isFromMenu ? `Valider ${(dishSelected as DishType).category === 'Accompagnement' ? 'À-côté' : (dishSelected as DishType).category}` : 'Ajouter au panier'}
            </DishAdd>
          )}
          {/* Si je ne commande pas et que je regarde les plats d'un menu */}
          {tableNumber === -1 && isFromMenu && (dishSelected as DishType).category !== 'Dessert' && (
            <DishAdd
              variant="success"
              onClick={(() => setCarouselIndex?.((prevCarouselIndex) => prevCarouselIndex + 1))}
            >
              {(() => {
                if ((dishSelected as DishType).category === 'Entrée') {
                  return 'Voir Plat principal';
                }
                if ((dishSelected as DishType).category === 'Plat principal') {
                  return 'Voir À-côté';
                }
                if ((dishSelected as DishType).category === 'Accompagnement') {
                  return 'Voir Dessert';
                }
                return '';
              })()}
            </DishAdd>
          )}
          <DishAbord variant="danger" onClick={handleAbord}>
            Fermer
          </DishAbord>
        </DishValidation>
      </DishInfos>
    </DishContainer>
  );
}

export default DishDetails;
