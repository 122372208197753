import styled from 'styled-components';

const LoaderContainer = styled.div<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  pointer-events: none;
`;

type SpinnerProps = {
  $showSpinner: boolean,
};

function Spinner({ $showSpinner }: SpinnerProps) {
  return (
    <LoaderContainer id="loader" $visible={$showSpinner}>
      <div id="status" />
    </LoaderContainer>
  );
}

export default Spinner;
