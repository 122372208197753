/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ConfirmationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: #202020;
  color: white !important;
  text-align: center;
  z-index: 999;
  padding: 50px;

  @media screen and (max-width: 992px) {
    width: 30%;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 576px) {
    width: 70%;
  }
`;

const ConfirmationTitle = styled.h2`
  color: white !important;
`;

type ErrorProps = {
  errorMessage: string,
};

function Error({ errorMessage }: ErrorProps) {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          clearInterval(interval);
          window.location.href = '/restaurant';
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);
  return (
    <ConfirmationContainer>
      <ConfirmationTitle>
        {errorMessage}
        <br />
        <br />
        Redirection dans :
        {' '}
        {counter}
        {' '}
        s
      </ConfirmationTitle>
    </ConfirmationContainer>
  );
}

export default Error;
