import { useContext } from 'react';
import { FetchDataType } from '../../../typescript/types/FetchDataTypes';
import { RestaurantContext, ThemeContext } from '../../../typescript/types/ContextTypes';

// Hook permettant la requête simple de l'api, sans authentification
export const useRestaurantRequest = () => {
  const fetchRestaurantData: FetchDataType = async (url, options) => {
    // On récupére la réponse renvoyée par le serveur
    const response = await fetch(url, options);

    // On récupère les données associées à cette réponse
    const data = await response.json();

    if (response.ok) {
      return { data, response };
    }
    return { data: null, response };
  };

  return { fetchRestaurantData };
};

export const useRestaurant = () => {
  const context = useContext(RestaurantContext);

  if (!context) {
    throw new Error('useRestaurant must be used within a ApiProvider');
  }

  return context;
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ApiProvider');
  }

  return context;
};
