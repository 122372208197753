import { useEffect, useState } from 'react';
import GenericTable from '../../Generic/GenericTable';
import { useApi } from '../../../../utils/hooks/admin';
import { ColumnsType } from '../../../../typescript/type/ColumnsType';

function MenuTable() {
  // Je récupère la taille de l'écran
  const { windowSize } = useApi();

  const defaultColumns = {
    price: true,
    isSaled: true,
  };

  // State permettant de définir les informations des Users à afficher
  const [displayedColumns, setDisplayedColumns] = useState<ColumnsType>(defaultColumns);

  const mobileColumns = {
    price: false,
    isSaled: true,
  };

  // Je change les colonnes à afficher en fct de la taille de l'écran
  useEffect(() => {
    if (windowSize.width <= 768) {
      setDisplayedColumns(mobileColumns);
    } else {
      setDisplayedColumns(defaultColumns);
    }
  }, [windowSize]);

  return (
    <GenericTable
      name="menus"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
      associatedName="dishes"
    />
  );
}

export default MenuTable;
