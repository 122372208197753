import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import SpinnerWrapper from '../../SpinnerWrapper';
import { useApi } from '../../../../utils/hooks/admin';
import Searcher from '../../Searcher';
import {
  ingredientCategoryChoice,
  dishCategoryChoice,
  getEntityProperty,
  getModalTitle,
  getUnits,
  convertQuantity,
  weightUnits,
  volumeUnits,
} from '../../../../utils/helpers/FormPart/DishFormPart';
import { OrderDishType } from '../../../../typescript/datas/OrderDishTypes';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { IngredientType } from '../../../../typescript/datas/IngredientType';
import { DishCategory, DishType } from '../../../../typescript/datas/DishTypes';
import { SearchableDataTableType, SearchableDataTableTypeArr } from '../../../../typescript/datas/DataTablesType';
import { VolumeUnitsType, WeightUnitsType } from '../../../../typescript/type/UnitsType';

const UnitChoice = styled.div`
  display: flex;
  flex-direction: row;
`;

type DishFormPartProps = {
  isAddModal: boolean,
  associatedEntitiesIds: Partial<OrderDishType>[],
  setAssociatedEntitiesIds: React.Dispatch<
    React.SetStateAction<number[] | Partial<OrderDishType>[]>
  >,
  allAssociatedEntities: IngredientType[],
  selectedEntryColumns: Partial<DishType>,
  setSelectedEntryColumns: React.Dispatch<React.SetStateAction<Partial<DishType>>>,
  isLoading: boolean,
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleSubmit: HandleSubmitType,
  handleClose: () => void,
};

function DishFormPart({
  isAddModal, // Booléen permettant de déterminer le mode (ajout ou édition)
  associatedEntitiesIds, // Tableau d'objets Ingredients {id, quantityNeeded} du plat
  setAssociatedEntitiesIds, // Modifie les ingrédients du plat
  allAssociatedEntities, // Tableau de tous les objets Ingredient
  selectedEntryColumns, // Objet contenant les informations du plat
  setSelectedEntryColumns, // Modifie les informations du plat
  isLoading,
  handleImageChange,
  handleSubmit,
  handleClose,
}: DishFormPartProps) {
  const { errors, authPermissions } = useApi();

  // State contenant l'objet représentant l'ingrédient trouvé via l'input de recherche
  const [selectedIngredient, setSelectedIngredient] = useState<IngredientType>();

  // State contenant l'unité de mesure choisie
  const [selectedUnit, setSelectedUnit] = useState('');

  // State contenant la quantité entrée dans l'input
  const [quantityDisplayed, setQuantityDisplayed] = useState(1);

  // State contenant la quantité (potentiellement convertie) à ajouter pour l'ingrédient trouvé
  const [quantity, setQuantity] = useState(1);

  // State contenant le terme de la recherche de l'input de recherche
  const [searchTerm, setSearchTerm] = useState('');

  // Détermine en fonction des permissions si mes inputs doivent être disabled ou non
  const isInputDisabled = !authPermissions.includes('Mise à jour des plats');

  // Permet d'empêcher la validation du formulaire si aucun ingrédient n'a été spécifié
  const isAssociatedEntitiesEmpty = associatedEntitiesIds.length === 0;

  // Aide à définir le titre de la modale
  const modalTitle = getModalTitle(isAddModal, isInputDisabled);

  // Méthode permettant de modifier le state quantity (float uniquement acceptés)
  const handleQuantityChange = (
    e?: React.ChangeEvent<HTMLInputElement>,
    manualQuantity?: number,
  ) => {
    let newQuantity = manualQuantity ?? parseFloat(e?.target.value || '0');

    if (Number.isNaN(newQuantity)) {
      newQuantity = quantity;
    }

    setQuantityDisplayed(newQuantity);

    if (selectedIngredient
        && selectedIngredient.unit !== 'Pièce(s)'
        && selectedUnit !== selectedIngredient.unit
    ) {
      if (weightUnits.includes(selectedUnit)) {
        newQuantity = convertQuantity(
          newQuantity,
          selectedUnit as WeightUnitsType,
          selectedIngredient.unit as WeightUnitsType,
          'weight',
        );
      } else if (volumeUnits.includes(selectedUnit)) {
        newQuantity = convertQuantity(
          newQuantity,
          selectedUnit as VolumeUnitsType,
          selectedIngredient.unit as VolumeUnitsType,
          'volume',
        );
      }
    }
    // Si la value n'est pas un number je garde l'ancienne valeur du state sinon la nouvelle
    setQuantity(newQuantity);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleQuantityChange(e);
  };

  // Méthode ajoutant un ingrédient au plat ou augmentant sa quantité s'il existe déjà
  const handleAddIngredient = (
    ingredient: IngredientType,
  ) => {
    const existingIngredientIndex = associatedEntitiesIds.findIndex(
      (item) => item.id === ingredient.id,
    );
    // Si l'ingrédient existait déjà pour le plat
    if (existingIngredientIndex !== -1) {
      const updatedSelectedIngredients = [...associatedEntitiesIds];

      if (updatedSelectedIngredients[existingIngredientIndex].quantityNeeded) {
        updatedSelectedIngredients[existingIngredientIndex]!.quantityNeeded = Math.round(
          (updatedSelectedIngredients[existingIngredientIndex]!.quantityNeeded! + quantity)
           * 1000000,
        ) / 1000000;

        setAssociatedEntitiesIds(updatedSelectedIngredients);
      }
    } else {
      // Sinon j'ajoute l'ingrédient et sa quantité aux ingrédients
      setAssociatedEntitiesIds([
        ...associatedEntitiesIds,
        {
          id: ingredient.id,
          quantityNeeded: quantity,
        }]);
    }

    // Je reset mes states de recherche et de quantité
    setSelectedIngredient(undefined);
    setSearchTerm('');
    setQuantity(quantityDisplayed);
  };

  const handleAddRemovableIngredient = (
    e: React.ChangeEvent<HTMLInputElement>,
    ingredientId: number,
  ) => {
    const { checked } = e.target;
    const existingIngredientIndex = associatedEntitiesIds.findIndex(
      (item) => item.id === ingredientId,
    );

    const updatedSelectedIngredients = [...associatedEntitiesIds];

    updatedSelectedIngredients[existingIngredientIndex].isRemovable = checked;
    setAssociatedEntitiesIds(updatedSelectedIngredients);
  };

  // Méthode permettant de retirer un ingrédient au plat
  const handleRemoveIngredient = (
    ingredientId: number,
  ) => {
    const updatedSelectedIngredients = associatedEntitiesIds.filter(
      (ingredient) => ingredient.id !== ingredientId,
    );
    setAssociatedEntitiesIds(updatedSelectedIngredients);
  };

  useEffect(() => {
    if (selectedIngredient) {
      setSelectedUnit(selectedIngredient.unit);
    }
  }, [selectedIngredient]);

  useEffect(() => {
    if (selectedUnit !== '') {
      handleQuantityChange(undefined, quantityDisplayed);
    }
  }, [selectedUnit]);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {modalTitle}
            de plat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>
                Nom
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSelectedEntryColumns(
                  { ...selectedEntryColumns, title: e.target.value },
                )}
                value={selectedEntryColumns.title}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('duplicateTitle')
              && <p className="text-primary">Ce plat est déjà enregistré.</p>}
              {errors.includes('emptyTitle')
              && <p className="text-primary">Le nom du plat doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>
                Description
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Courte présentation du plat..."
                onChange={
                  (e) => setSelectedEntryColumns(
                    { ...selectedEntryColumns, description: e.target.value },
                  )
                }
                value={selectedEntryColumns.description}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyDescription') && (
              <p className="text-primary">
                La description du plat doit être spécifiée
              </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Prix (en €)
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                onChange={(e) => {
                  const { value } = e.target;
                  const price = parseFloat(value);
                  setSelectedEntryColumns((prevEntryColumns) => ({
                    ...prevEntryColumns,
                    price: Number.isNaN(price) ? prevEntryColumns.price : price,
                  }));
                }}
                value={selectedEntryColumns.price}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyPrice')
              && <p className="text-primary">Le prix doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="category">
              <Form.Label>
                Catégorie
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Select
                name="category"
                onChange={(event) => {
                  const { value } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    category: value as DishCategory,
                  }));
                }}
                value={selectedEntryColumns.category}
                required
                disabled={isInputDisabled}
              >
                <option value="" disabled>Sélectionner une catégorie</option>
                {dishCategoryChoice.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </Form.Select>
              {errors.includes('emptyCategory')
              && <p className="text-primary">Le plat doit appartenir à une catégorie</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Vendre ce plat ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={(event) => {
                  const { checked } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    isSaled: checked,
                  }));
                }}
                checked={selectedEntryColumns.isSaled}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Proposer au client de choisir la cuisson ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={(event) => {
                  const { checked } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    cookingChoice: checked,
                  }));
                }}
                checked={selectedEntryColumns.cookingChoice}
              />
            </Form.Group>

            <Searcher
              name="dishesPart"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allEntities={allAssociatedEntities}
              setPartEntities={setSelectedIngredient as React.Dispatch<React.SetStateAction<
                SearchableDataTableTypeArr | SearchableDataTableType | undefined>
              >}
              disabled={isInputDisabled}
            />

            {selectedIngredient && (
              <Form.Group className="mb-3">
                <UnitChoice>
                  <Form.Label style={{ marginRight: '24px', width: '20%' }}>
                    Quantité en
                  </Form.Label>

                  <Form.Select
                    name="chosentUnit"
                    onChange={(e) => {
                      const { value } = e.target;
                      setSelectedUnit(value);
                    }}
                    value={selectedUnit}
                    style={{ width: 'auto' }}
                  >
                    {
                      selectedIngredient.unit !== 'Pièce(s)'
                        ? getUnits(selectedIngredient.unit).map((unit) => (
                          <option key={unit} value={unit}>{unit}</option>
                        ))
                        : <option value={selectedIngredient.unit}>{selectedIngredient.unit}</option>
                    }
                  </Form.Select>
                </UnitChoice>

                <Form.Control
                  className="d-inline w-20 me-4"
                  type="number"
                  value={quantityDisplayed}
                  onChange={handleInputChange}
                  min={1}
                />
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleAddIngredient(selectedIngredient)}
                >
                  Ajouter
                </Button>
              </Form.Group>
            )}
            <div>
              {associatedEntitiesIds.length > 0
              && <p className="text-decoration-underline fw-bolder">Ingrédients du plat :</p>}
              {ingredientCategoryChoice
                .filter(
                  (ingredientCategory) => associatedEntitiesIds.some(
                    (ingredient) => getEntityProperty(
                      ingredient.id ?? 0,
                      'category',
                      allAssociatedEntities,
                    ) === ingredientCategory,
                  ),
                )
                .map((ingredientCategory, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <p className="fw-bolder">{ingredientCategory}</p>
                    <ul>
                      {associatedEntitiesIds
                        .filter(
                          (ingredient) => getEntityProperty(
                            ingredient.id ?? 0,
                            'category',
                            allAssociatedEntities,
                          ) === ingredientCategory,
                        )
                        .map((ingredient) => (
                          <li key={ingredient.id} className="mb-2">
                            {getEntityProperty(ingredient.id ?? 0, 'title', allAssociatedEntities)}
                            {' : '}
                            {ingredient.quantityNeeded}
                            {' '}
                            {getEntityProperty(ingredient.id ?? 0, 'unit', allAssociatedEntities)}
                            {!isInputDisabled
                            && (
                            <Button
                              className="ms-4"
                              variant="primary"
                              size="sm"
                              onClick={() => handleRemoveIngredient(ingredient.id ?? 0)}
                            >
                              Retirer
                            </Button>
                            )}
                            <Form.Group className="mb-3" controlId="isRemovable">
                              <Form.Label style={{ marginTop: '16px' }}>
                                Retirable par le client ?
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                name="isRemovable"
                                style={{ display: 'inline-block', marginLeft: '20px' }}
                                onChange={
                                  (e) => handleAddRemovableIngredient(e, ingredient.id ?? 0)
                                }
                                checked={
                                  associatedEntitiesIds.find(
                                    (item) => item.id === ingredient.id,
                                  )?.isRemovable || false
                                }
                              />
                            </Form.Group>
                          </li>
                        ))}
                    </ul>
                  </React.Fragment>
                ))}
            </div>

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image du plat (440 * 560)</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                disabled={isInputDisabled}
              />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              {!isInputDisabled ? (
                <>
                  <Button
                    variant="success"
                    size="sm"
                    className="me-4"
                    type="submit"
                    disabled={isAssociatedEntitiesEmpty}
                  >
                    Valider
                  </Button>

                  <Button variant="primary" size="sm" onClick={handleClose}>
                    Annuler
                  </Button>
                </>
              ) : (
                <Button variant="primary" size="sm" onClick={handleClose}>
                  Retour
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DishFormPart;
