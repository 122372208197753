import { useEffect, useState } from 'react';
import GenericTable from '../../Generic/GenericTable';
import { useApi } from '../../../../utils/hooks/admin';
import { ColumnsType } from '../../../../typescript/type/ColumnsType';

function StockTable() {
  // Je récupère la taille de l'écran
  const { windowSize } = useApi();

  const defaultColumns = {
    quantity: true,
    percentQuantity: true,
    category: true,
    isAllergen: false,
    alert: false,
    increaseMode: false,
    startingDay: false,
    increaseInterval: false,
    isAdded: false,
    quantityIncreased: false,
  };

  // State permettant de définir les informations des ingrédients à afficher
  const [displayedColumns, setDisplayedColumns] = useState<ColumnsType>(defaultColumns);

  const mobileColumns = {
    quantity: true,
    percentQuantity: false,
    category: false,
    isAllergen: false,
    alert: false,
    increaseMode: false,
    startingDay: false,
    increaseInterval: false,
    isAdded: false,
    quantityIncreased: false,
  };

  // Je change les colonnes à afficher en fct de la taille de l'écran
  useEffect(() => {
    if (windowSize.width <= 768) {
      setDisplayedColumns(mobileColumns);
    } else {
      setDisplayedColumns(defaultColumns);
    }
  }, [windowSize]);

  return (
    <GenericTable
      name="ingredients"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
    />
  );
}

export default StockTable;
