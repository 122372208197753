import { useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';
import { GalleryOptionsType } from '../../../../typescript/datas/GalleryOptionsType';
import { ImageMemberInfosState } from '../../../../typescript/datas/ImageTypes';
import { GalleryImageType, UpdatableGalleryImageField } from '../../../../typescript/datas/GalleryImageType';
import { HandleMemberFieldChange } from '../../../../typescript/functions/HandleMemberFieldChange';
import { generateUniqueId } from '../../../../utils/helpers/Generic/GenericOption';

type GalleryOptionsFormPartProps = {
  handleSubmit: HandleSubmitType,
  setImageMemberInfos: React.Dispatch<React.SetStateAction<ImageMemberInfosState>>,
  entries: GalleryOptionsType,
  setEntries: React.Dispatch<React.SetStateAction<GalleryOptionsType>>,
};

const GallerySection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const GalleryTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

const Generic = styled.div`
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 2px solid white;
`;

function GalleryOptionsFormPart({
  handleSubmit,
  setImageMemberInfos,
  entries,
  setEntries,
}: GalleryOptionsFormPartProps) {
  const [selectedGalleryImage, setSelectedGalleryImage] = useState<GalleryImageType>();

  // Méthode pour gérer le changement de galleryImage sélectionné
  const handleGalleryImageChange = (
    value: string,
  ) => {
    const selectedId = parseInt(value, 10);
    const selectedImage = entries.galleryImages?.find(
      (image) => image.id === selectedId,
    );
    setSelectedGalleryImage(selectedImage);
  };

  // Méthode pour changer la colonne d'une galleryImage
  const handleMemberFieldChange: HandleMemberFieldChange = (
    value,
    imageId,
    fieldName,
  ) => {
    // On crée une copie de entries
    const updatedEntries = { ...entries };
    // On trouve le membre correspondant à l'id passé
    const imageToUpdate = updatedEntries.galleryImages?.find((image) => image.id === imageId);
    // On met à jour la colonne passée
    if (imageToUpdate) {
      // En modifiant imageToUpdate[fieldName] je modifie updatedEntries.galleryImages[fieldName]
      imageToUpdate[fieldName as UpdatableGalleryImageField] = value;
    }
    // On met à jour le tout
    setEntries(updatedEntries);
  };

  // Méthode pour gérer le changement d'image pour un champ spécifique
  const handleImageMemberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    memberId: number,
  ) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      // Pour chaque image du formulaire, un objet lui est dédié et il est identifié par son field
      setImageMemberInfos((prevImageMemberInfos) => ({
        ...prevImageMemberInfos,
        [fieldName]: {
          imageId: generateUniqueId(),
          memberId,
          imageFile: file,
          imageName: file.name,
        },
      }));
    }
  };

  return (
    <GallerySection>
      <GalleryTitle>Section Gallery</GalleryTitle>
      <Form onSubmit={handleSubmit}>
        <Generic>
          <FormGroup className="mb-3" controlId="flexSwitchCheckChecked">
            <Form.Check
              type="switch"
              id="flexSwitchCheckChecked"
              label="Afficher cette section ?"
              checked={entries.isEnabled}
              onChange={(e) => setEntries({ ...entries, isEnabled: e.target.checked })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamTitle">Titre</FormLabel>
            <FormControl
              type="text"
              id="teamTitle"
              value={entries.title}
              onChange={(e) => setEntries({ ...entries, title: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamDescription">Description</FormLabel>
            <FormControl
              as="textarea"
              rows={5}
              id="teamDescription"
              value={entries.description}
              onChange={(e) => setEntries({ ...entries, description: e.target.value })}
            />
          </FormGroup>
        </Generic>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="teamMembers" className="mt-2">Images affichées</FormLabel>
          <Form.Select
            id="teamBackgroundImg"
            onChange={(e) => handleGalleryImageChange(e.target.value)}
            defaultValue=""
          >
            <option value="" disabled>Choisissez une image</option>
            {entries.galleryImages?.map((galleryImage, index) => (
              <option key={galleryImage.id} value={galleryImage.id}>{`Image ${index + 1}`}</option>
            ))}
          </Form.Select>
        </FormGroup>
        {selectedGalleryImage && (
          <>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="galleryImageDescription">Description</FormLabel>
              <FormControl
                type="text"
                id="galleryImageDescription"
                value={selectedGalleryImage.description}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedGalleryImage.id, 'description')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberImage">Image</FormLabel>
              <Form.Control
                type="file"
                id="memberImage"
                className="bg-dark"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageMemberChange(e, `imageName_${selectedGalleryImage.id}`, selectedGalleryImage.id)}
              />
            </FormGroup>
          </>
        )}
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </GallerySection>
  );
}

export default GalleryOptionsFormPart;
