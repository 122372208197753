import { Button, Modal } from 'react-bootstrap';

type OrderValidationModalProps = {
  showValidate: boolean,
  setShowValidate: React.Dispatch<React.SetStateAction<boolean>>,
  number: number,
  handleOrderArchive: () => Promise<void>,
  handleOrderDelete: () => Promise<void>,
};

function OrderValidationModal({
  showValidate,
  setShowValidate,
  number,
  handleOrderArchive,
  handleOrderDelete,
}: OrderValidationModalProps) {
  return (
    <Modal show onHide={() => setShowValidate(!showValidate)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          Confirmation de paiement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p style={{ margin: '0px' }}>
          Confirmer le paiement de la table
          {' : '}
          {number}
          {' '}
          <br />
          ET
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center w-100">
          <Button variant="success" size="sm" className="me-4" onClick={handleOrderArchive}>
            L&apos;archiver
          </Button>
          <Button variant="warning" size="sm" className="me-4" onClick={handleOrderDelete}>
            La supprimer
          </Button>
          <Button variant="primary" size="sm" onClick={() => setShowValidate(!showValidate)}>
            Annuler
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default OrderValidationModal;
