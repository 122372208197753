/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { MenuOptionsType } from '../../../typescript/datas/MenuOptionsType';
import { DishType } from '../../../typescript/datas/DishTypes';
import { MenuType } from '../../../typescript/datas/MenuType';
import { ProductDetailsType } from '../../../typescript/type/ProductDetailsType';
import { DishAndMenuType } from '../../../typescript/datas/DishAndMenuType';

const SliderNav = styled.div`
  display: flex;
  flex-direction: row;
`;

const SliderTitle = styled.div`
  flex: 1;
`;

const DishImg = styled.img`
  position: relative;
  top: 10px;

  @media screen and (max-width: 768px) {
    top: -5px;
    width: 35px;
  }
`;

const OfferItems = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-start;
  background-color: white;
`;

const OfferGroup = styled.div<{ $currentIndex: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  background-color: #f5f5f5;
  transition: transform 2s ease-in-out;
  transform: translateX(${(props) => props.$currentIndex * -100}%);
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.05);
`;

const OfferItem = styled.div`
  width: 50%;
  padding: 0 15px 0 15px;
  position: relative;
  cursor: pointer;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

type MenuCarouselProps = {
  menuOptions: MenuOptionsType,
  dishesSaled: DishType[],
  menusSaled: MenuType[],
  tableNumber: number,
  setProductDetails: React.Dispatch<React.SetStateAction<ProductDetailsType>>,
};

function MenuCarousel({
  menuOptions,
  dishesSaled,
  menusSaled,
  tableNumber,
  setProductDetails,
}: MenuCarouselProps) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleGroupChange = (
    index: number,
  ) => {
    setCurrentIndex(index);
  };

  // Permet d'afficher la modale présentant un produit
  const handleProductDetails = (
    productObject: DishAndMenuType,
    productType: string,
  ) => {
    setProductDetails({
      display: true,
      product: productObject,
      type: productType,
    });
  };

  return (
    <div id="menu" className="menu-main pad-top-100 pad-bottom-100">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
              <h2 className="block-title text-center">{menuOptions.title}</h2>
              <p className="title-caption text-center">{menuOptions.description}</p>
            </div>
            <div className="tab-menu">
              <SliderNav className="slider slider-nav">
                <SliderTitle className={`tab-title-menu ${currentIndex === 0 ? 'is-active' : ''}`} onClick={() => handleGroupChange(0)}>
                  <h2>MENUS</h2>
                  <p>
                    <i className="flaticon-dinner" />
                  </p>
                </SliderTitle>
                <SliderTitle className={`tab-title-menu ${currentIndex === 1 ? 'is-active' : ''}`} onClick={() => handleGroupChange(1)}>
                  <h2>ENTRÉES</h2>
                  <p>
                    <i className="flaticon-canape" />
                  </p>
                </SliderTitle>
                <SliderTitle className={`tab-title-menu ${currentIndex === 2 ? 'is-active' : ''}`} onClick={() => handleGroupChange(2)}>
                  <h2>PLATS</h2>
                  <p>
                    <DishImg src={`${apiUrl}/RestaurantTheme/images/food.png`} alt="main-course" />
                  </p>
                </SliderTitle>
                <SliderTitle className={`tab-title-menu ${currentIndex === 3 ? 'is-active' : ''}`} onClick={() => handleGroupChange(3)}>
                  <h2>DESSERTS</h2>
                  <p>
                    <i className="flaticon-desert" />
                  </p>
                </SliderTitle>
              </SliderNav>
              <div className="slider slider-single">
                <OfferItems>
                  <OfferGroup $currentIndex={currentIndex}>
                    {menusSaled.map((menuSaled) => (
                      <OfferItem
                        key={menuSaled.id}
                        className="offer-item"
                        onClick={() => {
                          if (tableNumber !== 0) {
                            handleProductDetails(menuSaled, 'menu');
                          }
                        }}
                      >
                        <img
                          src={`${apiUrl}/images/menus/${menuSaled.imageName}`}
                          alt=""
                          className="img-responsive"
                        />
                        <div>
                          <h3>{menuSaled.title}</h3>
                          <p>{menuSaled.description}</p>
                        </div>
                        <span className="offer-price">
                          {menuSaled.price}
                          €
                        </span>
                      </OfferItem>
                    ))}
                  </OfferGroup>
                  <OfferGroup $currentIndex={currentIndex}>
                    {dishesSaled.filter((dishSaled) => dishSaled.category === 'Entrée')
                      .map((dishSaled) => (
                        <OfferItem
                          key={dishSaled.id}
                          className="offer-item"
                          onClick={() => {
                            if (tableNumber !== 0) {
                              handleProductDetails(dishSaled, 'dish');
                            }
                          }}
                        >
                          <img src={`${apiUrl}/images/dishes/${dishSaled.imageName}`} alt="" className="img-responsive" />
                          <div>
                            <h3>{dishSaled.title}</h3>
                            <p>{dishSaled.description}</p>
                          </div>
                          <span className="offer-price">
                            {dishSaled.price}
                            €
                          </span>
                        </OfferItem>
                      ))}
                  </OfferGroup>
                  <OfferGroup $currentIndex={currentIndex}>
                    {dishesSaled.filter((dishSaled) => dishSaled.category === 'Plat principal')
                      .map((dishSaled) => (
                        <OfferItem
                          key={dishSaled.id}
                          className="offer-item"
                          onClick={() => {
                            if (tableNumber !== 0) {
                              handleProductDetails(dishSaled, 'dish');
                            }
                          }}
                        >
                          <img src={`${apiUrl}/images/dishes/${dishSaled.imageName}`} alt="" className="img-responsive" />
                          <div>
                            <h3>{dishSaled.title}</h3>
                            <p>{dishSaled.description}</p>
                          </div>
                          <span className="offer-price">
                            {dishSaled.price}
                            €
                          </span>
                        </OfferItem>
                      ))}
                  </OfferGroup>
                  <OfferGroup $currentIndex={currentIndex}>
                    {dishesSaled.filter((dishSaled) => dishSaled.category === 'Dessert')
                      .map((dishSaled) => (
                        <OfferItem
                          key={dishSaled.id}
                          className="offer-item"
                          onClick={() => {
                            if (tableNumber !== 0) {
                              handleProductDetails(dishSaled, 'dish');
                            }
                          }}
                        >
                          <img src={`${apiUrl}/images/dishes/${dishSaled.imageName}`} alt="" className="img-responsive" />
                          <div>
                            <h3>{dishSaled.title}</h3>
                            <p>{dishSaled.description}</p>
                          </div>
                          <span className="offer-price">
                            {dishSaled.price}
                            €
                          </span>
                        </OfferItem>
                      ))}
                  </OfferGroup>
                </OfferItems>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MenuCarousel;
