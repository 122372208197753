/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useParams } from 'react-router-dom';
import Header from '../../composants/Client/Header';
import ColorPanel from '../../composants/Client/ColorPanel';
import MenuCarousel from '../../composants/Client/MenuCarousel';
import Spinner from '../../composants/Client/Spinner';
import Presentation from '../../composants/Client/Presentation';
import AboutUs from '../../composants/Client/AboutUs';
import Special from '../../composants/Client/Special';
import Team from '../../composants/Client/Team';
import Footer from '../../composants/Client/Footer';
import CartMin from '../../composants/Client/CartMin';
import OrderDetails from '../../composants/Client/OrderDetails';
import DishDetails from '../../composants/Client/DishDetails';
import MenuDetails from '../../composants/Client/MenuDetails';
import Confirmation from '../../composants/Client/Confirmation';
import Error from '../../composants/Client/Error';
import timeToMinutes from '../../utils/helpers/Client/restaurant';
import ToAdmin from '../../composants/Client/ToAdmin';
import { PresentationOptionsType } from '../../typescript/datas/PresentationOptionsType';
import { useRestaurantRequest } from '../../utils/hooks/client';
import { isHydraMember } from '../../utils/helpers/Datatype';
import { AboutUsOptionsType } from '../../typescript/datas/AboutUsOptionsType';
import { SpecialOptionsType } from '../../typescript/datas/SpecialOptionsType';
import { MenuOptionsType } from '../../typescript/datas/MenuOptionsType';
import { TeamOptionsType } from '../../typescript/datas/TeamOptionsType';
import { GalleryOptionsType } from '../../typescript/datas/GalleryOptionsType';
import { FooterOptionsType } from '../../typescript/datas/FooterOptionsType';
import { OpeningHoursType } from '../../typescript/datas/OpeningHoursType';
import Parallax from '../../composants/Client/Parallax';
import { DishAndMenuType } from '../../typescript/datas/DishAndMenuType';
import { DishType } from '../../typescript/datas/DishTypes';
import { MenuType } from '../../typescript/datas/MenuType';
import { ProductDetailsType } from '../../typescript/type/ProductDetailsType';
import Gallery from '../../composants/Client/Gallery';
import { RestaurantOrderType } from '../../typescript/datas/OrderTypes';
import { ConfirmationType } from '../../typescript/type/ConfirmationType';
import { TableType } from '../../typescript/datas/TableType';

function Restaurant() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { fetchRestaurantData } = useRestaurantRequest();
  const [isLoading, setIsLoading] = useState(true);

  const [presentationOptions, setPresentationOptions] = useState<PresentationOptionsType>();
  const [aboutUsOptions, setAboutUsOptions] = useState<AboutUsOptionsType>();
  const [specialOptions, setSpecialOptions] = useState<SpecialOptionsType>();
  const [carouselDatas, setCarouselDatas] = useState<DishAndMenuType[]>([]);
  const [menuOptions, setMenuOptions] = useState<MenuOptionsType>();
  const [menusSaled, setMenusSaled] = useState<MenuType[]>([]);
  const [dishesSaled, setDishesSaled] = useState<DishType[]>([]);
  const [teamOptions, setTeamOptions] = useState<TeamOptionsType>();
  const [galleryOptions, setGalleryOptions] = useState<GalleryOptionsType>();
  const [footerOptions, setFooterOptions] = useState<FooterOptionsType>();

  /*
   Permet de gérer les erreurs d'identifiant de table incorrect, de table
   déjà occupée et de commandes passées en dehors des horaires d'ouverture
  */
  const [errorMessage, setErrorMessage] = useState('');

  // Contient l'identifiant de la table permettant d'en déduire son numéro
  const { tableKey } = useParams();

  // Contient le numéro de la table
  const [tableNumber, setTableNumber] = useState(-1);

  // Permet de gérer la modale affichant les informations d'un plat / menu
  const [productDetails, setProductDetails] = useState<ProductDetailsType>({
    display: false,
    id: 0,
    product: {
    },
    type: '',
  });

  // Contient les informations des produits actuellement dans le panier
  const [orders, setOrders] = useState<RestaurantOrderType[]>([]);

  // Permet de gérer l'ouverture de la modale des détails du panier
  const [ordersDetails, setOrderDetails] = useState(false);

  // Permet de gérer l'ouverture de la modale de confirmation de commande
  const [confirmation, setConfirmation] = useState<ConfirmationType>({
    display: false,
    totalPrice: 0,
  });

  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    if (!isLoading && tableKey) {
      const fetchDataAsync = async () => {
        const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

        const isCurrentTimeInInterval = (
          openingHours: OpeningHoursType[],
        ) => {
          const now = new Date();
          // Pour les anglais le 1er jour de la semaine est dimanche
          let currentDayIndex = now.getDay() - 1;

          // Si dimanche
          if (currentDayIndex === -1) {
            // On replace correctement l'index
            currentDayIndex = 6;
          }
          const currentDay = daysOfWeek[currentDayIndex];

          // Mieux vaut comparer des nombres plutôt que des string
          const currentTime = timeToMinutes(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`);
          return openingHours.some(({ days, hours }) => {
            const [startDay, endDay] = days.split(/\s*-\s*/);
            const [startHour, endHour] = hours.split(/\s*-\s*/);

            const startDayIndex = daysOfWeek.indexOf(startDay);
            const endDayIndex = daysOfWeek.indexOf(endDay);
            const realCurrentDayIndex = daysOfWeek.indexOf(currentDay);

            // Vérifie si le jour actuel est compris dans les horaires d'ouverture
            const isDayInRange = (
              realCurrentDayIndex >= startDayIndex && realCurrentDayIndex <= endDayIndex
            );

            const startTime = timeToMinutes(startHour);
            const endTime = timeToMinutes(endHour);

            // Vérifie si l'heure actuelle est comprise dans les horaires d'ouverture
            const isTimeInRange = currentTime >= startTime && currentTime <= endTime;

            return isDayInRange && isTimeInRange;
          });
        };

        const options = {
          method: 'GET',
        };

        const { data: openingHoursData } = await fetchRestaurantData<OpeningHoursType>(`${apiUrl}/api/opening_hours`, options);

        let foundDate;

        if (openingHoursData && isHydraMember<OpeningHoursType>(openingHoursData)) {
          foundDate = isCurrentTimeInInterval(openingHoursData['hydra:member']);
        }

        if (!foundDate) {
          setErrorMessage('Le restaurant est fermé. Veuillez essayer plus tard');
        } else {
          const { data: orderIdentifierData } = await fetchRestaurantData<TableType>(`${apiUrl}/api/tablesIdentifiers`, options);

          if (orderIdentifierData && isHydraMember(orderIdentifierData)) {
            const foundIdentifier = orderIdentifierData['hydra:member'].find((obj) => obj.urlIdentifier === tableKey);

            if (foundIdentifier) {
              if (foundIdentifier.isOccupied) {
                setErrorMessage('La table est déjà occupée');
              } else {
                setTableNumber(foundIdentifier.number);
              }
            } else {
              setErrorMessage('Aucune table associée à ce QR Code');
            }
          }
        }
      };

      fetchDataAsync();
    }
  }, [isLoading, tableKey]);

  useEffect(() => {
    if (errorMessage === '' && tableNumber > -1) {
      const scrollToAnchor = () => {
        const menuElem = document.getElementById('menu');
        if (menuElem) {
          const toY = menuElem.getBoundingClientRect().top + window.scrollY - 70;
          scroll.scrollTo(toY, {
            duration: 500,
            smooth: 'easeInOutQuart',
          });
        }
      };

      // On scroll vers l'ancre menu
      scrollToAnchor();
    }
  }, [tableNumber]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const options = {
        method: 'GET',
      };

      const { data: presentationOptionsData } = await fetchRestaurantData<PresentationOptionsType>(`${apiUrl}/api/presentation_options`, options);

      if (presentationOptionsData
          && isHydraMember<PresentationOptionsType>(presentationOptionsData)
      ) {
        setPresentationOptions(presentationOptionsData['hydra:member'][0]);
      }

      const { data: aboutUsOptionsData } = await fetchRestaurantData<AboutUsOptionsType>(`${apiUrl}/api/about_us_options`, options);

      if (aboutUsOptionsData && isHydraMember<AboutUsOptionsType>(aboutUsOptionsData)) {
        setAboutUsOptions(aboutUsOptionsData['hydra:member'][0]);
      }

      const { data: specialOptionsData } = await fetchRestaurantData<SpecialOptionsType>(`${apiUrl}/api/special_options`, options);

      if (specialOptionsData && isHydraMember<SpecialOptionsType>(specialOptionsData)) {
        setSpecialOptions(specialOptionsData['hydra:member'][0]);
      }

      const { data: dishesCarouselData } = await fetchRestaurantData<DishType>(`${apiUrl}/api/dishes?exists[carouselIndex]=true`, options);

      const { data: menusCarouselData } = await fetchRestaurantData<MenuType>(`${apiUrl}/api/menus?exists[carouselIndex]=true`, options);

      if (dishesCarouselData
          && menusCarouselData
          && isHydraMember<DishType>(dishesCarouselData)
          && isHydraMember<MenuType>(menusCarouselData)
      ) {
        setCarouselDatas([...dishesCarouselData['hydra:member'], ...menusCarouselData['hydra:member']].sort((a, b) => (a.carouselIndex ?? 0) - (b.carouselIndex ?? 0)));
      }

      const { data: menuOptionData } = await fetchRestaurantData<MenuOptionsType>(`${apiUrl}/api/menu_options`, options);

      if (menuOptionData && isHydraMember<MenuOptionsType>(menuOptionData)) {
        setMenuOptions(menuOptionData['hydra:member'][0]);
      }

      const { data: menusSaledData } = await fetchRestaurantData<MenuType>(`${apiUrl}/api/menus?isSaled=true`, options);

      if (menusSaledData && isHydraMember<MenuType>(menusSaledData)) {
        setMenusSaled(menusSaledData['hydra:member']);
      }

      const { data: dishesSaledData } = await fetchRestaurantData<DishType>(`${apiUrl}/api/dishes?isSaled=true`, options);

      if (dishesSaledData && isHydraMember<DishType>(dishesSaledData)) {
        setDishesSaled(dishesSaledData['hydra:member']);
      }

      const { data: teamOptionsData } = await fetchRestaurantData<TeamOptionsType>(`${apiUrl}/api/team_options`, options);

      if (teamOptionsData && isHydraMember<TeamOptionsType>(teamOptionsData)) {
        setTeamOptions(teamOptionsData['hydra:member'][0]);
      }

      const { data: galleryOptionsData } = await fetchRestaurantData<GalleryOptionsType>(`${apiUrl}/api/gallery_options`, options);

      if (galleryOptionsData && isHydraMember<GalleryOptionsType>(galleryOptionsData)) {
        setGalleryOptions(galleryOptionsData['hydra:member'][0]);
      }

      const { data: footerOptionsData } = await fetchRestaurantData<FooterOptionsType>(`${apiUrl}/api/footer_options`, options);

      if (footerOptionsData && isHydraMember<FooterOptionsType>(footerOptionsData)) {
        setFooterOptions(footerOptionsData['hydra:member'][0]);
      }

      setIsLoading(false);
    };

    fetchDataAsync();
  }, []);

  // Permet d'empêcher le défilement de la page quand une modale est ouverte
  useEffect(() => {
    if (ordersDetails || confirmation.display || productDetails.display || errorMessage) {
      // On cible l'élément html
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  }, [ordersDetails, productDetails, errorMessage]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Spinner $showSpinner={isLoading} />

      {authToken && (
        <ToAdmin />
      )}

      {/* Modale d'erreurs */}
      {errorMessage !== '' && (
        <Error
          errorMessage={errorMessage}
        />
      )}

      {/* Modale du panier simplifié */}
      {tableNumber !== -1 && !ordersDetails && !productDetails.display && (
        <CartMin
          orders={orders}
          setOrderDetails={setOrderDetails}
          setConfirmation={setConfirmation}
        />
      )}

      {/* Modale du panier détaillé */}
      {ordersDetails && (
        <OrderDetails
          orders={orders}
          setOrders={setOrders}
          setOrderDetails={setOrderDetails}
          confirmation={confirmation}
          setConfirmation={setConfirmation}
        />
      )}

      {/* Modale de description de plats */}
      {productDetails && productDetails.display && productDetails.type === 'dish' && (
        <DishDetails
          dish={productDetails.product as DishType}
          orders={orders}
          setOrders={setOrders}
          setDishDetails={setProductDetails}
          tableNumber={tableNumber}
        />
      )}

      {/* Modale de description de menu */}
      {productDetails && productDetails.display && productDetails.type === 'menu' && (
        <MenuDetails
          menu={productDetails.product as MenuType}
          orders={orders}
          setOrders={setOrders}
          setMenuDetails={setProductDetails}
          tableNumber={tableNumber}
        />
      )}

      {/* Modale de confirmation de commande */}
      {confirmation.display && (
        <Confirmation confirmation={confirmation} orders={orders} tableNumber={tableNumber} />
      )}

      {presentationOptions && Object.keys(presentationOptions).length > 0 && (
        <>
          <Header logo={presentationOptions.logo} />
          <Parallax backgroundImg={presentationOptions.backgroundImg} section="presentation">
            <Presentation presentationOptions={presentationOptions} />
          </Parallax>
        </>
      )}

      {aboutUsOptions && aboutUsOptions.isEnabled && (
        <AboutUs aboutUsOptions={aboutUsOptions} />
      )}

      {specialOptions && specialOptions.isEnabled && (
        <Parallax backgroundImg={specialOptions.backgroundImg} section="special">
          <Special specialOptions={specialOptions} carouselDatas={carouselDatas} />
        </Parallax>
      )}

      {(menusSaled.length > 0 || dishesSaled.length > 0) && menuOptions && (
        <MenuCarousel
          menuOptions={menuOptions}
          dishesSaled={dishesSaled}
          menusSaled={menusSaled}
          tableNumber={tableNumber}
          setProductDetails={setProductDetails}
        />
      )}

      {teamOptions && teamOptions.isEnabled && (
        <Parallax backgroundImg={teamOptions.backgroundImg} section="team">
          <Team teamOptions={teamOptions} />
        </Parallax>
      )}

      {galleryOptions && galleryOptions.isEnabled && (
        <Gallery galleryOptions={galleryOptions} />
      )}

      {footerOptions && presentationOptions && Object.keys(presentationOptions).length > 0 && (
        <Footer logo={presentationOptions.logo} footerOptions={footerOptions} />
      )}

      <button type="button" className="btn btn-link scrollup" onClick={scrollToTop} style={{ display: 'inline' }}>Scroll</button>

      <ColorPanel />
    </>
  );
}
export default Restaurant;
