import {
  faAngleDown,
  faBox,
  faCalendarAlt,
  faChartSimple,
  faCog,
  faImage,
  faKey,
  faPaintBrush,
  faReceipt,
  faTable,
  faUserEdit,
  faUserGear,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useApi } from '../../../../utils/hooks/admin/index';

type SidebarProps = {
  handleOptionClick: (option: string) => void,
}

function Sidebar({ handleOptionClick }: SidebarProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    authPermissions,
    authUser,
    authImg,
    authRoles,
  } = useApi();

  // State permettant de gérer l'état de la liste déroulante des plats
  const [isDishDropdownOpen, setIsDishDropdownOpen] = useState(false);

  // State permettant de gérer l'état de la liste déroulante des commandes
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);

  // State contenant le nom du menu sélectionné
  const [activeOption, setActiveOption] = useState('');

  // State contenant le nom du sous-menu sélectionné des plats
  const [dishActiveSubOption, setDishActiveSubOption] = useState('');

  // State contenant le nom du sous-menu sélectionné des commandes
  const [orderActiveSubOption, setOrderActiveSubOptions] = useState('');

  /*
    React-bootstrap est très étrange. Appliquer un style sur une classe ou sur un id d'un
    même composant ne donnera pas du tout le même résultat dans certains cas (ici DropDown)

    State permettant de gérer le style de mon dropdown
  */
  const [dishDropdownClass, setDishDropDownClass] = useState('basic-nav-dropdown');

  const [orderDropdownClass, setOrderDropdownClass] = useState('basic-nav-dropdown');

  // Contient le nom des menus et sous-menus de mon dropdown des plats
  const dishDropDownOptions = ['Liste des menus', 'Liste des plats'];

  // Contient le nom des menus et sous-menus de mon dropdown des commandes
  const orderDropDownOptions = ['Préparation', 'Service', 'Paiement', 'Liste commandes'];

  // Méthode déclenchée au clic sur la liste déroulante et inversant son état (plats)
  const handleDishDropdownClick = () => {
    setIsDishDropdownOpen(!isDishDropdownOpen);
    setIsOrderDropdownOpen(false);
    setDishDropDownClass('basic-nav-dropdown-active');
    setOrderDropdownClass('basic-nav-dropdown');
  };

  // Méthode déclenchée au clic sur la liste déroulante et inversant son état (commandes)
  const handleOrderDropdownClick = () => {
    setIsOrderDropdownOpen(!isOrderDropdownOpen);
    setIsDishDropdownOpen(false);
    setOrderDropdownClass('basic-nav-dropdown-active');
    setDishDropDownClass('basic-nav-dropdown');
  };

  // Méthode déclenchée au clic sur un des éléments du menu et rendant le composant associé
  const onOptionClick = (
    option: string,
  ) => {
    // Si on a cliqué sur un menu sans rapport avec le dropdown
    if (!dishDropDownOptions.includes(option) && !orderDropDownOptions.includes(option)) {
      setOrderDropdownClass('basic-nav-dropdown');
      setDishDropDownClass('basic-nav-dropdown');
      setIsDishDropdownOpen(false);
      setIsOrderDropdownOpen(false);
    }

    // Si on a cliqué sur un des sous-menus de mon dropdown
    if (option === 'Liste des menus'
    || option === 'Liste des plats') {
      setDishActiveSubOption(option);
    } else if (option === 'Préparation'
    || option === 'Service'
    || option === 'Paiement'
    || option === 'Liste commandes') {
      setOrderActiveSubOptions(option);
    } else {
      setActiveOption(option);
      setDishActiveSubOption('');
      setOrderActiveSubOptions('');
    }
    // Si on a cliqué sur autre chose que le menu Gestion des plats
    if (option !== 'Gestion plats' && option !== 'Commandes') {
      // On rend le composant associé à ce menu
      handleOptionClick(option);
    }
  };

  return (
    <div className="sidebar pe-4 pb-3">
      <Navbar bg="secondary" variant="dark" expand={false}>
        <Navbar.Brand href="index.html" className="navbar-brand mx-4 mb-3">
          <h3 className="text-primary">
            <FontAwesomeIcon icon={faUserEdit} className="me-2" />
            TP Gourmand
          </h3>
        </Navbar.Brand>
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative">
            {authImg && (
              <img className="rounded-circle" src={`${apiUrl}/images/users/${authImg}`} alt="" style={{ width: '40px', height: '40px' }} />
            )}
            <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1" />
          </div>
          <div className="ms-3">
            <h6 className="mb-0">{authUser}</h6>
            {authRoles.map((authRole, index) => [
              <span key={authRole}>{authRole}</span>,
              index !== authRoles.length - 1 && <br key={`br-${authRole}`} />,
            ])}
          </div>
        </div>
        <Nav className="w-100">
          {authPermissions.includes('Accès à la liste des employés') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Gestion des utilisateurs' ? 'active' : ''}`} onClick={() => onOptionClick('Gestion des utilisateurs')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faUserGear} />
              </i>
              Gestion employés
            </Nav.Link>
          )}
          {authPermissions.includes('Accès à la liste des rôles et permissions associées') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Gestion des roles' ? 'active' : ''}`} onClick={() => onOptionClick('Gestion des roles')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faKey} />
              </i>
              Gestion roles
            </Nav.Link>
          )}
          {authPermissions.includes('Accès à la liste des ingrédients') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Gestion des stocks' ? 'active' : ''}`} onClick={() => onOptionClick('Gestion des stocks')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faBox} />
              </i>
              Gestion stocks
            </Nav.Link>
          )}
          {(authPermissions.includes('Accès à la liste des plats')
          || authPermissions.includes('Accès à la liste des menus')) && (
            <NavDropdown
              title={(
                <span>
                  <i className={`me-2 ${activeOption === 'Gestion plats' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUtensils} />
                  </i>
                  Gestion plats
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    size="sm"
                    className={`sideIcon-after-dropdown ${isDishDropdownOpen ? 'rotate180' : ''}`}
                  />
                </span>
              )}
              show={isDishDropdownOpen}
              id={dishDropdownClass}
              menuVariant="transparent"
              className="position-relative"
              onClick={() => {
                onOptionClick('Gestion plats');
                handleDishDropdownClick();
              }}
            >
              {authPermissions.includes('Accès à la liste des menus') && (
                <NavDropdown.Item
                  className={`${dishActiveSubOption === 'Liste des menus' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Liste des menus');
                  }}
                >
                  Liste des menus
                </NavDropdown.Item>
              )}
              {authPermissions.includes('Accès à la liste des plats') && (
                <NavDropdown.Item
                  className={`${dishActiveSubOption === 'Liste des plats' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Liste des plats');
                  }}
                >
                  Liste des plats
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {(authPermissions.includes('Accès à la préparation de commandes')
          || authPermissions.includes('Accès à la prise de paiement de commandes')) && (
            <NavDropdown
              title={(
                <span>
                  <i className={`me-2 ${activeOption === 'Commandes' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faReceipt} />
                  </i>
                  Commandes
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    size="sm"
                    className={`sideIcon-after-dropdown ${isOrderDropdownOpen ? 'rotate180' : ''}`}
                  />
                </span>
              )}
              show={isOrderDropdownOpen}
              id={orderDropdownClass}
              menuVariant="transparent"
              className="position-relative"
              onClick={() => {
                onOptionClick('Commandes');
                handleOrderDropdownClick();
              }}
            >
              {authPermissions.includes('Accès à la préparation de commandes') && (
                <NavDropdown.Item
                  className={`${orderActiveSubOption === 'Préparation' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Préparation');
                  }}
                >
                  Préparation
                </NavDropdown.Item>
              )}
              {authPermissions.includes('Accès à l\'envoi de commandes') && (
                <NavDropdown.Item
                  className={`${orderActiveSubOption === 'Service' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Service');
                  }}
                >
                  Service
                </NavDropdown.Item>
              )}
              {authPermissions.includes('Accès à la prise de paiement de commandes') && (
                <NavDropdown.Item
                  className={`${orderActiveSubOption === 'Paiement' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Paiement');
                  }}
                >
                  Paiement
                </NavDropdown.Item>
              )}
              {authPermissions.includes('Accès à la prise de paiement de commandes') && (
                <NavDropdown.Item
                  className={`${orderActiveSubOption === 'Liste commandes' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionClick('Liste commandes');
                  }}
                >
                  Archives
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {authPermissions.includes('Accès aux informations des tables') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Informations des tables' ? 'active' : ''}`} onClick={() => onOptionClick('Informations des tables')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faTable} />
              </i>
              Gestion tables
            </Nav.Link>
          )}
          {authPermissions.includes('Accès à la personnalisation') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Personnalisation' ? 'active' : ''}`} onClick={() => onOptionClick('Personnalisation')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faPaintBrush} />
              </i>
              Gestion thème
            </Nav.Link>
          )}
          {authPermissions.includes('Accès aux options générales') && (
            <Nav.Link className={`nav-item nav-link ${activeOption === 'Options générales' ? 'active' : ''}`} onClick={() => onOptionClick('Options générales')}>
              <i className="me-2">
                <FontAwesomeIcon icon={faCog} />
              </i>
              Options générales
            </Nav.Link>
          )}
          <Nav.Link className={`nav-item nav-link ${activeOption === 'Page client' ? 'active' : ''}`} onClick={() => onOptionClick('Page client')}>
            <i className="me-2">
              <FontAwesomeIcon icon={faImage} />
            </i>
            Page client
          </Nav.Link>
          <Nav.Link className={`nav-item nav-link ${activeOption === 'Graphiques' ? 'active' : ''}`} onClick={() => onOptionClick('Graphiques')}>
            <i className="me-2">
              <FontAwesomeIcon icon={faChartSimple} />
            </i>
            Graphiques
          </Nav.Link>
          <Nav.Link className={`nav-item nav-link ${activeOption === 'Planning' ? 'active' : ''}`} onClick={() => onOptionClick('Planning')}>
            <i className="me-2">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </i>
            Planning
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
}

export default Sidebar;
