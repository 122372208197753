import { DataTablesType } from '../../../typescript/datas/DataTablesType';
import { isIngredient } from '../Datatype';

// Méthode renvoyant une partie du corps de la requête
const getBodyData = (
  name: string,
  selectedEntryColumns: Partial<DataTablesType>,
): Partial<DataTablesType> => {
  switch (name) {
    case 'ingredients':
      if (isIngredient(selectedEntryColumns)) {
        return {
          title: selectedEntryColumns.title,
          quantity: selectedEntryColumns.quantity,
          maxQuantity: selectedEntryColumns.maxQuantity,
          unit: selectedEntryColumns.unit,
          category: selectedEntryColumns.category,
          isAllergen: selectedEntryColumns.isAllergen,
          alert: selectedEntryColumns.alert,
          increaseMode: selectedEntryColumns.increaseMode,
          increaseInterval: selectedEntryColumns.increaseInterval,
          isAdded: selectedEntryColumns.isAdded,
          quantityIncreased: selectedEntryColumns.quantityIncreased,
          startingDay: (
            selectedEntryColumns.startingDay === '' || selectedEntryColumns.startingDay === 'NaN-NaN-NaN'
          ) ? undefined : selectedEntryColumns.startingDay,
        };
      }
      return {};
    default:
      return {};
  }
};

export default getBodyData;
