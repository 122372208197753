// Définit les importations des fichiers CSS spécifiques à chaque thème
const themeImports = {
  login: {
    main: () => [
      '/DarkPanTheme/bootstrap.min.css',
      '/DarkPanTheme/templateStyle.css',
      '/DarkPanTheme/font.css',
    ],
  },
  admin: {
    main: () => [
      '/DarkPanTheme/bootstrap.min.css',
      '/DarkPanTheme/templateStyle.css',
      '/DarkPanTheme/font.css',
    ],
  },
  restaurant: {
    main: () => [
      '/RestaurantTheme/css/bootstrap.min.css',
      '/RestaurantTheme/css/style.css',
      '/RestaurantTheme/css/responsive.css',
      '/RestaurantTheme/css/colors/orange.css',
    ],
  },
};

export default themeImports;
