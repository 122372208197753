import { DataTablesType } from '../../../typescript/datas/DataTablesType';
import { DishType } from '../../../typescript/datas/DishTypes';
import { MenuType } from '../../../typescript/datas/MenuType';
import { OrderDishType } from '../../../typescript/datas/OrderDishTypes';
import { UserType } from '../../../typescript/datas/UserType';
import {
  isDish,
  isIngredient,
  isMenu,
  isTable,
  isUser,
} from '../Datatype';

const getUserRoles = (
  user: UserType,
) => {
  if (user && user.userRoles && user.userRoles.length > 0) {
    return user.userRoles.map((userRole) => userRole.role.id);
  }
  return [];
};

const getDishIngredients = (
  dish: DishType,
) => {
  if (dish && dish.dishIngredients && dish.dishIngredients.length > 0) {
    return dish.dishIngredients.map((dishIngredient) => ({
      id: dishIngredient.ingredient.id,
      quantityNeeded: dishIngredient.quantityNeeded,
      isRemovable: dishIngredient.isRemovable,
    }));
  }
  return [];
};

const getMenuDishes = (
  menu: MenuType,
) => {
  if (menu && menu.menuDishes && menu.menuDishes.length > 0) {
    return menu.menuDishes.map((menuDish) => menuDish.dish.id);
  }
  return [];
};

// Convertit la String de date de la bdd en Date pour la valeur par défaut du champ date
const stringToDate = (
  date: Date,
) => {
  // On crée un objet Date avec notre String de date
  const formattedDate = new Date(date);

  // On en déduit l'année
  const year = formattedDate.getFullYear();

  // Les mois commencent à 0, d'où le + 1. On en déduit le mois
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');

  // On en déduit le jour
  const day = formattedDate.getDate().toString().padStart(2, '0');

  // on retourne la date convertit en Date
  return `${year}-${month}-${day}`;
};

// Méthode renvoyant l'objet en paramètre avec seulement certaines propriétés
export const getEntryColumns = (
  name: string,
  selectedEntry: DataTablesType | undefined,
): Partial<DataTablesType> => {
  switch (name) {
    case 'users':
      if (isUser(selectedEntry) || (selectedEntry === undefined)) {
        return {
          username: selectedEntry ? selectedEntry.username : '',
          realName: selectedEntry ? selectedEntry.realName : '',
          password: '',
          email: selectedEntry ? selectedEntry.email : '',
          phoneNumber: selectedEntry ? selectedEntry.phoneNumber : '',
          employmentStatus: selectedEntry ? selectedEntry.employmentStatus : '',
          socialSecurityNumber: selectedEntry ? selectedEntry.socialSecurityNumber : '',
          comments: selectedEntry ? selectedEntry.comments : '',
          hireDate: selectedEntry ? stringToDate(selectedEntry.hireDate as Date) : '',
          endDate: selectedEntry ? stringToDate(selectedEntry.endDate as Date) : '',
        };
      }
      return {};
    case 'dishes':
      if (isDish(selectedEntry) || (selectedEntry === undefined)) {
        return {
          title: selectedEntry ? selectedEntry.title : '',
          description: selectedEntry ? selectedEntry.description : '',
          price: selectedEntry ? selectedEntry.price : 0,
          category: selectedEntry ? selectedEntry.category : '',
          isSaled: selectedEntry ? selectedEntry.isSaled : false,
          cookingChoice: selectedEntry ? selectedEntry.cookingChoice : false,
        };
      }
      return {};
    case 'menus':
      if (isMenu(selectedEntry) || (selectedEntry === undefined)) {
        return {
          title: selectedEntry ? selectedEntry.title : '',
          description: selectedEntry ? selectedEntry.description : '',
          price: selectedEntry ? selectedEntry.price : 0,
          isSaled: selectedEntry ? selectedEntry.isSaled : false,
        };
      }
      return {};
    case 'ingredients':
      if (isIngredient(selectedEntry) || (selectedEntry === undefined)) {
        return {
          title: selectedEntry?.title || '',
          quantity: selectedEntry?.quantity || 0,
          maxQuantity: selectedEntry?.maxQuantity || 0,
          unit: selectedEntry?.unit || '',
          category: selectedEntry?.category || '',
          isAllergen: selectedEntry?.isAllergen || false,
          alert: selectedEntry?.alert || false,
          increaseMode: selectedEntry?.increaseMode || false,
          startingDay: selectedEntry?.startingDay || '',
          increaseInterval: selectedEntry?.increaseInterval || 0,
          isAdded: selectedEntry?.isAdded || false,
          quantityIncreased: selectedEntry?.quantityIncreased || 0,
        };
      }
      return {};
    case 'tables':
      if (isTable(selectedEntry) || (selectedEntry === undefined)) {
        return {
          number: selectedEntry?.number || 0,
          urlIdentifier: selectedEntry?.urlIdentifier || '',
        };
      }
      return {};
    case 'planning':
      return {};
    default:
      return {};
  }
};

// Méthode renvoyant l'ensemble des ids d'entités secondaires initialement attribuées
export const getInitialAssociatedEntitiesIds = (
  name: string,
  selectedEntry: DataTablesType | undefined,
): number[] | Partial<OrderDishType>[] => {
  switch (name) {
    case 'users':
      if (isUser(selectedEntry)) {
        return getUserRoles(selectedEntry);
      }
      return [];
    case 'dishes':
      if (isDish(selectedEntry)) {
        return getDishIngredients(selectedEntry);
      }
      return [];
    case 'menus':
      if (isMenu(selectedEntry)) {
        return getMenuDishes(selectedEntry);
      }
      return [];
    default:
      return [];
  }
};

// Méthode renvoyant une partie du corps de la requête
export const getBodyData = (
  name: string,
  selectedEntryColumns: Partial<DataTablesType>,
  isAddModal: boolean,
): Partial<DataTablesType> => {
  switch (name) {
    case 'users':
      if (isUser(selectedEntryColumns)) {
        return {
          username: selectedEntryColumns.username,
          realName: selectedEntryColumns.realName,
          phoneNumber: selectedEntryColumns.phoneNumber,
          email: selectedEntryColumns.email,
          hireDate: (
            selectedEntryColumns.hireDate === '' || selectedEntryColumns.hireDate === 'NaN-NaN-NaN'
          ) ? undefined : selectedEntryColumns.hireDate,
          endDate: (
            selectedEntryColumns.endDate === '' || selectedEntryColumns.endDate === 'NaN-NaN-NaN'
          ) ? undefined : selectedEntryColumns.endDate,
          employmentStatus: selectedEntryColumns.employmentStatus,
          socialSecurityNumber: selectedEntryColumns.socialSecurityNumber,
          comments: selectedEntryColumns.comments,

          // Ajoute plainPassword seulement si selectedEntryColumns.password n'est pas vide
          ...(selectedEntryColumns.password && { password: selectedEntryColumns.password }),

          // Ajoute hasImage seulement si la méthode est POST
          hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
        };
      }
      return {};
    case 'dishes':
      if (isDish(selectedEntryColumns)) {
        return {
          title: selectedEntryColumns.title,
          description: selectedEntryColumns.description,
          category: selectedEntryColumns.category,
          price: selectedEntryColumns.price,
          isSaled: selectedEntryColumns.isSaled,
          isAllergen: selectedEntryColumns.isAllergen,
          cookingChoice: selectedEntryColumns.cookingChoice,

          // Ajoute hasImage seulement si la méthode est POST
          hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
        };
      }
      return {};
    case 'menus':
      if (isMenu(selectedEntryColumns)) {
        return {
          title: selectedEntryColumns.title,
          description: selectedEntryColumns.description,
          price: selectedEntryColumns.price,
          isSaled: selectedEntryColumns.isSaled,

          // Ajoute hasImage seulement si la méthode est POST
          hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
        };
      }
      return {};
    default:
      return {};
  }
};
