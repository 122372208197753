import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import getImagePath from '../../../utils/helpers/Client/Parrallax';

const apiUrl = process.env.REACT_APP_API_URL;

// Styled component pour le conteneur de la section avec effet de parallaxe
const ParallaxContainer = styled.div<{
  $section: string,
  $backgroundImg: string,
  }>`
  background-image: url(${apiUrl}/images/${(props) => getImagePath(props.$section)}/${(props) => props.$backgroundImg});
`;

type ParallaxProps = {
  backgroundImg: string,
  section: string,
  children: React.ReactNode,
};

function Parallax({
  backgroundImg,
  section,
  children,
}: ParallaxProps) {
  // État pour stocker la position de défilement de la page
  const [scrollPosition, setScrollPosition] = useState(0);

  // Fonction pour mettre à jour la position de défilement de la page
  const updateScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };

  // Effet pour écouter les événements de défilement de la page
  useEffect(() => {
    setScrollPosition(window.scrollY);
    window.addEventListener('scroll', updateScrollPosition);
    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);

  // Calcul de la position de l'image en fonction de la position de défilement
  const calculateBackgroundPosition = () => {
    let relativeScrollPosition;

    /*
      Pour les images autres que celle de présentation, je trouve la scroll position
      où apparait le bord de l'élément, je retire 200 et je trouve le résultat de
      l'équation (x - scrollposition) * 0.15 = 200.
      Ainsi je commence le décalage 200px avant l'apparition de l'élément,
      puis je fais varier le décalage de +200 à -...
    */
    if (section === 'special' && scrollPosition >= 411) {
      relativeScrollPosition = (1744 - scrollPosition) * 0.15;
    } else if (section === 'presentation') {
      relativeScrollPosition = (0 - scrollPosition) * 0.15;
    } else if (section === 'team' && scrollPosition >= 2058) {
      relativeScrollPosition = (3391 - scrollPosition) * 0.15;
    }
    return `center ${relativeScrollPosition}px`;
  };

  return (
    <>
      {section === 'presentation' && (
        <ParallaxContainer
          id="banner"
          className="banner full-screen-mode parallax"
          $section={section}
          $backgroundImg={backgroundImg}
          style={{ backgroundPosition: calculateBackgroundPosition() }}
        >
          {children}
        </ParallaxContainer>
      )}
      {section === 'special' && (
        <ParallaxContainer
          id="special"
          className="special-menu pad-top-100 parallax"
          $section={section}
          $backgroundImg={backgroundImg}
          style={{ backgroundPosition: calculateBackgroundPosition() }}
        >
          {children}
        </ParallaxContainer>
      )}
      {section === 'team' && (
        <ParallaxContainer
          id="our_team"
          className="team-main pad-top-100 pad-bottom-100 parallax"
          $section={section}
          $backgroundImg={backgroundImg}
          style={{ backgroundPosition: calculateBackgroundPosition() }}
        >
          {children}
        </ParallaxContainer>
      )}
    </>
  );
}

export default Parallax;
