/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { RestaurantMenuDishType } from '../../../typescript/datas/OrderMenuDishType';

const OrderCell = styled.td`
  vertical-align: middle !important;
`;

const OrderProductContent = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const OrderProductImgContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

const OrderProductImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const OrderProductInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderRemove = styled.td`
  vertical-align: middle !important;
`;

const OrderCloseButton = styled.button`
  background-color: #202020;
  border: none;
`;

type MenuOrderDetailsProps = {
  order: RestaurantMenuDishType,
  setOrders: React.Dispatch<React.SetStateAction<RestaurantMenuDishType[]>>,
};

function MenuOrderDetails({
  order,
  setOrders,
}: MenuOrderDetailsProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  // Permet la suppression d'un produit du panier
  const handleRemoveProduct = (
    productId: number,
  ) => {
    // On retire le produit qui correspond aux conditions
    setOrders((prevOrders) => prevOrders.filter((prevOrder) => (
      !(prevOrder.id === productId)
    )));
  };
  return (
    <tr>
      <OrderCell className="p-4">
        <OrderProductContent>
          <OrderProductImgContainer>
            <OrderProductImg src={`${apiUrl}/images/menus/${order.imageName}`} alt="" />
          </OrderProductImgContainer>
          <OrderProductInfos>
            <p>
              {window.innerWidth <= 768 ? '' : 'Menu '}
              {order.title}
            </p>
            <p>
              <small style={{ textDecoration: 'underline' }}>
                Plats choisis
              </small>
              <small>
                {' '}
                :
              </small>
            </p>
            {order.associatedDishes.map((associatedDish) => (
              <small key={associatedDish.id}>
                <span>
                  -
                  {' '}
                  {associatedDish.title}
                  {associatedDish.ingredientsToRemove.length > 0 && (
                    <>
                      <br />
                      <span className="text-muted">
                        Retrait:
                        <br />
                        {associatedDish.ingredientsToRemove.map(
                          (ingredientToRemove) => (
                            <span key={ingredientToRemove}>
                              {window.innerWidth <= 768 ? '' : '-->'}
                              {ingredientToRemove}
                              <br />
                            </span>
                          ),
                        )}
                      </span>
                    </>
                  )}
                  {associatedDish.selectedMeat !== '' && (
                    <span className="text-muted">
                      {associatedDish.ingredientsToRemove.length === 0 ? <br /> : ''}
                      Cuisson:
                      <br />
                      {window.innerWidth <= 768 ? '' : '-->'}
                      {associatedDish.selectedMeat}
                    </span>
                  )}
                </span>
              </small>
            ))}
          </OrderProductInfos>
        </OrderProductContent>
      </OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price}
        {' '}
        €
      </OrderCell>
      <OrderCell className="align-middle p-4 text-center">1</OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price * order.quantity}
        {' '}
        €
      </OrderCell>
      <OrderRemove className="text-center align-middle px-0"><OrderCloseButton type="button" style={{ color: 'red' }} onClick={() => handleRemoveProduct(order.id as number)}>x</OrderCloseButton></OrderRemove>
    </tr>
  );
}

export default MenuOrderDetails;
