/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function ColorPanel() {
  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const handlePanel = () => setIsPanelOpened(!isPanelOpened);
  const changeColor = (
    color: string,
  ) => {
    const linkElement = document.getElementById('changeable-colors');

    if (linkElement) {
      linkElement.setAttribute('href', `/RestaurantTheme/css/colors/${color}.css`);
    }
  };

  return (
    <section id="color-panel" className={`${isPanelOpened ? 'open-color-panel' : 'close-color-panel'}`}>
      <a className="panel-button gray2" onClick={handlePanel}>
        <FontAwesomeIcon icon={faCog} className="fa-spin" size="2xl" />
      </a>
      {/* Colors */}
      <div className="segment">
        <h4 className="gray2 normal no-padding">Color Scheme</h4>
        <a title="orange" className="switcher orange-bg" onClick={() => changeColor('orange')} />
        {' '}
        <a title="strong-blue" className="switcher strong-blue-bg" onClick={() => changeColor('strong-blue')} />
        {' '}
        <a title="moderate-green" className="switcher moderate-green-bg" onClick={() => changeColor('moderate-green')} />
        {' '}
        <a title="vivid-yellow" className="switcher vivid-yellow-bg" onClick={() => changeColor('vivid-yellow')} />
      </div>
    </section>
  );
}

export default ColorPanel;
