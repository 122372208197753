/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import SpinnerWrapper from '../../SpinnerWrapper';
import { useApi } from '../../../../utils/hooks/admin';
import { FileType } from '../../../../typescript/datas/FileType';
import { isHydraMember, isImage, isToken } from '../../../../utils/helpers/Datatype';
import { ImageType } from '../../../../typescript/datas/ImageTypes';
import { UserType } from '../../../../typescript/datas/UserType';

type PersonalInfosFormPartProps = {
  handleProfileModal: () => Promise<void>,
}

const StyledModal = styled(Modal)`
  p {
    margin-bottom: 0px;
  }
  h5 {
    color: #6C7293;
    font-weight: bold;
    text-decoration: underline;
  }
`;

function PersonalInfosFormPart({
  handleProfileModal,
}: PersonalInfosFormPartProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    errors,
    authToken,
    authId,
    authRoles,
    authPermissions,
    setAuthImg,
    updateUserAuth,
    fetchData,
  } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [entry, setEntry] = useState({
    realName: '',
    username: '',
    password: '',
    phoneNumber: '',
    email: '',
    hireDate: '',
    employmentStatus: '',
    comments: '',
    endDate: '',
    socialSecurityNumber: '',
  });

  // Contient les informations à envoyer concernant l'image donnée par l'utilisateur
  const [imageInfos, setImageInfos] = useState<FileType>();

  // Méthode permettant de mettre à jour le state avec les informations du fichier image donné
  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files) {
      // On récupère le fichier passé
      const file = e.target.files[0];

      // On met à jour notre state avec le contenu du fichier et son nom
      setImageInfos({
        imageFile: file,
        imageName: file.name,
      });
    }
  };

  // Requête l'API à la soumission du formulaire
  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    // Mes requêtes vont s'effectuer, j'affiche mon loading
    setIsLoading(true);
    // On retire le comportement par défaut du formulaire
    e.preventDefault();
    // Contiendra le fichier. FormData est obligatoire pour pouvoir le transmettre à l'API
    const ImageformData = new FormData();

    // Si une image a été spécifiée
    if (imageInfos) {
      // On met à jour notre entité en lui ajoutant les information de l'image
      ImageformData.append('image', imageInfos.imageFile);
      ImageformData.append('imageName', imageInfos.imageName);
    }

    // Je retire tout ce qui est inutile
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      password,
      realName,
      hireDate,
      employmentStatus,
      comments,
      endDate,
      socialSecurityNumber,
      ...rest
    } = entry;

    const entityOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'application/merge-patch+json',
      },
      body: JSON.stringify({
        ...rest,

        // Je rajoute le password si il est défini
        ...(password !== '' && { password }),
      }),
    };

    // Méthode requêtant l'API
    const submitEntity = async () => {
      /* ************ TRAITEMENT DE L'AJOUT / MODIF DE L'ENTITÉ  ************ */

      // Contient l'url à atteindre pour effectuer l'ajout ou la modification
      const entityUrl = `${apiUrl}/api/users/${authId}`;

      // On requête l'API pour traiter l'inscription ou la modification de l'entité
      const { data: entityData } = await fetchData(entityUrl, entityOptions);

      if (!entityData) {
        setIsLoading(false);
        return;
      }

      // Dans le cas de l'ajout / modif d'un utilisateur, un token sera renvoyé
      if (isToken(entityData) && entityData.token) {
        /*
          Stockage de l'objet authToken dans le LocalStorage et
          Maj des valeurs partagées par le context ApiContext
        */
        updateUserAuth(entityData.token);
      }

      // Si une image a été spécifiée
      if (imageInfos !== undefined) {
        const imagePostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: ImageformData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `${apiUrl}/api/users/${authId}`;

        // On requête l'API pour inscrire la nouvelle image
        const { data: imgData } = await fetchData<ImageType>(imagePostUrl, imagePostOptions);

        if (isImage(imgData)) {
          // Je mets à jour son image
          setAuthImg(imgData.imageName);
        }
      }
      setIsLoading(false);
      handleProfileModal();
    };

    submitEntity();
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      /*
        Je récupère le user en cours à partir de la liste de ces derniers. Obtenir la liste
        des users me permettra aussi de créer le cache associé
      */
      const { data: usersData } = await fetchData<UserType>(`${apiUrl}/api/users`, options);

      if (isHydraMember<UserType>(usersData)) {
        const currentUser = usersData['hydra:member'].find((userData) => userData.id === authId);

        if (currentUser) {
          // Requête l'API récupérant la liste des entités
          setEntry({
            realName: currentUser.realName,
            username: currentUser.username,
            password: '',
            email: currentUser.email,
            phoneNumber: currentUser.phoneNumber,
            hireDate: currentUser.hireDate as string,
            employmentStatus: currentUser.employmentStatus,
            comments: currentUser.comments,
            endDate: currentUser.endDate as string,
            socialSecurityNumber: currentUser.socialSecurityNumber,
          });
        }
      }

      setIsLoading(false);
    };

    fetchDataAsync();
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <StyledModal show onHide={handleProfileModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Profil de
            {' '}
            {entry.realName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>
                Pseudonyme
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setEntry({
                  ...entry, username: e.target.value,
                })}
                value={entry.username}
                required
              />
              {errors.includes('duplicateUsername')
              && <p className="text-primary">Ce pseudonyme est déjà utilisé.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>
                Mot de passe
              </Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setEntry({
                  ...entry,
                  password: e.target.value,
                })}
                value={entry.password}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>
                Email
              </Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setEntry({
                  ...entry, email: e.target.value,
                })}
                value={entry.email}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="telephone">
              <Form.Label>
                Téléphone
              </Form.Label>
              <Form.Control
                type="tel"
                onChange={(e) => setEntry({
                  ...entry, phoneNumber: e.target.value,
                })}
                value={entry.phoneNumber}
              />
            </Form.Group>

            <div className="mb-3">
              <h5>Rôle(s) attribué(s)</h5>
              {authRoles.map((authRole) => (
                <p key={authRole}>
                  -
                  {' '}
                  {authRole}
                </p>
              ))}
            </div>

            <div className="mb-3">
              <h5>Permission(s) accordée(s)</h5>
              {authPermissions.map((authPermission) => (
                <p key={authPermission}>
                  -
                  {' '}
                  {authPermission}
                </p>
              ))}
            </div>

            <div className="mb-3">
              <h5>Date d&apos;embauche</h5>
              <p>{new Date(entry.hireDate).toLocaleDateString()}</p>
            </div>

            <div className="mb-3">
              <h5>Fin du contrat</h5>
              <p>{new Date(entry.endDate).toLocaleDateString()}</p>
            </div>

            <div className="mb-3">
              <h5>Type de contrat</h5>
              <p>{entry.employmentStatus}</p>
            </div>

            <div className="mb-3">
              <h5>N° Sécu</h5>
              <p>{entry.socialSecurityNumber}</p>
            </div>

            <div className="mb-3">
              <h5>Commentaires</h5>
              <p>{entry.comments}</p>
            </div>

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image de profil</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              <Button variant="success" size="sm" className="me-4" type="submit">
                Valider
              </Button>

              <Button variant="primary" size="sm" onClick={handleProfileModal}>
                Annuler
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
}

export default PersonalInfosFormPart;
