import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

type SpinnerWrapperProps = {
  $showSpinner: boolean,
}

const SpinnerWrapperStyled = styled.div<{ $visible: boolean }>`
  position: fixed;
    top: 50%;
    left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
`;

// Mon composant représentant le spinner qui s'affiche en fonction de sa prop.
// le '$' est important car la props va être utilisé dans un styled-component
const SpinnerWrapper: React.FC<SpinnerWrapperProps> = React.memo(({ $showSpinner }) => (
  <SpinnerWrapperStyled $visible={$showSpinner}>
    <Spinner animation="border" className="text-primary" role="status" />
  </SpinnerWrapperStyled>
));

export default SpinnerWrapper;
