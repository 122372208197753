import { differenceInMonths } from 'date-fns';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';
import { UserType } from '../../../typescript/datas/UserType';
import { DataTablesType } from '../../../typescript/datas/DataTablesType';
import { CacheType } from '../../../typescript/types/CacheTypes';
import {
  isDish,
  isDishOrMenu,
  isIngredient,
  isTable,
  isUser,
} from '../Datatype';
import { TitleKeyType } from '../../../typescript/types/TitleKeysType';

// Convertit le nom de mes colonnes en une version plus adapté à l'affichage
export const getLabelForColumn = (
  columnName: string,
) => {
  switch (columnName) {
    case 'username':
      return 'Pseudonyme';
    case 'phoneNumber':
      return 'Téléphone';
    case 'email':
      return 'E-mail';
    case 'hireDate':
      return 'Ancienneté';
    case 'roles':
      return 'Roles';
    case 'endDate':
      return 'Fin du contrat';
    case 'employmentStatus':
      return 'Type de contrat';
    case 'socialSecurityNumber':
      return 'N° Sécu';
    case 'comments':
      return 'Commentaires';

      /* -------------------------------------------------- */

    case 'quantity':
      return 'Stock restant';
    case 'percentQuantity':
      return '% restant';
    case 'category':
      return 'Catégorie';
    case 'isAllergen':
      return 'Allergène ?';
    case 'alert':
      return 'Alerte ?';
    case 'increaseMode':
      return 'Incrémentation ?';
    case 'startingDay':
      return ' 1ère incr.';
    case 'increaseInterval':
      return 'Incr. tous les';
    case 'isAdded':
      return 'Ajouter/Redéfinir';
    case 'quantityIncreased':
      return 'Redéfini à';

      /* -------------------------------------------------- */

    case 'price':
      return 'Prix (€)';
    case 'isSaled':
      return 'Vendu ?';
    case 'cookingChoice':
      return 'Cuisson ?';

      /* -------------------------------------------------- */

    case 'number':
      return 'Numéro';
    case 'isOccupied':
      return 'Table occupée ?';
    case 'urlIdentifier':
      return 'Identifiant URL';

      /* -------------------------------------------------- */
    default:
      return '';
  }
};

// Calcule le nombre de mois entre l'embauche et la date actuelle
const calculateMonthsOfService = (
  hireDate: Date,
) => {
  const currentDate = new Date();
  const difference = differenceInMonths(currentDate, hireDate);

  return Number.isNaN(difference) ? '' : `${difference} mois`;
};

// Utilisation de la fonction utilitaire pour récupérer les noms des rôles d'un utilisateur
const getRolesName = (user: UserType) => user.userRoles
  .map((userRole) => userRole.role.name) // Récupérer directement le nom du rôle
  .sort((a, b) => a.localeCompare(b)) // Trier par ordre alphabétique
  .map((roleName, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li className="list-unstyled" key={index}>
      {roleName}
    </li>
  ));

// Permet de déterminer la valeur à renvoyer en cas de problème
const getDefaultIfUndefined = (
  value: string | number | boolean,
) => (
  (value !== undefined) && (value !== '') ? value : 'À définir'
);

const getDefaultDateIfUndefined = (
  value: Date | string,
) => {
  const date = typeof value === 'string' ? new Date(value) : null;
  return date ? date.toLocaleDateString() : 'À Définir';
};

const formatPercentage = (
  value: string,
) => (value.endsWith('.00') ? parseInt(value, 10) : value);

export const getColumnValue = (
  entry: DataTablesType,
  column: string,
) => {
  switch (column) {
    case 'username':
      if (isUser(entry)) {
        return entry.username;
      }
      return '';
    case 'realName':
      if (isUser(entry)) {
        return entry.realName;
      }
      return '';
    case 'phoneNumber':
      if (isUser(entry)) {
        return entry.phoneNumber;
      }
      return '';
    case 'email':
      if (isUser(entry)) {
        return entry.email;
      }
      return '';
    case 'hireDate':
      if (isUser(entry)) {
        return getDefaultIfUndefined(calculateMonthsOfService(entry.hireDate as Date));
      }
      return '';
    case 'roles':
      if (isUser(entry)) {
        return getRolesName(entry);
      }
      return [];
    case 'endDate':
      if (isUser(entry)) {
        return getDefaultDateIfUndefined(entry.endDate as string);
      }
      return '';
    case 'employmentStatus':
      if (isUser(entry)) {
        return getDefaultIfUndefined(entry.employmentStatus);
      }
      return '';
    case 'socialSecurityNumber':
      if (isUser(entry)) {
        return getDefaultIfUndefined(entry.socialSecurityNumber);
      }
      return '';
    case 'comments':
      if (isUser(entry)) {
        return getDefaultIfUndefined(entry.comments);
      }
      return '';

      /* -------------------------------------------------- */

    case 'title':
      if (isIngredient(entry)) {
        return entry.title;
      }
      return '';
    case 'quantity':
      if (isIngredient(entry)) {
        return `${Math.round(entry.quantity * 1000) / 1000} ${entry.unit}`;
      }
      return '';
    case 'percentQuantity':
      if (isIngredient(entry)) {
        return `${formatPercentage(((entry.quantity / entry.maxQuantity) * 100).toFixed(2))} %`;
      }
      return '';
    case 'category':
      if (isIngredient(entry) || isDish(entry)) {
        return entry.category;
      }
      return '';
    case 'isAllergen':
      if (isIngredient(entry)) {
        return entry.isAllergen ? 'Oui' : 'Non';
      }
      return '';
    case 'alert':
      if (isIngredient(entry)) {
        return entry.alert ? 'Oui' : 'Non';
      }
      return '';
    case 'increaseMode':
      if (isIngredient(entry)) {
        return entry.increaseMode ? 'Oui' : 'Non';
      }
      return '';
    case 'startingDay':
      if (isIngredient(entry)) {
        return getDefaultDateIfUndefined(entry.startingDay as string);
      }
      return '';
    case 'increaseInterval':
      if (isIngredient(entry)) {
        return getDefaultIfUndefined(entry.increaseInterval);
      }
      return '';
    case 'isAdded':
      if (isIngredient(entry)) {
        return getDefaultIfUndefined(entry.isAdded) === true ? 'Ajouter' : 'Redéfinir';
      }
      return '';
    case 'quantityIncreased':
      if (isIngredient(entry)) {
        return entry.isAdded ? `+ ${getDefaultIfUndefined(entry.quantityIncreased)}` : `à ${getDefaultIfUndefined(entry.quantityIncreased)}`;
      }
      return '';

      /* -------------------------------------------------- */

    case 'price':
      if (isDishOrMenu(entry)) {
        return entry.price;
      }
      return '';
    case 'isSaled':
      if (isDishOrMenu(entry)) {
        return entry.isSaled ? 'Oui' : 'Non';
      }
      return '';
    case 'cookingChoice':
      if (isDish(entry)) {
        return entry.cookingChoice ? 'Oui' : 'Non';
      }
      return '';

      /* -------------------------------------------------- */

    case 'number':
      if (isTable(entry)) {
        return entry.number;
      }
      return '';
    case 'isOccupied':
      if (isTable(entry)) {
        return entry.isOccupied ? 'Oui' : 'Non';
      }
      return '';
    case 'urlIdentifier':
      if (isTable(entry)) {
        return entry.urlIdentifier;
      }
      return '';

    default:
      return '';
  }
};

// Méthode permettant de changer la couleur du texte en fonction du pourcentage
export const getTextColorClass = (
  percentQuantity: number,
  stockWarningThreshold: number,
  stockCriticalThreshold: number,
) => {
  if (percentQuantity >= stockWarningThreshold) {
    return 'text-success';
  } if (percentQuantity > stockCriticalThreshold) {
    return 'text-warning';
  }
  return 'text-danger';
};

// Méthode permettant de récuperer le contenu du cache pour la ressource nommée en paramètre
export const getCacheContent = (
  name: NameType,
  cache: CacheType<DataTablesType>,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  switch (name) {
    case 'users':
      return [...cache[`${apiUrl}/api/users`]['hydra:member']];
    case 'ingredients':
      return [...cache[`${apiUrl}/api/ingredients`]['hydra:member']];
    case 'dishes':
      return [...cache[`${apiUrl}/api/dishes`]['hydra:member']];
    case 'menus':
      return [...cache[`${apiUrl}/api/menus`]['hydra:member']];
    case 'tables':
      return [...cache[`${apiUrl}/api/tables`]['hydra:member']];
    default:
      return [];
  }
};

// Méthode permettant d'obtenir les informations nécessaires pour la mise à jour du cache trié
export const getUpdatedCacheContent = (
  name: NameType,
  cache: CacheType<DataTablesType>,
  sortedData: DataTablesType[],
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  switch (name) {
    case 'users':
      return {
        apiUrl: `${apiUrl}/api/users`,
        updatedCacheData: {
          ...cache[`${apiUrl}/api/users`],
          'hydra:member': sortedData,
        },
      };
    case 'ingredients':
      return {
        apiUrl: `${apiUrl}/api/ingredients`,
        updatedCacheData: {
          ...cache[`${apiUrl}/api/ingredients`],
          'hydra:member': sortedData,
        },
      };
    case 'dishes':
      return {
        apiUrl: `${apiUrl}/api/dishes`,
        updatedCacheData: {
          ...cache[`${apiUrl}/api/dishes`],
          'hydra:member': sortedData,
        },
      };
    case 'menus':
      return {
        apiUrl: `${apiUrl}/api/menus`,
        updatedCacheData: {
          ...cache[`${apiUrl}/api/menus`],
          'hydra:member': sortedData,
        },
      };
    case 'tables':
      return {
        apiUrl: `${apiUrl}/api/tables`,
        updatedCacheData: {
          ...cache[`${apiUrl}/api/tables`],
          'hydra:member': sortedData,
        },
      };
    default:
      return {};
  }
};

// Méthode définissant les colonnes de base qui peuvent être triées
export const getSortParam = (
  entityName: string,
) => {
  switch (entityName) {
    case 'users':
      return 'realName';
    case 'ingredients':
      return 'title';
    case 'dishes':
      return 'title';
    case 'menus':
      return 'title';
    default:
      return 'realName';
  }
};

// Titres qu'il est possible d'attribuer à mes tableaux
export const titles: Record<TitleKeyType, string> = {
  users: 'Liste des utilisateurs',
  ingredients: 'Liste des ingrédients',
  dishes: 'Liste des plats',
  menus: 'Liste des menus',
  tables: 'Liste des tables',
};
