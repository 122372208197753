/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import DishOrderDetails from '../DishOrderDetails';
import MenuOrderDetails from '../MenuOrderDetails';
import { RestaurantOrderType } from '../../../typescript/datas/OrderTypes';
import { ConfirmationType } from '../../../typescript/type/ConfirmationType';
import { isRestaurantMenuDish } from '../../../utils/helpers/Datatype';
import { RestaurantOrderDishType } from '../../../typescript/datas/OrderDishTypes';
import { RestaurantMenuDishType } from '../../../typescript/datas/OrderMenuDishType';

const OrderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #202020;
  z-index: 9999;
  padding: 30px;
  color: white !important;
  font-size: 18px;
  vertical-align: auto !important;
  overflow-y: scroll;
  max-height: 90vh;

  @media screen and (max-width: 768px) {
    width: 95%;
  }

  @media screen and (max-width: 450px) {
      padding: 10px;
  }

  th, td {
    @media screen and (max-width: 450px) {
      white-space: normal !important;
    }
  }
`;

const OrderButtons = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;

const OrderValidation = styled(Button)`
  cursor: pointer;
  padding: 5px;
  display: inline;
  font-size: 18px;
  margin-right: 50px;
`;

const OrderClose = styled(Button)`
  cursor: pointer;
  padding: 5px;
  display: inline;
  font-size: 18px;
`;

type OrderDetailsProps = {
  orders: RestaurantOrderType[],
  setOrders : React.Dispatch<React.SetStateAction<RestaurantOrderType[]>>,
  setOrderDetails: React.Dispatch<React.SetStateAction<boolean>>,
  confirmation: ConfirmationType,
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationType>>,
};

function OrderDetails({
  orders,
  setOrders,
  setOrderDetails,
  confirmation,
  setConfirmation,
}: OrderDetailsProps) {
  const totalPrice = orders.reduce((total, order) => total + (order.quantity * order.price), 0);

  const handleOrderDetailsChange = () => {
    setOrderDetails((prevOrderDetails) => !prevOrderDetails);
  };

  useEffect(() => {
    if (confirmation.display) {
      setOrderDetails(false);
    }
  }, [confirmation]);

  return (
    <OrderContainer className="container">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered m-0">
              <thead>
                <tr>
                  <th className="text-center py-3 px-4">
                    {window.innerWidth <= 768 ? 'Articles' : 'Articles'}
                  </th>
                  <th className="text-center py-3 px-4">
                    {window.innerWidth <= 768 ? '€' : 'Prix'}
                  </th>
                  <th className="text-center py-3 px-4" style={{ width: '60px' }}>
                    {window.innerWidth <= 768 ? 'Qté' : 'Quantité'}
                  </th>
                  <th className="text-center py-3 px-4">
                    {window.innerWidth <= 768 ? 'T' : 'Total'}
                  </th>
                  <th className="text-center align-middle py-3 px-0" style={{ width: '40px' }}><a href="#" className="shop-tooltip float-none text-light"><i className="ino ion-md-trash" /></a></th>
                </tr>
              </thead>
              <tbody>

                {orders.map((order) => {
                  const isMenu = isRestaurantMenuDish(order);

                  return (
                    isMenu
                      ? (
                        <MenuOrderDetails
                          key={order.id}
                          order={order}
                          setOrders={setOrders as React.Dispatch<React.SetStateAction<
                            RestaurantMenuDishType[]
                          >>}
                        />
                      )
                      : (
                        <DishOrderDetails
                          key={order.id}
                          order={order}
                          setOrders={setOrders as React.Dispatch<React.SetStateAction<
                            RestaurantOrderDishType[]
                          >>}
                        />
                      )
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center pb-4">
            <div className="d-flex">
              <div className="text-right mt-4">
                <label className="text-muted font-weight-normal m-0">Prix total</label>
                <div className="text-large">
                  <strong>
                    {Math.round(totalPrice * 100) / 100}
                    {' '}
                    €
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <OrderButtons className="text-center">
            {orders.length > 0 && (
              <OrderValidation className="btn-success" onClick={() => setConfirmation({ display: true, totalPrice })}>Valider la commande</OrderValidation>
            )}
            <OrderClose id="orderClose" onClick={handleOrderDetailsChange}>Fermer</OrderClose>
          </OrderButtons>
        </div>
      </div>
    </OrderContainer>
  );
}

export default OrderDetails;
