/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useApi } from '../../../utils/hooks/admin';
import OrderValidationModal from '../OrderValidationModal';
import { OrderBodyType, OrderDataType } from '../../../typescript/datas/OrderTypes';
import { WaitingTimeType } from '../../../typescript/datas/RestaurantOptionsType';

const OrderContainer = styled.div<{
  $timeStatus: number,
  $allReady: boolean,
  $mode: string,
  }>`
  border: ${({ $timeStatus }) => {
    if ($timeStatus === 1) return '1px solid yellow';
    if ($timeStatus === 2) return '1px solid red';
    return '1px solid green';
  }};

  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 2px;
    background-color: red;
    display: ${({ $allReady, $mode }) => ($allReady && $mode !== 'AllCaisse' ? 'block' : 'none')};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  p {
    margin: 0px;
  }
`;

const OrderHeader = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const OrderItem = styled.div`
  margin: 10px;
  padding: 10px;
`;

const MenuItem = styled.div`
  border: 1px solid white;
  padding: 5px 0px 5px 0px;
`;

const Title = styled.h6<{ $status: number }>`
  color: ${({ $status }) => {
    if ($status === 3) return 'green';
    if ($status === 2) return 'yellow';
    if ($status === 1) return 'blue';
    return 'red';
  }};
  cursor: pointer;
`;

const Payment = styled.p`
  color: red;
`;

const OrderValidation = styled.div`
  text-align: center;
`;

const OrderValidationButton = styled(Button)`
  width: 140px;
`;

type OrderDisplayProps = {
  order: OrderDataType,
  mode: string,
  waitingTime: WaitingTimeType,
};

function OrderDisplay({
  order,
  mode,
  waitingTime,
}: OrderDisplayProps) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    id,
    orderDate,
    orderDishes = [],
    orderMenus = [],
    tableRef = null,
    totalPrice,
    isPaid,
  } = order;

  const number = tableRef?.number;

  const { authToken, fetchData } = useApi();

  // Contient tous les plats simples d'une commande
  const [localOrderDishes, setLocalOrderDishes] = useState(orderDishes);

  // Contient tous les plats d'un menu d'une commande
  const [localOrderMenus, setLocalOrderMenus] = useState(orderMenus);

  // Donne une indication sur le temps d'attente de la commande
  const [timeStatus, setTimeStatus] = useState(0);

  // Permet de gérer l'affichage de la modale de confirmation de paiement
  const [showValidate, setShowValidate] = useState(false);

  // Évent déclenché au clic sur un plat
  const handleDishClick = async (
    dishId: number,
    index: number,
    isMenu: boolean,
    status: number,
    menuIndex?: number,
    menuId?: number,
    menuDishId?: number,
  ) => {
    let newStatus: number;

    // Si c'est l'interface du cuisinier il peut passer le status à 'préparé'
    if (mode === 'Préparation' && status === 1) {
      newStatus = 2;
    // Si c'est l'interface du serveur il peut passer le status à 'à préparer'
    } else if (mode === 'Service' && status === 0) {
      newStatus = 1;
    // Si c'est l'interface du serveur il peut passer le status à 'servie'
    } else if (mode === 'Service' && status === 2) {
      newStatus = 3;
    } else {
      return;
    }

    const body: OrderBodyType = {
      // Je dois toujours mettre à jour le status
      status: newStatus,
    };

    // Si on demande la préparation d'un plat je dois changer la date
    if (newStatus === 1) {
      body.orderDate = new Date();
    }

    // Si un plat sans menu a été cliqué je dois spécifier l'id du plat
    if (!isMenu) {
      body.orderDishId = dishId;
    // Si un plat d'un menu a été cliqué je dois spécifier l'id du menu et du plat
    } else {
      body.orderMenuId = menuId;
      body.orderDishId = dishId;
    }

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'application/merge-patch+json',
      },
      body: JSON.stringify(body),
    };

    // Si on a cliqué sur un plat d'un menu
    if (isMenu && menuIndex !== undefined) {
      // Permet d'obtenir tous les plats associé à un menu
      const updatedMenus = [...localOrderMenus];
      // On obtient le status du plat cliqué associé à un menu
      const currentStatus = updatedMenus[menuIndex].orderMenuDishes[index].status;

      // Si le cuisinier a cliqué et que le status était 'à préparer'
      if (mode === 'Préparation' && currentStatus === 1) {
        // On passe le status à 'préparé'
        updatedMenus[menuIndex].orderMenuDishes[index].status = 2;
        // Si le serveur a cliqué et que le plat est commandé
      } else if (mode === 'Service' && currentStatus === 0) {
        // On passe le status à 'à préparer'
        updatedMenus[menuIndex].orderMenuDishes[index].status = 1;
      // Si le serveur a cliqué et que le plat est préparé
      } else if (mode === 'Service' && currentStatus === 2) {
        // On passe le status à 'servi'
        updatedMenus[menuIndex].orderMenuDishes[index].status = 3;
      }

      setLocalOrderMenus(updatedMenus);

      // On met à jour le status dans la base
      if (newStatus !== 1) {
        await fetchData(`${apiUrl}/api/order_menu_dishes/${menuDishId}`, options);
      // Si le serveur vient d'indiquer qu'il faut préparer le plat suivant, on change l'heure de la commande et son status
      } else {
        await fetchData(`${apiUrl}/api/ordersStatus/${id}`, options);
      }
    // Si on a cliqué sur un plat simple, on met à jour le status dans la base
    } else if (newStatus !== 1) {
      await fetchData(`${apiUrl}/api/order_dishes/${dishId}`, options);
      // Si le serveur vient d'indiquer qu'il faut préparer le plat suivant, on change l'heure de la commande et son status
    } else {
      await fetchData(`${apiUrl}/api/ordersStatus/${id}`, options);
    }
  };

  /*
    True si tous les plats simples et tous les plats de menu
    de la commande ont le status 3 (servis)
  */
  const allReady = [...localOrderDishes, ...localOrderMenus.flatMap((menu) => menu.orderMenuDishes)]
    .every((dish) => dish.status === 3);

  // Vide la table en mettant isOccupied à false
  const handleOrderArchive = async () => {
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'application/merge-patch+json',
      },
      body: JSON.stringify({
        isOccupied: false,
      }),
    };

    await fetchData(`${apiUrl}/api/tablesOccupied/${id}`, options);
  };

  const handleOrderDelete = async () => {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    await fetchData(`${apiUrl}/api/tablesOccupied/${id}`, options);
  };

  // Permet de retourner l'état de la commande
  const getOrderStatus = (
    paid: boolean,
  ) => (paid ? 'Payée' : 'En cours');

  // Permet de lancer le calcul du temps d'attente à intervale régulier
  useEffect(() => {
    // L'heure de la commande
    const targetTime = new Date(orderDate);

    // Calcule le temps d'attente de la commande
    const checkTimeDifference = () => {
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - targetTime.getTime();
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      // Le temps d'attente est moyen
      if (timeDifferenceInMinutes > waitingTime.averageWaitTime && timeDifferenceInMinutes <= waitingTime.longWaitTime) {
        setTimeStatus(1);
      // Le temps d'attente est long
      } else if (timeDifferenceInMinutes > waitingTime.longWaitTime) {
        setTimeStatus(2);
      } else {
        setTimeStatus(0);
      }
    };

    if (waitingTime && Object.keys(waitingTime).length > 0) {
      // Vérification initiale (pour éviter de devoir attendre 1m avant la première vérif)
      checkTimeDifference();

      // Vérifie toutes les minutes le temps d'attente
      const intervalId = setInterval(checkTimeDifference, waitingTime.refreshInterval * 1000);

      return () => clearInterval(intervalId);
    }

    return undefined;
  }, [orderDate, waitingTime]);

  // Quand les order provenant du parent sont modifiées (par changement de status d'un plat par ex)
  useEffect(() => {
    // On met à jour les states de l'enfant contentant les info des plat et plat de menu
    setLocalOrderDishes(orderDishes);
    setLocalOrderMenus(orderMenus);
  }, [order]);

  return (
    <OrderContainer $allReady={allReady} $mode={mode} $timeStatus={timeStatus}>
      <OrderHeader className="bg-secondary text-center rounded">
        {mode === 'AllCaisse' && <h5>N°{id}</h5>}
        <h5>
          {mode !== 'AllCaisse' ? `Table ${number}` : getOrderStatus(isPaid)}
        </h5>
        <p>
          {mode !== 'AllCaisse' ? new Date(orderDate).toLocaleTimeString() : `${new Date(orderDate).toLocaleDateString()} ${new Date(orderDate).toLocaleTimeString()}`}
        </p>
        {(mode === 'Caisse' || mode === 'AllCaisse') && (
          <Payment>
            {isPaid ? 'Montant payé' : 'Total à payer'}
            <br />
            {totalPrice}
            {' '}
            €
          </Payment>
        )}
      </OrderHeader>
      <OrderItemContainer>
        {localOrderDishes.map((orderDish, index) => (
          <OrderItem key={index} className="bg-secondary text-center rounded">
            <Title
              $status={orderDish.status}
              onClick={mode !== 'Caisse' && mode !== 'AllCaisse' ? () => handleDishClick(orderDish.id, index, false, orderDish.status) : undefined}
            >
              {orderDish.dishRef.title}
            </Title>
            {orderDish.quantity !== 1 && (
              <p>Qty : {orderDish.quantity}</p>
            )}
            {(mode === 'Caisse' || mode === 'AllCaisse') && (
              <Payment>
                Prix : {orderDish.dishRef.price
                ? `${orderDish.dishRef.price * orderDish.quantity} €`
                : 'Prix non disponible'}
              </Payment>
            )}
            {mode !== 'Caisse' && orderDish.cookedMeat !== '' && (
              <p>Cuisson : {orderDish.cookedMeat}</p>
            )}
            {mode !== 'Caisse' && orderDish.ingredientsToRemove.length > 0 && (
              <p>Retirer : {orderDish.ingredientsToRemove.join(', ')}</p>
            )}
          </OrderItem>
        ))}

        {localOrderMenus.map((orderMenu, menuIndex) => (
          <OrderItem key={menuIndex} className="bg-secondary text-center rounded">
            <h6>{orderMenu.menuRef.title}</h6>
            {(mode === 'Caisse' || mode === 'AllCaisse') && (
              <Payment style={{ marginBottom: '8px' }}>Prix : {orderMenu.menuRef.price} €</Payment>
            )}
            {orderMenu.orderMenuDishes.map((menuDish, index) => (
              <MenuItem key={`${menuIndex}-${index}`}>
                <Title
                  $status={menuDish.status}
                  onClick={mode !== 'Caisse' && mode !== 'AllCaisse' ? () => handleDishClick(menuDish.dish.id as number, index, true, menuDish.status, menuIndex, orderMenu.id, menuDish.id) : undefined}
                >
                  {menuDish.dish.title}
                </Title>
                {menuDish.quantity !== 1 && (
                  <p>Qty : {menuDish.quantity}</p>
                )}
                {mode !== 'Caisse' && menuDish.cookedMeat !== '' && (
                  <p>Cuisson : {menuDish.cookedMeat}</p>
                )}
                {mode !== 'Caisse' && menuDish.ingredientsToRemove.length > 0 && (
                  <p>Retirer : {menuDish.ingredientsToRemove.join(', ')}</p>
                )}
              </MenuItem>
            ))}
          </OrderItem>
        ))}
      </OrderItemContainer>
      {mode === 'Caisse' && (
        <OrderValidation>
          <OrderValidationButton
            type="button"
            className="btn btn-primary btn-success"
            onClick={() => setShowValidate(!showValidate)}
          >
            Confirmer le paiement
          </OrderValidationButton>
        </OrderValidation>
      )}
      {showValidate && number && (
        <OrderValidationModal
          showValidate={showValidate}
          setShowValidate={setShowValidate}
          number={number}
          handleOrderArchive={handleOrderArchive}
          handleOrderDelete={handleOrderDelete}
        />
      )}
    </OrderContainer>
  );
}

export default OrderDisplay;
