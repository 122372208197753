import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useApi } from '../../../../utils/hooks/admin';
import SpinnerWrapper from '../../SpinnerWrapper';
import { TableType } from '../../../../typescript/datas/TableType';
import { HandleSubmitType } from '../../../../typescript/functions/HandleSubmitType';

type OrderFormPartProps = {
  selectedEntryColumns: Partial<TableType>,
  setSelectedEntryColumns: React.Dispatch<React.SetStateAction<Partial<TableType>>>,
  handleSubmit: HandleSubmitType,
  handleClose: () => void,
  isLoading: boolean,
}

function OrderFormPart({
  selectedEntryColumns, // Objet contenant les informations de l'ingrédient en cours d'édition
  setSelectedEntryColumns, // Modifie les informations de l'ingrédient
  handleSubmit,
  handleClose,
  isLoading,
}: OrderFormPartProps) {
  const { errors } = useApi();

  const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // Méthode permettant de mettre à jour les informations de l'ingrédient
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      name,
      value,
    } = e.target;

    // Si le nom de l'input correspond à number
    if (name === 'number') {
      // On s'assurera que la value soit bien un int
      const quantity = parseInt(value, 10);
      setSelectedEntryColumns((prevEntryColumns) => ({
        ...prevEntryColumns,
        [name]: Number.isNaN(quantity) ? prevEntryColumns[name] : quantity,
      }));
    } else {
      setSelectedEntryColumns((prevEntryColumns) => ({
        ...prevEntryColumns,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (selectedEntryColumns.urlIdentifier === '') {
      setSelectedEntryColumns({
        ...selectedEntryColumns,
        urlIdentifier: generateRandomId(),
      });
    }
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {selectedEntryColumns.number !== 0 ? 'Édition ' : 'Ajout '}
            de tables
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="number">
              <Form.Label>
                Numéro de la table
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                name="number"
                onChange={handleInputChange}
                value={selectedEntryColumns.number}
                required
              />
              {errors.includes('duplicateNumber')
              && <p className="text-primary">Ce numéro de table est déjà utilisé.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="urlIdentifier">
              <Form.Label>
                Identifiant de la table
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="urlIdentifier"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, urlIdentifier: e.target.value,
                })}
                value={selectedEntryColumns.urlIdentifier}
                required
              />
              {errors.includes('duplicateUrlIdentifier')
              && <p className="text-primary">Cet identifiant de table est déjà utilisé.</p>}
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              <Button variant="success" size="sm" className="me-4" type="submit">
                Valider
              </Button>

              <Button variant="primary" size="sm" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrderFormPart;
