/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import methodSelection from '../../helpers/cache';
import errorMessages from '../../errors';
import { ApiContext, ModalManagementContext } from '../../../typescript/types/ContextTypes';
import { CacheType, UpdateCacheType } from '../../../typescript/types/CacheTypes';
import { JwtPayloadType } from '../../../typescript/type/JwtPayloadType';
import { WindowSizeType } from '../../../typescript/types/WindowSizeTypes';
import { HydraMemberType, Role } from '../../../typescript/type/HydraMemberType';
import { FetchDataType } from '../../../typescript/types/FetchDataTypes';
import { ErrorDataType } from '../../../typescript/type/ErrorDataType';
import { UpdateUserAuthType } from '../../../typescript/type/AuthType';
import { HandleSuccessInModalType } from '../../../typescript/types/ModalTypes';
import { SetBooleanStateType } from '../../../typescript/types/StateTypes';
import { isArraySupersetType } from '../../../typescript/types/AssociativeEntityUpdaterTypes';
import { OptionsType } from '../../../typescript/type/OptionsType';
import { TokenType } from '../../../typescript/datas/TokenType';
import { ImageType } from '../../../typescript/datas/ImageTypes';
import { OrderDishType } from '../../../typescript/datas/OrderDishTypes';
import { isData, isToken } from '../../helpers/Datatype';

// Hook permettant de requêter l'API
export const useApiRequest = (
  cache: CacheType<any>,
  updateCache: UpdateCacheType,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  // State contenant les messages d'erreur renvoyés par l'API
  const [errors, setErrors] = useState(['']);

  // Permet la redirection
  const navigate = useNavigate();

  // Mise à jour du state des erreurs
  const updateErrors = (data: ErrorDataType) => {
    // Initialisation du tableau des erreurs
    let errorsArr: string[] = [];

    // Vérification de la présence de data.error ou data.errors
    if (data.error) {
      errorsArr.push(data.error);
    } else if (data.errors) {
      errorsArr = data.errors;
    } else if (data.violations && Array.isArray(data.violations)) {
      // Si aucun error ou errors, vérification de la présence de data.violations
      // et construction du tableau des messages de violation
      errorsArr = data.violations.map((violation) => violation.message);
    }

    // Mise à jour du state avec les erreurs
    setErrors(errorsArr);
  };

  // Méthode requêtant l'API, modifiant le cache et renvoyant la réponse
  // Multiple indique un traitement de plusieurs entrées à la fois
  const fetchData: FetchDataType = async <T extends object>(
    url: string,
    options: Partial<OptionsType>,
    isLogin = false,
  ) => {
    let modifiedUrl = url;

    if (url === `${apiUrl}/api/menus?isDeleted=false`) {
      modifiedUrl = `${apiUrl}/api/menus`;
    } else if (url === `${apiUrl}/api/dishes?isDeleted=false`) {
      modifiedUrl = `${apiUrl}/api/dishes`;
    }

    // Si j'effectue une requête GET mais que le cache contient déjà les informations associées
    if (options.method === 'GET' && cache[modifiedUrl]) {
      // Retourne les données du cache
      return { data: cache[modifiedUrl], response: undefined };
    }

    // On récupére la réponse renvoyée par le serveur
    const response = await fetch(url, options);

    // 204 représente la suppression sans réponse en json à traiter. On s'arrête là
    if (response.status === 204) {
      return { data: null, response };
    }

    const responseData: T | ErrorDataType = await response.json();

    // Si la réponse indique que la requête a échoué
    if (!response.ok) {
      // Si je ne suis pas actuellement sur la page de Login
      if (!isLogin) {
        // Si le code de cette réponse correspond à l'expiration du token
        if (response.status === 401) {
          // Je redirige le User vers le Login en lui indiquant que son token a expiré
          navigate(
            '/login',
            { state: { errorMessage: 'Votre session a expiré. Veuillez vous reconnecter.' } },
          );
        }

        if (['error', 'errors', 'violations'].some((key) => key in responseData)) {
          const errorData = responseData;
          updateErrors(errorData);
        }

        // Je renvoie des données génériques au composant ayant fait l'appel au hook
        return { data: null, response: undefined };
      }
      // Si je sur la page Login, on détermine le type d'erreur à afficher
      const errorMessage = response.status === 401
        ? errorMessages.invalidCredentials
        : errorMessages.serverError;

      // Je mets à jour mon state dédié aux erreurs
      setErrors([errorMessage]);
    }

    // On récupère les données associées à cette réponse
    const successData = responseData as HydraMemberType<T>;

    // Construit et retourne le contenu du cache pour la ressource manipulée par le serveur
    return methodSelection(modifiedUrl, successData, response, cache, updateCache, options);
  };

  return { errors, setErrors, fetchData };
};

// Hook permettant de gérer le comportement de mes modales
export const useModal = () => {
  // Définit le comportement de mes modales au moment de la validation de ces dernières
  const handleSuccessInModal: HandleSuccessInModalType = (
    response,
    handleClose,
    handleSuccess,
    setIsLoading,
  ) => {
    /*
      Si la réponse liée à l'opération associée à la validation de la modale
      indique que la requête a réussi
    */
    if (response && response.ok) {
      // Je ferme cette dernière
      handleClose();

      // Le traitement terminé, je retire le loading
      setIsLoading(false);

      // Je recharge le parent
      handleSuccess();
    }
  };
  return { handleSuccessInModal };
};

// Hook permettant de requêter l'API et d'enregistrer ou de supprimer des associations d'entités
export const useAssociativeEntityUpdater = (
  fetchData: FetchDataType,
  authToken: string,
  updateUserAuth: UpdateUserAuthType,
) => {
  // Méthode indiquant si deux tableaux contiennent les mêmes éléments
  const isArraySuperset: isArraySupersetType = (
    initialsecondEntities,
    secondEntities,
  ) => initialsecondEntities.every(
    (initialElem) => secondEntities.includes(initialElem),
  );

  // Méthode indiquant si deux tableaux d'objets contiennent les mêmes éléments
  const isArrayOfObjectSuperset = (
    initialsecondEntities: OrderDishType[],
    secondEntities: OrderDishType[],
  ) => secondEntities.every(
    (elem) => initialsecondEntities.some(
      (initialElem) => elem.id === initialElem.id
        && elem.quantityNeeded === initialElem.quantityNeeded
        && elem.isRemovable === initialElem.isRemovable,
    ),
  );

  // Méthode pour gérer la mise à jour des associations d'entités pour number[]
  const updateNumberAssociativeEntity = async (
    associativeEntityName: string,
    firstEntityId: number,
    secondEntities: number[],
    initialsecondEntities: number[],
    name: string,
    apiUrl: string | undefined,
  ) => {
    let entitiesToAdd: number[] = [];
    let entitiesToRemove: number[] = [];

    // Détermine les entités ajoutées et retirées
    if (!isArraySuperset(initialsecondEntities, secondEntities)
      || !isArraySuperset(secondEntities, initialsecondEntities)) {
      entitiesToAdd = secondEntities.filter(
        (secondEntityId) => !initialsecondEntities.includes(secondEntityId),
      );

      entitiesToRemove = initialsecondEntities.filter(
        (initialsecondEntityId) => !secondEntities.includes(initialsecondEntityId),
      );
    }

    // Si il y a de nouvelles associations d'entités à ajouter
    if (entitiesToAdd.length > 0) {
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToAdd],
          isLastMethod: name === 'users' ? entitiesToRemove.length === 0 : false,
        }),
      };

      // On requête l'API pour inscrire la ou les nouvelle(s) association(s) d'entités
      const { data } = await fetchData<TokenType>(
        `${apiUrl}/api/${associativeEntityName}`,
        options,
      );

      // Si l'API a fourni un nouveau token d'id
      if (data && isToken(data) && data?.token) {
        // On met à jour nos informations relatives au User connecté
        updateUserAuth(data.token);
      }
    }

    // Si des associations d'entités sont à supprimer
    if (entitiesToRemove.length > 0) {
      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToRemove],
        }),
      };

      // On requête l'API pour retirer la ou les ancienne(s) association(s) d'entités
      const { data } = await fetchData<TokenType>(
        `${apiUrl}/api/${associativeEntityName}`,
        options,
      );

      // Si l'API a fourni un nouveau token d'id
      if (data && isToken(data) && data?.token) {
        // On met à jour nos informations relatives au User connecté
        updateUserAuth(data.token);
      }
    }
  };

  // Méthode pour gérer la mise à jour des associations d'entités pour DishDataType[]
  const updateDishAssociativeEntity = async (
    associativeEntityName: string,
    firstEntityId: number,
    secondEntities: OrderDishType[],
    initialsecondEntities: OrderDishType[],
    apiUrl: string | undefined,
  ) => {
    let entitiesToAdd: OrderDishType[] = [];
    let entitiesToRemove: OrderDishType[] = [];

    // Si le tableau de départ est différent du tableau final
    if (!isArrayOfObjectSuperset(initialsecondEntities, secondEntities)
      || !isArrayOfObjectSuperset(secondEntities, initialsecondEntities)) {
      // Détermine les entités ajoutées
      entitiesToAdd = secondEntities.filter(
        (secondEntity) => !initialsecondEntities.some(
          (initialEntity) => initialEntity.id === secondEntity.id
            && initialEntity.quantityNeeded === secondEntity.quantityNeeded
            && initialEntity.isRemovable === secondEntity.isRemovable,
        ),
      );

      // Détermine les entités retirées
      entitiesToRemove = initialsecondEntities.filter(
        (initialEntity) => !secondEntities.some(
          (secondEntity) => secondEntity.id === initialEntity.id,
        ),
      );
    }

    // Si il y a de nouvelles associations d'entités à ajouter
    if (entitiesToAdd.length > 0) {
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToAdd],
          isLastMethod: entitiesToRemove.length === 0,
        }),
      };

      // On requête l'API pour inscrire la ou les nouvelle(s) association(s) d'entités
      await fetchData(
        `${apiUrl}/api/${associativeEntityName}`,
        options,
      );
    }

    // Si des associations d'entités sont à supprimer
    if (entitiesToRemove.length > 0) {
      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToRemove],
        }),
      };

      // On requête l'API pour retirer la ou les ancienne(s) association(s) d'entités
      await fetchData(
        `${apiUrl}/api/${associativeEntityName}`,
        options,
      );
    }
  };

  // Méthode principale pour gérer la mise à jour des associations d'entités
  const updateAssociativeEntity = async (
    associativeEntityName: string,
    firstEntityId: number,
    secondEntities: number[] | OrderDishType[],
    initialsecondEntities: number[] | OrderDishType[],
    name: string,
  ) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (name === 'users' || name === 'roles' || name === 'menus') {
      await updateNumberAssociativeEntity(
        associativeEntityName,
        firstEntityId,
        secondEntities as number[],
        initialsecondEntities as number[],
        name,
        apiUrl,
      );
    } else if (name === 'dishes') {
      await updateDishAssociativeEntity(
        associativeEntityName,
        firstEntityId,
        secondEntities as OrderDishType[],
        initialsecondEntities as OrderDishType[],
        apiUrl,
      );
    }
  };

  return { isArraySuperset, updateAssociativeEntity };
};

// Hook permettant la gestion du cache
export const useCache = () => {
  // State contenant les informations de mes entités
  const [cache, setCache] = useState<CacheType<any>>({});

  /*
    Méthode permettant de mettre à jour, à partir de la réponse du serveur,
    les informations pour mes entités identifiées par l'url
  */
  const updateCache = <T>(
    url: string,
    data: HydraMemberType<T>,
  ) => {
    setCache((prevCache) => ({
      ...prevCache,
      [url]: data,
    }));
  };

  const resetCache = () => {
    setCache({});
  };

  return { cache, updateCache, resetCache };
};

export const useAuth = (
  setIsLoading: SetBooleanStateType,
  cache: CacheType<Role>,
  fetchData: FetchDataType,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  // States contenant les informations relatives au User connecté
  const [authToken, setAuthToken] = useState('');
  const [authId, setAuthId] = useState(0);
  const [authUser, setAuthUser] = useState('');
  const [authImg, setAuthImg] = useState('default_user_image.png');
  const [authRoles, setAuthRoles] = useState(['']);
  const [authPermissions, setAuthPermissions] = useState(['']);

  // Permet la redirection
  const navigate = useNavigate();

  // Méthode permettant la mise à jour des informations relatives au User connecté
  const updateUserAuth: UpdateUserAuthType = async (
    initialAuthToken,
    initialId = 0,
    initialAuthRoles: string[] = [],
    initialAuthUser = '',
    isRetrievedFromLS = false,
  ) => {
    const token = initialAuthToken;
    let id = initialId;
    let roles = [...initialAuthRoles];
    let user = initialAuthUser;

    const rolesOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    /*
      Requête l'API et entraîne la construction du cache des rôles
      Ce cache est nécessaire pour obtenir les permissions accordées au User connecté
    */
    await fetchData<HydraMemberType<Role>>(`${apiUrl}/api/roles`, rolesOptions);

    // Si on ne possède pas les informations sur le User connecté
    if (roles.length === 0 && !user) {
      // Si le token n'est pas enregistré dans le LocalStorage
      if (!isRetrievedFromLS) {
        // On stocke ce dernier
        localStorage.setItem('authToken', token);
      }

      // À partir de ce token on peut en déduire les informations associées
      const decodedToken: JwtPayloadType = jwtDecode(token);

      id = decodedToken.id;

      // Username associé au token
      user = decodedToken.username;

      // Roles associés au token
      roles = decodedToken.roles || '';
    }

    // On peut à présent mettre à jour nos states avec les informations récupéréess
    setAuthToken(token);
    setAuthId(id);
    setAuthRoles(roles);
    setAuthUser(user);
  };

  const resetUserAuth = () => {
    setAuthToken('');
    setAuthId(0);
    setAuthUser('');
    setAuthRoles([]);
    setAuthPermissions([]);
    setAuthImg('default_user_image.png');
  };

  /*
    Se déclenche quand le state contenant les rôles change.
    Détermine les permissions associées aux nouveaux rôles
  */
  useEffect(() => {
    // Retourne un tableau contenant l'ensemble des permissions accordées au User connecté
    const getPermissionsForRoles = () => {
      // Contient le cache des rôles contenant les informations de tous ces derniers
      const rolesCollection = cache[`${apiUrl}/api/roles`];

      // Si ce cache existe (devrait toujours être vraie)
      if (rolesCollection ?? false) {
        // Contiendra toutes les permissions accordées au User connecté. Set empêche les doublons
        const permissionsSet = new Set('');

        // Pour chaque rôle contenu dans mon tableau
        authRoles.forEach((authRole) => {
          // Je récupère les informations de ce dernier dans le cache des rôles
          const roleName = rolesCollection['hydra:member'].find((role) => role.name === authRole);
          // Si j'ai bien trouvé ce dernier dans le cache des rôles
          if (roleName) {
            // Pour chaque permission associée
            roleName.rolePermissions.forEach((rolePermission) => {
              // J'ajoute ces dernières dans l'ensemble des permissions
              permissionsSet.add(rolePermission.permission.name);
            });
          }
        });

        // Je convertis et retourne l'ensemble en tableau
        return Array.from(permissionsSet);
      }
      return [];
    };

    // Si les authRoles existent (empêche d'effectuer la logique au montage initial)
    if (authRoles.length > 0) {
      // Mets à jour les permissions accordées au User connecté
      setAuthPermissions(getPermissionsForRoles());
    }
  }, [authRoles, cache[`${apiUrl}/api/roles`]]);

  /*
    Se déclenche quand le state contenant les permissions change.
    Fin du processus de récupération des nouvelles informations de l'utilisateur connecté
  */
  useEffect(() => {
    // Si les authPermissions existent (empêche d'effectuer la logique au montage initial)
    if (authPermissions.length > 0) {
      // Le processus de récupération des informations est terminé, je retire le loading
      setIsLoading(false);
    }
  }, [authPermissions]);

  /*
    Se déclenche au montage initial de l'application.
    Permet de déclencher le processus de récupération des informations du User connecté
  */
  useEffect(() => {
    // Je récupère le token d'id dans le LocalStorage
    const storedToken = localStorage.getItem('authToken');

    // Si il existe je peux lancer le processus
    if (storedToken) {
      updateUserAuth(storedToken, 0, [], '', true);
    } else {
      setIsLoading(false);

      // Redirection vers la page de connexion
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const getUserImg = async () => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      // Requête l'API récupérant la liste des entités
      const { data } = await fetchData<ImageType>(`${apiUrl}/api/users/image/${authId}`, options);
      if (data && isData<ImageType>(data)) {
        setAuthImg(data.imageName);
      }
    };

    if (authId !== 0) {
      getUserImg();
    }
  }, [authId]);

  return {
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
  };
};

// Méthode permettant de connaître la taille actuelle de l'écran
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // On scrute l'évenement de redimensionnement de l'écran
    window.addEventListener('resize', handleResize);

    // Nettoie l'event quand le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowSize };
};

// Hook permettant d'accéder aux informations partagées par le context ApiContext
export const useApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within a ApiProvider');
  }

  return context;
};

export const useModalManagement = () => {
  const context = useContext(ModalManagementContext);

  if (!context) {
    throw new Error('useModalManagement must be used within a ApiProvider');
  }

  return context;
};
