import { Col, Container, Row } from 'react-bootstrap';
import SpecialCarousel from '../SpecialCarousel';
import { SpecialOptionsType } from '../../../typescript/datas/SpecialOptionsType';
import { DishAndMenuType } from '../../../typescript/datas/DishAndMenuType';

type SpecialProps = {
  specialOptions: SpecialOptionsType,
  carouselDatas: DishAndMenuType[],
};

function Special({
  specialOptions,
  carouselDatas,
}: SpecialProps) {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="wow fadeIn">
            <h2 className="block-title color-white text-center">{specialOptions.title}</h2>
            <h5 className="title-caption text-center">{specialOptions.description}</h5>
          </div>
          <SpecialCarousel carouselDatas={carouselDatas} />
        </Col>
      </Row>
    </Container>
  );
}

export default Special;
