/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import GalleryModal from '../GalleryModal';
import { GalleryOptionsType } from '../../../typescript/datas/GalleryOptionsType';
import { GalleryImageType } from '../../../typescript/datas/GalleryImageType';

type GalleryProps = {
  galleryOptions: GalleryOptionsType,
};

function Gallery({ galleryOptions }: GalleryProps) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [imageClicked, setImageClicked] = useState<GalleryImageType>();

  const colSizes = [
    {
      md: 8,
      sm: 12,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 8,
      sm: 12,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
    {
      md: 4,
      sm: 6,
      xs: 12,
    },
  ];

  const handleImageClick = (
    image: GalleryImageType,
  ) => {
    setShowModal(true);
    setImageClicked(image);
    document.body.classList.add('modalOpen');
  };

  const handleClose = () => {
    setShowModal(false);
    document.body.classList.remove('modalOpen');
  };

  return (
    <div id="gallery" className="gallery-main pad-top-100 pad-bottom-100">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
              <h2 className="block-title text-center">{galleryOptions.title}</h2>
              <p className="title-caption text-center">{galleryOptions.description}</p>
            </div>
          </Col>
        </Row>

        <div className="gal-container clearfix">
          <Row>
            {galleryOptions.galleryImages.map((image, index) => (
              <Col
                key={image.id}
                md={colSizes[index].md}
                sm={colSizes[index].sm}
                xs={colSizes[index].xs}
                className="gal-item"
                onClick={() => handleImageClick(image)}
              >
                <div className="box">
                  <a>
                    <img src={`${apiUrl}/images/galleryImages/${image.imageName}`} alt={image.description} />
                  </a>
                </div>
              </Col>
            ))}
            {imageClicked && Object.keys(imageClicked).length > 0 && (
              <GalleryModal
                showModal={showModal}
                handleClose={handleClose}
                imageClicked={imageClicked}
              />
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Gallery;
