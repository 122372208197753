// Permet de vérifier que 2 tableaux sont identiques
const arraysAreEquals = (
  arr1: unknown[],
  arr2: unknown[],
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((elem) => arr2.includes(elem)) && arr2.every((elem) => arr1.includes(elem));
};

export default arraysAreEquals;
