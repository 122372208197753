import React from 'react';
import NavBar from '../NavBar';

type MainPanelProps = {
  children: React.ReactNode,
}

function MainPanel({ children }: MainPanelProps) {
  return (
    <div className="content">
      <NavBar />
      {children}
    </div>
  );
}

export default MainPanel;
